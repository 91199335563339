
import axios from '../../utils/routes/axios';
import * as routes from '../../utils/routes'

class MemberController {

    async register(member) {
        return await axios.post(routes.ADMIN_SIGN_IN,
            member);

    };

    async registerFromCsvArray(array) {
        return await axios.post(routes.CREATE_MEMBERS_FROM_CSV,
            array);
    };

    async getCreateMemberApiFields() {

        return await axios.get(routes.GET_CREATE_MEMBER_API_FIELD)
    }

    async getMembers(count = 10, offset = 0) {

        return await axios.get(`${routes.GET_MEMBERS}?count=${count}&offset=${offset}`)
    }

    async findMember(email) {
        return await axios.get(`${routes.SEARCH_MEMBERS}?query=${email}`)
    }

}


export default new MemberController()

