import React, { Component } from 'react'

export class ConsularListItem extends Component {

    state = {
        processing: false
    }

    editConsulAction = e => {

        this.props.updateConsul(this.props.consul)
    }

    deleteConsulAction = e => {

        if (window.confirm('Are you sure to delete this consular advisor ? It\'s irreversible !')) {
            this.setState({processing: true}, () => {
                this.props.deleteConsul(this.props.consul)
            })
        }
    }
    render() {

        const consul = this.props.consul
        const asfeConsular = consul.asfeConsular
        const badge = {
            color:asfeConsular?'asfe-consular-tag':'sympatizer-tag',
            text:asfeConsular?'ASFE':'OTHER'
        }

        return (


            <header className="uk-comment-header uk-grid-medium uk-flex-middle" uk-grid>
                <div className="uk-width-expand">
                    <div className="uk-flex uk-flex-row uk-flex-between uk-flex-middle">

                        <div className="flex-row-center">
                            <div className="uk-width-auto">
                                <img className="uk-comment-avatar circular" src={consul.image.url} width="100" height="100"
                                     alt="consul-image"/>
                            </div>

                            <div className="spacer-2"/>
                            <div className="uk-flex uk-flex-column uk-flex-center">
                                <div className="uk-flex uk-flex-inline uk-flex-between uk-flex-middle">

                                    <div><a onClick={this.editConsulAction}
                                            className="uk-link-reset">{consul.name.toUpperCase()+ ' '}</a></div>

                                    <div className="spacer-1"/>
                                    <div><span
                                        id={badge.color}
                                        className={"uk-badge " + badge.color}>{badge.text.toUpperCase()}</span></div>
                                </div>


                                <ul className="uk-comment-meta uk-subnav uk-subnav-divider uk-margin-remove-top">
                                    <li><a href="#" onClick={this.editConsulAction} className="uk-icon-link uk-margin-small-right"
                                           uk-icon="home"></a>{consul.district ? consul.district.name.toUpperCase() : ''}
                                    </li>
                                    {consul.asfeConsular ?
                                        <li><a href="#" onClick={this.editConsulAction} className="uk-icon-link uk-margin-small-right"
                                               uk-icon="users"></a>{consul.assistants.length}</li> : null}
                                </ul>

                            </div>
                        </div>

                        <div>
                            {
                                !this.state.processing ? 
                                    <ul className="uk-iconnav">
                                        <li><a onClick={this.editConsulAction} className="primary-theme-color" href="#" uk-icon="icon: pencil; ratio: 1.5"></a></li>
                                        <li><a onClick={this.deleteConsulAction} className="primary-theme-color" href="#" uk-icon="icon: close; ratio: 1.5"></a></li>
                                    </ul> : <button onClick={this.updateDistrictAction} type="submit" className="uk-button primary-theme-color text-white uk-button-large">Suppression... { this.state.processing ? <div className="uk-margin-small-left" uk-spinner=""/>: null}</button>
                            }
                        </div>

                    </div>
                    <div className="default-divider "></div>



                </div>

            </header>


        )
    }
}

export default ConsularListItem
