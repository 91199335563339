import React, { Component } from 'react'
import consularController from '../../../controller/consularController';
import AssistantTag from '../assistants/AssistantTag';

export class ConsularAdvisorPersonalInfos extends Component {



    state = {
        files:this.props.files,
        folders:this.props.folders,
        folder: this.props.folder,
        image:this.props.image,
        firstName: this.props.firstName,
        lastName: this.props.lastName,
        biography: this.props.biography,
        currentDistrict: null,
        districts: this.props.districts,
        cities: [],
        city: null,
        assistantsTags: [],
        assistants: this.props.assistants,
        currentAssistants: [],
        assistantsUuids: [],
        selectedCityIndex: -1,
        processing: false
    }

    componentWillMount() {

        const currentDistrict = this.props.selectedDistrictIndex !== -1 ? this.state.districts[this.props.selectedDistrictIndex] : null
        const currentAssistants = this.props.currentAssistants
        const assistantsTags = currentAssistants.map(assistant => <AssistantTag assistant={assistant} removeAction={this.removeAction} />)
        const selectedCityIndex = this.props.selectedCityIndex;

        this.setState({ currentDistrict, assistantsTags, selectedCityIndex, currentAssistants })
    }

    handleFieldChange = (e) => {
        this.props.handleFieldChange(e)
        this.setState({ [e.target.name]: e.target.value })

    }

    onFileChange = e=>{
        const fileIndex = e.target.value;

        if(fileIndex.toString()!=='-1'){
            const file = {
                ...this.state.files[fileIndex],
                url:this.state.files[fileIndex].full_size_url
            }
            this.props.onFileChange(file)
            this.setState({image: file})
        }

    }

    onFolderChange = e =>{
        const folderIndex = e.target.value;

        if(folderIndex.toString()!=='-1'){
            const folder = this.state.folders[folderIndex]
            this.setState({processing: true}, async () => {
                const images = (await consularController.getFilesByFolderId(folder.id, folder.created_at.toString().split('T')[0])).data.files
                this.setState({files: images, processing: false, folder}, () => {
                    this.props.onFolderChange(folder, images)
                })
            })
        }
    }

    onDistrictChange = e => {

        const district = this.state.districts[e.target.value]
        const cities = district.cities
        const city = {}

        this.setState({ currentDistrict: district, cities, city, selectedCityIndex: -1 })
        this.props.onDistrictChange(e)
    }

    onCityChange = e => {
        this.props.onCityChange(e)
        const selectedCityIndex = e.target.value
        this.setState({ selectedCityIndex })
    }

    onAssistantChange = e => {


        const assistant = this.state.assistants[e.target.value]

        if (assistant) {

            const assistantsUuids = this.state.assistantsUuids
            const currentAssistants = this.state.currentAssistants.filter(candidate => candidate.uuid === assistant.uuid)

            if (currentAssistants.length === 0) {


                assistantsUuids.push(assistant.uuid)
                const assistantsTags = this.state.assistantsTags
                currentAssistants.push(assistant)

                assistantsTags.push(<AssistantTag assistant={assistant} removeAction={this.removeAction} />)
                this.setState({ assistantsUuids, assistantsTags, currentAssistants })
                this.props.onAssistantChange(assistant)

            }

        }


    }

    removeAction = assistant => {

        const assistantsTags = this.state.assistantsTags.filter(assistantTag => assistantTag.props.assistant.uuid !== assistant.uuid).sort((a,b)=>a.position<b.position?-1:1)
        const assistantsUuids = this.state.assistantsUuids.filter(assUuid => assUuid !== assistant.uuid)
        this.props.removeAssistantAction(assistant)
        this.setState({ assistantsTags, assistantsUuids })
    }

    render() {


        const cities = this.state.currentDistrict ? this.state.currentDistrict.cities : []
        const selectedCityIndex = this.state.selectedCityIndex
        const imageSelected = this.state.image!==null
        const folderSelected = this.state.folder!==null
        const biography = this.state.biography
        const bioSize = biography.length
        return (
            <form >
                <div className="centered ">
                    <div className="flex-row-center">
                        <div>
                            <img className="circular" src={imageSelected?this.state.image.url:''} width="100" height="100"
                                 alt="consul-image"/>
                        </div>

                        <div className="sizedbox-width-5"/>

                        <div className="flex-column">
                            <div className="uk-inline content-20w">
                                <span className="uk-form-icon" uk-icon="icon: user" />
                                <input name="firstName" placeholder="first name" onChange={this.handleFieldChange} className="uk-align-center uk-input bottom-border no-border" type="text" value={this.state.firstName} />
                            </div>

                            <div className="uk-inline content-20w">
                                <span className="uk-form-icon" uk-icon="icon: user" />
                                <input name="lastName" placeholder="last name" onChange={this.handleFieldChange} className="uk-align-center uk-input bottom-border no-border" type="text" value={this.state.lastName} />
                            </div>
                        </div>

                    </div>
                    <div className="inline-content-center-no-space">
                        <span className="primary-theme-color-foreground" uk-icon={"icon: folder ; ratio: 2"}></span>
                        <div className="spacer-1"></div>
                        <form>
                            <div className="uk-margin">
                                <div uk-form-custom="target: > * > span:first-child">
                                    <select onChange={this.onFolderChange}>
                                        <option value={-1}>dossiers...</option>
                                        {this.state.folders.map((folder, index) => <option value={index} selected={folderSelected?folder.id.toString()===this.state.folder.id.toString():false}>{folder.name}</option>)}
                                    </select>
                                    <button className="uk-button uk-button-default" type="button">
                                        <span></span>
                                        <span className="primary-theme-color-foreground"
                                              uk-icon="icon: chevron-down"></span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="inline-content-center-no-space">
                        <span className="primary-theme-color-foreground" uk-icon={"icon: image ; ratio: 2"}></span>
                        <div className="spacer-1"></div>
                        <form>
                            <div className="uk-margin">
                                <div uk-form-custom="target: > * > span:first-child">
                                    <select onChange={this.onFileChange} disabled={this.state.processing}>
                                        <option value={-1}>images...</option>
                                        {this.state.files.map((file, index) => <option value={index} selected={imageSelected?file.id.toString()===this.state.image.id.toString():false}>{file.name}</option>)}

                                    </select>
                                    <button className="uk-button uk-button-default" type="button">
                                        <span></span>
                                        <span className="primary-theme-color-foreground"
                                              uk-icon="icon: chevron-down"></span>
                                    </button>
                                </div>
                            </div>
                        </form>
                        { this.state.processing ? <div className="uk-margin-small-left" uk-spinner=""/>: null }
                    </div>



                    <div className="uk-inline">
                        <span class={`uk-label uk-label-${bioSize<=2048 && bioSize>0? 'success' :'danger'}`}>{bioSize<=2048 && bioSize>0 ? 'OK':'Error'}</span><span>({`${bioSize} character${bioSize>0?'s':''}`})[max: 2048]</span>
                        <textarea name="biography" onChange={this.handleFieldChange} class="uk-textarea" rows="5" cols="70" placeholder="biography" value={biography}></textarea>
                    </div>

                    <div className="sizedbox-height-2">
                    </div>

                    <div className="inline-content-center-no-space">
                        <span className="primary-theme-color-foreground" uk-icon={"icon: location ; ratio: 2"}></span>
                        <div className="spacer-1"></div>
                        <form>
                            <div class="uk-margin">
                                <div uk-form-custom="target: > * > span:first-child">
                                    <select onChange={this.onDistrictChange} >
                                        {this.props.selectedDistrictIndex !== -1 ? <option value={this.props.selectedDistrictIndex} selected>{this.state.districts[this.props.selectedDistrictIndex].name}</option> : <option value="">District...</option>}
                                        {this.props.districts.map((district, index) => {
                                            if (index.toString() !== this.props.selectedDistrictIndex.toString()) {
                                                return <option value={index}>{district.name}</option>
                                            }
                                        })}

                                    </select>
                                    <button class="uk-button uk-button-default" type="button" >
                                        <span></span>
                                        <span className="primary-theme-color-foreground" uk-icon="icon: chevron-down"></span>
                                    </button>
                                </div>
                            </div>
                        </form>

                        <div className="spacer-1"></div>

                        <form>
                            <div class="uk-margin">
                                <div uk-form-custom="target: > * > span:first-child">
                                    <select onChange={this.onCityChange}>
                                        {selectedCityIndex !== -1 ? <option value={selectedCityIndex} selected>{this.state.currentDistrict.cities[selectedCityIndex].name}</option> : <option value="">City...</option>}
                                        {cities.map((city, index) => {

                                            if (index.toString() !== selectedCityIndex.toString()) {

                                                return <option value={index}>{city.name}</option>
                                            }
                                        })}

                                    </select>
                                    <button class="uk-button uk-button-default" type="button" >
                                        <span></span>
                                        <span className="primary-theme-color-foreground" uk-icon="icon: chevron-down"></span>
                                    </button>
                                </div>
                            </div>
                        </form>


                    </div>

                    <div className="inline-content-center-no-space">
                        <span className="primary-theme-color-foreground" uk-icon={"icon: users ; ratio: 2"}></span>
                        <div className="spacer-1"></div>
                        <form>
                            <div class="uk-margin">
                                <div uk-form-custom="target: > * > span:first-child">
                                    <select onChange={this.onAssistantChange}>
                                        <option value="">Please select...</option>
                                        {this.props.assistants.map((assistant, index) => <option value={index}>{assistant.name + ' #'+assistant.position}</option>)}

                                    </select>
                                    <button class="uk-button uk-button-default" type="button" >
                                        <span></span>
                                        <span className="primary-theme-color-foreground" uk-icon="icon: chevron-down"></span>
                                    </button>
                                </div>
                            </div>
                        </form>


                    </div>

                    <div className='uk-width-1-1 uk-grid uk-flex'>

                        {this.state.assistantsTags}

                    </div>



                    <div className="sizedbox-height-5"></div>




                </div>

            </form>
        )
    }
}

export default ConsularAdvisorPersonalInfos
