import React, { Component } from 'react'
import campaignController from '../../controller/campaignController'
import CampaignItem from './CampaignItem'
import CampaignScreenDetails from './CampaignScreenDetails';

export class CampaignsScreen extends Component {


    state = {
        campaigns: [],
        filteredCampaigns: [],
        campaignsTotal: 0,
        openDialog: false,
        dialogChilds: [],
        offset: 0,
        limit: 10,
        lists: [],
        limits: [20, 30, 50, 100],
        paginationInf: 0,
        paginationSup: 20,
        loading: true,
    }

    componentDidMount() {

        campaignController.getReports().then(res => {
            const payload = res.data.reports
            const lists = this.filterListInCampaigns(payload)
            this.setState({ campaigns: payload, filteredCampaigns: payload, lists: lists, campaignsTotal: res.data.total_items })
            this.loadingToggleEnd()
        })
    }

    showCampaignDetails = (campaign) => {
        this.props.updateScreen(<CampaignScreenDetails updateListToCreate={this.props.updateListToCreate} campaign={campaign} />)
    }

    onListChange = (e) => {

        try {
            const targetList = this.state.lists[e.target.value]
            const filteredCampaigns = []
            this.state.campaigns.forEach(campaign => {

                if (campaign.list_id === targetList.id) {
                    filteredCampaigns.push(campaign)
                }

            })

            this.setState({ filteredCampaigns })

        } catch (e) {
            this.setState({ filteredCampaigns: this.state.campaigns })
        }

    }

    elementIsPresentIn = (array, element) => {

        let isPresent = false;
        array.forEach(el => {
            if (el.id === element.list_id) {
                isPresent = true;
            }
        })
        return isPresent;
    }

    onLimitChange = (e) => {


        const limit = this.state.limits[e.target.value]
        const campaignsTotal = this.state.campaignsTotal
        const paginationInf = 0
        const paginationSup = limit < campaignsTotal ? limit : campaignsTotal


        this.loadingToggleStart()
        campaignController.getReports(limit).then(res => {

            const payload = res.data.reports
            const lists = this.filterListInCampaigns(payload)

            this.setState({ campaigns: payload, filteredCampaigns: payload, lists, paginationInf, paginationSup, campaignsTotal: res.data.total_items, limit })
            this.loadingToggleEnd()
        })


    }

    filterListInCampaigns = (campaigns) => {

        const lists = []

        campaigns.forEach(campaign => {

            if (!this.elementIsPresentIn(lists, campaign)) {

                lists.push({
                    id: campaign.list_id,
                    name: campaign.list_name
                })
            }

        })

        return lists
    }

    rightPaginationUpdate = () => {



        const limit = this.state.limit
        const offset = this.state.offset + limit
        const diff = this.state.campaignsTotal - offset

        if (diff > 0) {

            this.loadingToggleStart()

            const paginationInf = offset
            const paginationSup = paginationInf + limit


            campaignController.getReports(this.state.limit, paginationInf).then(res => {

                const payload = res.data.reports
                const lists = this.filterListInCampaigns(payload)

                this.setState({ campaigns: payload, filteredCampaigns: payload, campaignsTotal: res.data.total_items, lists, offset, paginationInf, paginationSup })
                this.loadingToggleEnd()
            })

        }
    }

    leftPaginationUpdate = () => {



        const limit = this.state.limit
        const offset = this.state.offset - limit

        const paginationSup = this.state.paginationInf
        const paginationInf = this.state.paginationInf - limit

        if (paginationInf >= 0) {

            this.loadingToggleStart()

            campaignController.getReports(limit, paginationInf).then(res => {

                this.setState({ campaigns: res.data.reports, filteredCampaigns: res.data.reports, listsTotal: res.data.total_items, offset, paginationInf, paginationSup })
                this.loadingToggleEnd()
            })



        }



    }

    loadingToggleStart = () => {
        this.setState({ loading: true })
    }

    loadingToggleEnd = () => {
        this.setState({ loading: false })
    }



    render() {

        const campaigns = this.state.filteredCampaigns.map(campaign => {
            campaign = {
                ...campaign,
                name: campaign.subject_line,
                send: new Date(campaign.send_time).toLocaleDateString("fr-FR"),
                uuid: campaign.id,
                opens: Number(campaign['opens']['open_rate'] * 100).toFixed(2),
                clicks: Number(campaign['clicks']['click_rate'] * 100).toFixed(2),
            }

            return campaign
        })


        return (
                <div className="uk-padding">
                    <div className="header-container">
                        <h1>CAMPAIGNS</h1>
                    </div>
                    <div className="sizedbox-height-2"></div>

                    <div className="uk-flex uk-flex-around uk-flex-wrap">
                        <div>
                            <form >
                                <div class="uk-margin">
                                    <div uk-form-custom="target: > * > span:first-child">

                                        <span><b>LIST :</b> </span>
                                        <select onChange={this.onListChange}>
                                            <option value="">Please select...</option>
                                            {this.state.lists.map((list, index) => <option value={index}>{list.name}</option>)}

                                        </select>
                                        <button class="uk-button uk-button-default" type="button" >
                                            <span></span>
                                            <span className="primary-theme-color-foreground" uk-icon="icon: chevron-down"></span>
                                        </button>
                                    </div>
                                </div>


                            </form>
                        </div>
                        <div className="uk-flex uk-flex-around">
                            <span className="primary-theme-color-foreground" uk-icon={"icon: ban ; ratio: 2"}></span>
                            <div className="spacer-1"></div>
                            <form>
                                <div class="uk-margin">
                                    <div uk-form-custom="target: > * > span:first-child">
                                        <select onChange={this.onLimitChange}>
                                            <option value="">limit</option>
                                            {this.state.limits.map((limit, index) => <option value={index}>{limit}</option>)}

                                        </select>
                                        <button class="uk-button uk-button-default" type="button" >
                                            <span></span>
                                            <span className="primary-theme-color-foreground" uk-icon="icon: chevron-down"></span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div >
                            <button onClick={this.leftPaginationUpdate} type="submit" className="primary-theme-color-foreground" uk-icon="icon: chevron-left"></button>
                            <span>{this.state.paginationInf + 1} - {this.state.paginationSup} of {this.state.campaignsTotal}</span>
                            <button onClick={this.rightPaginationUpdate} type="submit" className="primary-theme-color-foreground" uk-icon="icon: chevron-right"></button>
                        </div>

                    </div>

                    <div className="items-container uk-overflow-auto">
                        {!this.state.loading ? <table class="uk-table uk-table-small uk-table-divider">
                            <thead>
                                <tr>
                                    <th>NAME</th>
                                    <th>STATS</th>
                                    <th>ACTIONS</th>
                                </tr>
                            </thead>
                            <tbody>

                                {campaigns.map(campaign => {
                                    return <CampaignItem key={campaign.uuid} campaignFocusScreen={this.showCampaignDetails} campaign={campaign} />
                                })}
                            </tbody>
                        </table> : (<div className="inline-content-center"><span class="uk-margin-small-right" uk-spinner="ratio: 3"></span></div>)}

                    </div>

                    <div className="inline-content-center-no-space">
                        <button onClick={this.leftPaginationUpdate} type="submit" className="primary-theme-color-foreground" uk-icon="icon: chevron-left"></button>
                        <span>{this.state.paginationInf + 1} - {this.state.paginationSup} of {this.state.campaignsTotal}</span>
                        <button onClick={this.rightPaginationUpdate} type="submit" className="primary-theme-color-foreground" uk-icon="icon: chevron-right"></button>
                    </div>
                    <div className="sizedbox-height-2"></div>

                </div>
        )
    }
}

export default CampaignsScreen
