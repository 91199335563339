import React, {Component} from 'react'
import consularController from './../../../controller/consularController'
import ConsularAdvisorPersonalInfos from './ConsularAdvisorPersonalInfos'
import ConsularAdvisorIntentions from './ConsularAdvisorIntentions'
import ConsularAdvisorPropositions from './ConsularAdvisorPropositions'
import SocialNetworksComponentScreen from './SocialNetworksComponentScreen'
import ConsularAdvisorEvents from "./ConsularAdvisorEvents";
import notification from '../../../services/notification'

export class EditConsularAdvisorForm extends Component {

    state = {

        files:this.props.files,
        folders:this.props.folders,
        folder: this.props.folder,
        currentFile:{...this.props.consul.image,url:this.props.consul.image.url},
        currentFolder: null,
        mainContainer: null,
        firstName: this.props.consul.firstName,
        lastName: this.props.consul.lastName,
        image: this.props.consul.image,
        biography: this.props.consul.biography,
        districtUuid: this.props.consul.district !== null ? this.props.consul.district.uuid : '',
        intentions: this.props.consul.intentions,
        propositions: this.props.consul.propositions,
        city: null,
        cities: [],
        events: this.props.consul.events,
        socialNetworks: this.props.consul.socialNetworks,
        assistants: this.props.assistants,
        districts: this.props.districts,
        assistantsUuids: [],
        currentAssistants: this.props.consul.assistants,
        socialNetworksTags: this.props.socialNetworksTags,
        assistantsTags: [],
        personalInfos: null,
        intentionsScreen: null,
        propositionsScreen: null,
        eventsScreen: null,
        socialNetworksScreen: null,
        selectedDistrictIndex: -1,
        selectedCityIndex: -1,
        currentPropositions: this.props.consul.propositions,
        processing: false,

    }


    componentDidMount() {


        let selectedDistrictIndex = -1
        let selectedCityIndex = -1
        const district = this.props.consul.district

        const currentAssistants = this.props.consul.assistants.map(assistant => {

            assistant = {
                ...assistant,
                name: assistant.firstName + ' ' + assistant.lastName
            }

            return assistant
        })

        const assistants = this.props.assistants.map(assistant => {

            assistant = {
                ...assistant,
                name: assistant.firstName + ' ' + assistant.lastName
            }

            return assistant
        })

        const socialNetworks = this.props.consul.socialNetworks.map(socialNetwork => {


            const socialNetworksTags = this.props.socialNetworksTags
            let socialTagIndex = -1

            for (let i = 0; i < socialNetworksTags.length; i++) {


                if (socialNetworksTags[i].uuid.toString() === socialNetwork.tag.uuid.toString()) {
                    socialTagIndex = i
                    break
                }
            }
            socialNetwork = {
                ...socialNetwork,
                id: socialNetwork.uuid,
                link: socialNetwork.link,
                socialTagIndex
            }

            return socialNetwork
        })

        let cities = []


        if (district !== null) {

            for (let i = 0; i < this.props.districts.length; i++) {

                if (this.props.districts[i].uuid.toString() === district.uuid.toString()) {
                    selectedDistrictIndex = i
                    break
                }
            }

            cities = district.cities

            if (cities !== null && this.props.consul.cityUuid !== null) {

                for (let i = 0; i < cities.length; i++) {

                    if (cities[i].uuid.toString() === this.props.consul.cityUuid.toString()) {
                        selectedCityIndex = i
                        break
                    }
                }

            }
        }


        const personalInfos = <ConsularAdvisorPersonalInfos


            firstName={this.state.firstName}
            lastName={this.state.lastName}
            files={this.state.files}
            image={this.state.currentFile}
            onFileChange={this.onFileChange}
            biography={this.state.biography}

            folders={this.state.folders}
            folder={this.state.currentFolder}
            onFolderChange={this.onFolderChange}

            assistants={assistants}
            onAssistantChange={this.onAssistantChange}

            currentAssistants={currentAssistants}
            removeAssistantAction={this.removeAssistantAction}

            districts={this.state.districts}
            onDistrictChange={this.onDistrictChange}

            cities={cities}

            city={this.state.city}
            onCityChange={this.onCityChange}

            handleFieldChange={this.handleFieldChange}

            selectedDistrictIndex={selectedDistrictIndex}
            selectedCityIndex={selectedCityIndex}


        />

        const intentionsScreen = <ConsularAdvisorIntentions intentions={this.state.intentions}
                                                            addIntention={this.addIntention}
                                                            updateIntentions={this.updateIntentions}/>

        const propositions = this.props.consul.propositions.map((proposition,index)=>{
            return {
                ...proposition,
                id:proposition.uuid,
                index
            }
        }).sort((a,b)=>a.position<b.position?-1:1)
        const propositionsScreen = <ConsularAdvisorPropositions currentPropositions={propositions}
                                                                updatePropositions={this.updatePropositions}
                                                                addProposition={this.addProposition}/>


        const socialNetworksScreen = <SocialNetworksComponentScreen socialNetworks={socialNetworks}
                                                                    socialNetworksTags={this.state.socialNetworksTags}
                                                                    addSocialNetwork={this.addSocialNetwork}
                                                                    updateSocialNetworks={this.updateSocialNetworks}/>

        const eventsScreen = <ConsularAdvisorEvents events={this.state.events} addEvent={this.addEvent}
                                                    updateEvents={this.updateEvents}/>

        const mainContainer = personalInfos


        this.setState({
            personalInfos,
            mainContainer,
            propositionsScreen,
            currentPropositions:propositions,
            eventsScreen,
            socialNetworksScreen,
            intentionsScreen,
            selectedDistrictIndex,
            selectedCityIndex,
            assistants,
            currentAssistants,
            socialNetworks,
            cities
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        this.state.personalInfos = <ConsularAdvisorPersonalInfos


            firstName={this.state.firstName}
            lastName={this.state.lastName}
            files={this.state.files}
            image={this.state.currentFile}
            onFileChange={this.onFileChange}
            biography={this.state.biography}

            folders={this.props.folders}
            folder={this.state.currentFolder}
            onFolderChange={this.onFolderChange}

            assistants={this.state.assistants}
            onAssistantChange={this.onAssistantChange}

            currentAssistants={this.state.currentAssistants}
            removeAssistantAction={this.removeAssistantAction}

            districts={this.state.districts}
            onDistrictChange={this.onDistrictChange}

            cities={this.state.cities}

            city={this.state.city}
            onCityChange={this.onCityChange}

            handleFieldChange={this.handleFieldChange}

            selectedDistrictIndex={this.state.selectedDistrictIndex}
            selectedCityIndex={this.state.selectedCityIndex}

        />

        this.state.propositionsScreen =
            <ConsularAdvisorPropositions currentPropositions={this.state.currentPropositions}
                                         updatePropositions={this.updatePropositions}
                                         addProposition={this.addProposition}/>


        this.state.eventsScreen = <ConsularAdvisorEvents events={this.state.events} addEvent={this.addEvent}
                                                         updateEvents={this.updateEvents}/>

        this.state.intentionsScreen =
            <ConsularAdvisorIntentions intentions={this.state.intentions} addIntention={this.addIntention}
                                       updateIntentions={this.updateIntentions}/>

        this.state.socialNetworksScreen = <SocialNetworksComponentScreen socialNetworks={this.state.socialNetworks}
                                                                         socialNetworksTags={this.state.socialNetworksTags}
                                                                         addSocialNetwork={this.addSocialNetwork}
                                                                         updateSocialNetworks={this.updateSocialNetworks}/>
    }

    onFileChange = file =>{

        const fileEntry = {
            ...file,
            url: file.full_size_url
        }
        this.setState({currentFile: fileEntry})
    }

    onFolderChange = (folder, images) => {
        this.setState({currentFolder: folder, files: images })
    }

    handleFieldChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    // Personal Info
    onDistrictChange = e => {

        const district = this.state.districts[e.target.value]
        const cities = district.cities
        const city = {}
        const selectedDistrictIndex = e.target.value
        const selectedCityIndex = -1

        this.setState({cities, city, selectedDistrictIndex, selectedCityIndex})
    }

    onCityChange = e => {

        const city = this.state.cities[e.target.value]
        const selectedCityIndex = e.target.value

        console.log(city)

        this.setState({city, selectedCityIndex})

    }

    onAssistantChange = candidate => {

        const currentAssistants = this.state.currentAssistants

        currentAssistants.push(candidate)

        this.setState({currentAssistants})

    }

    removeAssistantAction = candidate => {

        const currentAssistants = this.state.currentAssistants.filter(assistant => candidate.uuid !== assistant.uuid)

        this.setState({currentAssistants})
    }

    validateAssistantsOrder = _ =>{

        const sorted = this.state.currentAssistants.sort((a,b)=>a.position-b.position)

        let valid = true
        if(sorted[0].position !== 1) return false
        for(let i=0;i<sorted.length-1;i++){

            if(sorted[i].position+1 !== sorted[i+1].position){
                valid = false
                break
            }
        }
        return valid
    }

    // Intentions

    addIntention = intention => {
        const intentions = this.state.intentions
        intentions.push(intention)
        this.setState({intentions})
    }

    updateIntentions = intentions => {
        this.setState({intentions})
    }

    // Propositions

    addProposition = proposition => {

        const propositions = this.state.propositions
        propositions.push(proposition)
        this.setState({currentPropositions: propositions})
    }

    updatePropositions = propositions => {
        this.setState({currentPropositions:propositions})
    }

    // Events
    addEvent = event => {
        const events = this.state.events
        events.push(event)
        this.setState({events})
    }

    updateEvents = events => {
        this.setState({events})
    }

    // Social Networks

    addSocialNetwork = socialNetwork => {

        const socialNetworks = this.state.socialNetworks

        socialNetworks.push(socialNetwork)
        this.setState({socialNetwork})
    }

    updateSocialNetworks = socialNetworks => {
        this.setState({socialNetworks})
    }

    handleTab = e => {

        const id = e.target.id
        let mainContainer = this.state.mainContainer

        switch (id.toLowerCase()) {

            case 'infos':

                mainContainer = this.state.personalInfos

                break
            case 'intentions':

                mainContainer = this.state.intentionsScreen
                break
            case 'propositions':

                mainContainer = this.state.propositionsScreen
                break
            case 'events':

                mainContainer = this.state.eventsScreen
                break
            case 'socials-networks':

                mainContainer = this.state.socialNetworksScreen
                break
            default:
                break;
        }

        this.setState({mainContainer})
    }

    cancelAction = e => {
        this.props.hideDialog()
    }

    updateConsularAdvisor = e => {

        const selectedCityIndex = this.state.selectedCityIndex
        const selectedDistrictIndex = this.state.selectedDistrictIndex
        const firstName = this.state.firstName
        const lastName = this.state.lastName
        const image = this.state.currentFile
        const biography = this.state.biography

        const districtUuid = selectedDistrictIndex !== -1 ? this.state.districts[selectedDistrictIndex].uuid : ''
        const cityUuid = selectedCityIndex !== -1 ? this.state.cities[selectedCityIndex].uuid : ''
        const intentions = this.state.intentions.map(intention => intention.value)
        const propositions = this.state.currentPropositions.map((proposition,index) => {

            return {
                ...proposition,
                position: index
            }

        })
        let events = this.state.events
        const assistantsUuids = this.state.currentAssistants.map(assistant => assistant.uuid)
        const socialNetworks = this.state.socialNetworks.every(socialNetwork => socialNetwork.socialTagIndex !== -1) ? this.state.socialNetworks.map(socialNetwork => {

            const socialNetworkObj = {
                socialNetworkTagUuid: this.state.socialNetworksTags[socialNetwork.socialTagIndex].uuid,
                link: socialNetwork.link
            }

            return socialNetworkObj
        }) : []

        let error = false

        if (firstName.length <= 0) {
            error = true
            return this.showNotification(false, 'Fill the First name please...')
        }
        if (lastName.length <= 0) {
            error = true
            return this.showNotification(false, 'Fill the Last name please...')
        }

        if (image === null) {
            error = true
            return this.showNotification(false, 'Select an image please...')
        }

        if (biography.trim().length <= 0) {
            error = true
            return this.showNotification(false, 'Fill the Biography please...')
        }

        if (biography.length > 2048) {
            error = true
            return this.showNotification(false, 'Biography cannot have more than 2048 characters...')
        }

        if (districtUuid.length <= 0) {
            error = true
            return this.showNotification(false, 'Select a district...')
        }

        if (assistantsUuids.length <= 0) {
            error = true
            return this.showNotification(false, 'Please select assistants...')
        }

        if(!this.validateAssistantsOrder()){
            error = true
            return this.showNotification(false, 'Verify assistants positions...')
        }

        if (intentions.length <= 0 || intentions.every(intention => intention.trim().length <= 0)) {
            error = true
            return this.showNotification(false, 'Please fill intentions...')
        }

        if (intentions.some(intention => intention.length > 2048)) {
            error = true
            return this.showNotification(false, 'Intention cannot have more than 2048 characters...')
        }

        if (propositions.length <= 0 || propositions.every(proposition => proposition.value.trim().length <= 0)) {
            error = true
            return this.showNotification(false, 'Please fill propositions...')
        }

        if (events.length > 0 && events.some(event => event.place === null)) {
            error = true
            return this.showNotification(false, 'Each event should have a place.')
        }

        if (propositions.some(proposition => proposition.value.trim().length > 1024)) {
            error = true
            return this.showNotification(false, 'Proposition cannot have more than 1024 characters...')
        }

        if (socialNetworks.length <= 0) {
            error = true
            return this.showNotification(false, 'Please fill social networks...')
        }


        if (!error) {

            this.showNotification(true, 'All required fields present...')

            events = events.map(event => {
                return {

                    title: event.title,
                    date: event.date.toString(),
                    address: event.address,
                    time: event.time,
                    moreInfoLink: event.moreInfoLink,
                    latitude: event.latitude!==undefined?event.latitude:event.place.location.lat,
                    longitude: event.longitude!==undefined?event.longitude:event.place.location.lng
                }
            })
            const data = {
                consulUuid: this.props.consul.uuid,
                firstName,
                lastName,
                image,
                biography,
                districtUuid,
                cityUuid,
                intentions,
                propositions,
                events,
                assistantsUuids,
                socialNetworks
            }

            this.setState({processing: true}, () => {
                consularController.updateConsul(data).then(res => {
                    if (res.status=== 200) {
                        this.showNotification(true, `consular ${res.data.firstName + ' ' + res.data.lastName} updated...`)
                        this.props.updateConsuls().then(()=>this.cancelAction())
                    } else {
                        this.showNotification(false, 'something wrong happened...Check if the assistant(s) are not related to other consul or if the district is already taken')
                    }
                }).catch(e => {
                    this.showNotification(false, 'something wrong happened...')
                    this.setState({processing: false})
                })
            })
        }

    }

    showNotification = (success, message) => {
        return notification.display(message, success ? 'success': 'danger')
    }


    render() {
        return (
            <div className="flex-column">

                {this.state.notification ?
                    <div className="uk-margin inline-content-center">

                        {
                            this.state.success ?
                                <input class="uk-input uk-form-success uk-form-width-expand" type="text"
                                       placeholder="form-success" value={this.state.message}></input>
                                :
                                <input class="uk-input uk-form-danger uk-form-width-expand" type="text"
                                       placeholder="form-danger" value={this.state.message}></input>
                        }

                    </div>

                    : null
                }

                <div className="tab-menu">

                    <ul className="uk-child-width-expand" uk-tab="media: @s">
                        <li className="uk-active"><a onClick={this.handleTab} id="infos" href="#infos">Infos Personnelles</a></li>
                        <li><a onClick={this.handleTab} href="#intentions" id="intentions">Intentions</a></li>
                        <li><a onClick={this.handleTab} href="#propositions" id="propositions">Propositions</a></li>
                        <li><a onClick={this.handleTab} href="#events" id="events">Évènements</a></li>
                        <li><a onClick={this.handleTab} href="#socials-networks" id="socials-networks">Réseaux sociaux</a></li>

                    </ul>

                </div>

                {
                    this.state.mainContainer
                }
                <div className="modal-footer inline-content-center-no-space ">
                    <button onClick={this.cancelAction} type="submit"
                            className="uk-button uk-button-large" disabled={this.state.processing}>ANNULER
                    </button>
                    <div className="spacer-1"></div>
                    <button onClick={this.updateConsularAdvisor} type="submit"
                            className="uk-button primary-theme-color text-white uk-button-large">METTRE À JOUR { this.state.processing ? <div className="uk-margin-small-left" uk-spinner=""/>: null}
                    </button>
                </div>

            </div>
        )
    }
}

export default EditConsularAdvisorForm
