import React, { Component } from 'react'

export class CampaignItem extends Component {

    showCampaignDetails = () => {
        this.props.campaignFocusScreen(this.props.campaign)
    }

    editCampaignAction = () => {

        console.log('edit campaign action')

    }

    deleteCampaignAction = () => {
        console.log('delete campaign action')
    }


    render() {

        const campaign = this.props.campaign


        return (
            <React.Fragment >


                <tr>
                    <td><div class="flex-column">
                        <dt><a onClick={this.showCampaignDetails}>{campaign.name}</a></dt>
                        <div><span uk-icon="icon: forward"></span><span>{campaign.send}</span></div>
                    </div></td>
                    <td><div class="flex-column">

                        <div><span className="text-primary"><b>Opens: </b></span>{campaign.opens + "%"}</div>

                        <div><span className="text-primary"><b>Clicks: </b></span>{campaign.clicks + "%"}</div>
                    </div></td>
                    <td><ul className="uk-iconnav">
                        <li><a onClick={this.editCampaignAction} className="primary-theme-color" href="#" uk-icon="icon: pencil; ratio: 1.5"></a></li>
                        <li><a onClick={this.deleteCampaignAction} className="primary-theme-color" href="#" uk-icon="icon: close; ratio: 1.5"></a></li>
                    </ul></td>
                </tr>


            </React.Fragment>
        )
    }
}

export default CampaignItem
