import React, { Component } from 'react'

export class ListMemberDialogDisplayItem extends Component {
    render() {

        const member = this.props.member

        return (
            <div>
                <div className="member-item-list default-divider ">

                    <div class="flex-column">
                        <dt><a>{member.email_address}</a></dt>
                    </div>
                    <div class="flex-column">
                        <div className="inline-content-center">
                            <div><span uk-icon="icon: calendar"></span></div>
                            <div className="spacer-1"></div>
                            <div><span>{member.birthDate}</span></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ListMemberDialogDisplayItem
