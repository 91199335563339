import React, { Component } from 'react'

export class Dialog extends Component {

    state = {
        childs: this.props.childs
    }



    cancelAction = () => {
        this.props.hideDialog()
    }

    validateAction = () => {

        this.props.mainAction()

    }

    render() {

        const title = this.props.title ? this.props.title : 'VALIDATE'

        return (
            <div className="modal">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="inline-content-center">{title.toUpperCase()}</h1>
                    </div>
                    <div className="sizedbox-height-5"></div>
                    {
                        !this.props.multipleSteps ? (<React.Fragment>
                            <div className="modal-body flex-column">
                                {this.state.childs}
                            </div>
                            <div className="sizedbox-height-5"></div>

                            <div className="modal-footer inline-content-center-no-space ">
                                <button onClick={this.cancelAction} type="submit" className="uk-button">CANCEL</button>
                                <div className="spacer-1"></div>
                                <button onClick={this.validateAction} type="submit" className="uk-button primary-theme-color text-white">CONFIRM</button>
                            </div>
                        </React.Fragment>) : (<React.Fragment >{this.props.stepContainer}</React.Fragment >)
                    }
                </div>
            </div>
        )
    }
}

export default Dialog
