import React, { Component } from 'react'
import FileHelper from '../../helpers/FileHelper'
import memberController from '../../controller/memberController'
import listsController from '../../controller/listsController'
export class MemberImportFromCsvMatchingFields extends Component {

    state = {
        mappingFields: {},
        error: false,
        membersApiFields: [],
        lists: this.props.account.lists
    }

    onFieldChange = (e) => {
        this.hideNotification()
        this.state.mappingFields[e.target.id] = parseInt(e.target.value, 10)
    }


    validateMapping = () => {

        const keys = Object.keys(this.state.mappingFields)


        if (!(keys.length === (this.state.membersApiFields.length))) {

            return false;
        }


        for (let i = 0; i < keys.length; i++) {


            for (let j = i + 1; j < keys.length; j++) {

                if ((this.state.mappingFields[keys[i]] === this.state.mappingFields[keys[j]]) || isNaN(this.state.mappingFields[keys[j]])) return false;
            }

        }

        return true;
    }

    prevStep = () => {
        this.props.updateStep(0)
    }

    validate = () => {
        if (this.validateMapping()) {

            const csvFileAsArray = this.props.getCsvArrayContent()
            const csvFileAsJsonArray = FileHelper.processArrayCsvToMappedObjects(csvFileAsArray, this.state.membersApiFields, this.state.mappingFields)
            // memberController.registerFromCsvArray(csvFileAsJsonArray).then(res => console.log(res.data))
        }
        else {
            this.showNotification()
        }
    }

    hideNotification = () => {
        this.setState({ error: false })
    }

    showNotification = () => {
        this.setState({ error: true })
    }

    onListChange = (e) => {

        this.setState({ mappingFields: {} })
        try {

            const list = this.state.lists[e.target.value]

            listsController.getListMergeFields(list.id).then(res => this.setState({ membersApiFields: res.data.merge_fields }))


        } catch (e) {

        }
    }



    render() {

        const membersCsvFields = this.props.csvFields
        return (
            <div >



                {
                    this.state.error ? (<div className='uk-alert-danger inline-content-center-no-space' uk-alert>

                        <p>Error, make sure all the selected fields are different <a class="uk-alert-close" uk-icon="icon: close; ratio: 1.5"></a></p>

                    </div>) : (<div></div>)
                }


                {
                    membersCsvFields.map((csvField, csvFieldIndex) => csvField.length !== 0 ? (

                        <div className='inline-content-center'>
                            <h4>
                                {csvField + ' :'}
                            </h4>
                            <div className="spacer-2"></div>

                            <div className="inline-content-center-no-space">
                                <span className="primary-theme-color-foreground" uk-icon={"icon: user ; ratio: 1.5"}></span>
                                <div className="spacer-1"></div>
                                <form>
                                    <div class="uk-margin">
                                        <div uk-form-custom="target: > * > span:first-child">
                                            <select id={csvFieldIndex} onChange={this.onFieldChange}>
                                                <option value="">Please select...</option>
                                                {this.state.membersApiFields.map((apiField, index) => <option value={index}>{apiField.name}</option>)}

                                            </select>
                                            <button class="uk-button uk-button-default" type="button" >
                                                <span></span>
                                                <span className="primary-theme-color-foreground" uk-icon="icon: chevron-down"></span>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>


                        </div>

                    ) : null)
                }
                <div className="inline-content-center-no-space">

                    <span className="primary-theme-color-foreground" uk-icon={"icon: file-text ; ratio: 2"}></span>
                    <div className="spacer-1"></div>
                    <form>
                        <div class="uk-margin">
                            <div uk-form-custom="target: > * > span:first-child">
                                <select onChange={this.onListChange}>
                                    <option value="">list...</option>
                                    {this.state.lists.map((list, index) => <option value={index}>{list.name}</option>)}

                                </select>
                                <button class="uk-button uk-button-default" type="button" >
                                    <span></span>
                                    <span className="primary-theme-color-foreground" uk-icon="icon: chevron-down"></span>
                                </button>
                            </div>
                        </div>
                    </form>


                </div>
                <div className="sizedbox-height-5"></div>

                <div className="modal-footer inline-content-center-no-space ">
                    <button onClick={this.prevStep} type="submit" className="uk-button rounded-btn uk-button-small">PREVIOUS</button>
                    <div className="spacer-1"></div>
                    <button onClick={this.validate} type="submit" className="uk-button rounded-btn uk-button-small">VALIDATE</button>
                </div>


            </div>
        )
    }
}

export default MemberImportFromCsvMatchingFields
