import React, {Component} from 'react'
import consularController from '../../../../controller/consularController'
import notification from '../../../../services/notification'

class EditSympathizerForm extends Component {
    state = {
        files:this.props.files,
        folders: this.props.folders,
        image: this.props.consul.image,
        firstName:this.props.consul.firstName,
        lastName:this.props.consul.lastName,
        district:this.props.consul.district,
        districts: this.props.districts,
        cities: [],
        selectedDistrictIndex: -1,
        selectedCityIndex: -1,
        cityUuid: this.props.consul.cityUuid,
        link:this.props.consul.link,
        processing: false,
        processingFiles: false,
    }

    componentWillMount() {

        let selectedDistrictIndex=-1
        let selectedCityIndex = -1
        this.props.districts.forEach((district,index)=>{

            if(district.uuid.toString()===this.state.district.uuid.toString()){

                const cities = district.cities

                cities.forEach((city,cityIndex)=>{
                    if(city.uuid.toString()=== this.state.cityUuid){

                        selectedDistrictIndex = index
                        selectedCityIndex = cityIndex

                    }

                })
            }

        })

        this.setState({selectedDistrictIndex,selectedCityIndex})
    }

    onFolderChange = e =>{
        const folderIndex = e.target.value;

        if(folderIndex.toString()!=='-1'){
            const folder = this.state.folders[folderIndex]
            this.setState({processingFiles: true}, async () => {
                const images = (await consularController.getFilesByFolderId(folder.id, folder.created_at.toString().split('T')[0])).data.files
                this.setState({files: images, processingFiles: false, folder})
            })
        }
    }

    onFileChange = e=>{
        const fileIndex = e.target.value;

        if(fileIndex.toString()!=='-1'){
            const file = {
                ...this.state.files[fileIndex],
                url:this.state.files[fileIndex].full_size_url
            }
            this.setState({image: file})
        }

    }

    handleFieldChange = e =>{

        this.setState({[e.target.name]:e.target.value})
    }


    // Personal Info
    onDistrictChange = e => {

        const district = this.state.districts[e.target.value]
        const cities = district.cities
        const city = {}
        const selectedDistrictIndex = e.target.value
        const selectedCityIndex = -1

        this.setState({ cities, city, selectedDistrictIndex, selectedCityIndex })
    }

    onCityChange = e => {

        const selectedCityIndex = e.target.value
        this.setState({ selectedCityIndex })
    }

    cancelAction = e =>{
        this.props.hideDialog()
    }

    updateSympathizer = e =>{
        const sympathizerUuid = this.props.consul.uuid
        const firstName = this.state.firstName
        const lastName = this.state.lastName
        const district = this.props.districts[this.state.selectedDistrictIndex]
        const districtUuid = district.uuid
        const cityUuid = district.cities[this.state.selectedCityIndex].uuid
        const link = this.state.link
        const image = {
            ...this.state.image,
            url:this.state.image.url
        }

        if (firstName.length <= 0) {
            return this.showNotification(false, 'Fill the First name please...')
        }
        if (lastName.length <= 0) {
            return this.showNotification(false, 'Fill the Last name please...')
        }

        if (image === null) {
            return this.showNotification(false, 'Select an image please...')
        }

        if (districtUuid.length <= 0) {
            return this.showNotification(false, 'Select a district...')
        }

        if (link.length <= 0) {
            return this.showNotification(false, 'Fill a link...')
        }

        const data = {
            sympathizerUuid,
            firstName,
            lastName,
            image,
            districtUuid,
            cityUuid,
            link
        }

        this.setState({processing: true}, () => {
            consularController
            .updateSympathizer(data)
            .then(res=>res.status===200)
            .then(resp=>{
                if(resp)this.props.updateConsuls().then(()=>{
                    this.showNotification(true, 'Sympathizer updated...')    
                    this.cancelAction()
                })
            }).catch(err=>{
                this.setState({processing: false})
                this.showNotification(false, 'Something wrong happened...')
            })
        })

    }

    showNotification = (success, message) => {
        return notification.display(message, success ? 'success': 'danger')
    }

    render() {

        const cities = this.state.selectedDistrictIndex!==-1 ? this.state.districts[this.state.selectedDistrictIndex].cities : []

        return (
            <div className="centered">

                <div className="flex-row-center">

                    <div className="uk-width-auto">
                        <img className="uk-comment-avatar circular" src={this.state.image.url} width="100" height="100"
                             alt="consul-image"/>
                    </div>

                    <div className="sizedbox-width-5"/>
                    <div className="flex-column">
                        <div className="uk-inline content-20w">
                            <span className="uk-form-icon" uk-icon="icon: user" />
                            <input name="firstName" placeholder="first name" onChange={this.handleFieldChange} className="uk-align-center uk-input bottom-border no-border" type="text" value={this.state.firstName} />
                        </div>



                        <div className="uk-inline content-20w">
                            <span className="uk-form-icon" uk-icon="icon: user" />
                            <input name="lastName" placeholder="last name" onChange={this.handleFieldChange} className="uk-align-center uk-input bottom-border no-border" type="text" value={this.state.lastName} />
                        </div>
                    </div>
                </div>

                <div className="inline-content-center-no-space">
                        <span className="primary-theme-color-foreground" uk-icon={"icon: folder ; ratio: 2"}></span>
                        <div className="spacer-1"></div>
                        <form>
                            <div className="uk-margin">
                                <div uk-form-custom="target: > * > span:first-child">
                                    <select onChange={this.onFolderChange}>
                                        <option value={-1}>dossiers...</option>
                                        {this.state.folders.map((folder, index) => <option value={index}>{folder.name}</option>)}
                                    </select>
                                    <button className="uk-button uk-button-default" type="button">
                                        <span></span>
                                        <span className="primary-theme-color-foreground"
                                              uk-icon="icon: chevron-down"></span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>

                <div className="inline-content-center-no-space">
                    <span className="primary-theme-color-foreground" uk-icon={"icon: image ; ratio: 2"}></span>
                    <div className="spacer-1"></div>
                    <form>
                        <div className="uk-margin">
                            <div uk-form-custom="target: > * > span:first-child">
                                <select onChange={this.onFileChange} disabled={this.state.processingFiles}>
                                    <option value={-1}>images...</option>
                                    {this.state.files.map((file, index) => <option value={index} selected={file.id.toString()===this.state.image.id.toString()}>{file.name}</option>)}

                                </select>
                                <button className="uk-button uk-button-default" type="button">
                                    <span></span>
                                    <span className="primary-theme-color-foreground"
                                          uk-icon="icon: chevron-down"></span>
                                </button>
                            </div>
                        </div>
                    </form>
                    { this.state.processingFiles ? <div className="uk-margin-small-left" uk-spinner=""/>: null }
                </div>

                <div className="uk-inline content-20w">
                    <span className="uk-form-icon" uk-icon="icon: link" />
                    <input name="link" placeholder="website link" onChange={this.handleFieldChange} className="uk-align-center uk-input bottom-border no-border" type="text" value={this.state.link} />
                </div>

                <div className="spacer-1"/>

                <div className="inline-content-center-no-space">
                    <span className="primary-theme-color-foreground" uk-icon={"icon: location ; ratio: 1.5"}></span>
                    <div className="spacer-1"/>
                    <form>
                        <div className="uk-margin">
                            <div uk-form-custom="target: > * > span:first-child">
                                <select onChange={this.onDistrictChange}>

                                    <option value={this.state.selectedDistrictIndex} selected>{this.state.districts[this.state.selectedDistrictIndex].name}</option>
                                    {this.props.districts.map((district, index) => {
                                        if(index.toString()!==this.state.selectedDistrictIndex.toString()){
                                            return <option value={index}>{district.name}</option>
                                        }
                                    })}

                                </select>
                                <button className="uk-button uk-button-default" type="button">
                                    <span/>
                                    <span className="primary-theme-color-foreground"
                                          uk-icon="icon: chevron-down"/>
                                </button>
                            </div>
                        </div>
                    </form>

                    <div className="spacer-1"/>

                    <form>
                        <div className="uk-margin">
                            <div uk-form-custom="target: > * > span:first-child">
                                <select onChange={this.onCityChange}>

                                    {this.state.selectedCityIndex!==-1?<option value={this.state.selectedCityIndex} selected>{this.state.districts[this.state.selectedDistrictIndex].cities[this.state.selectedCityIndex].name}</option>:<option value={-1}>City...</option>}
                                    {cities.map((city, index) => {
                                        if(index.toString()!==this.state.selectedCityIndex.toString()) return<option value={index}>{city.name}</option>
                                    })}

                                </select>
                                <button className="uk-button uk-button-default" type="button">
                                    <span></span>
                                    <span className="primary-theme-color-foreground"
                                          uk-icon="icon: chevron-down"></span>
                                </button>
                            </div>
                        </div>
                    </form>

                </div>

                <div className="modal-footer inline-content-center-no-space ">
                    <button onClick={this.cancelAction} type="submit" className="uk-button uk-button-large" disabled={this.state.processing}>CANCEL</button>
                    <div className="spacer-1"></div>
                    <button onClick={this.updateSympathizer} type="submit" className="uk-button primary-theme-color text-white uk-button-large">UPDATE{ this.state.processing ? <div className="uk-margin-small-left" uk-spinner=""/>: null}</button>
                </div>
            </div>
        );
    }
}

export default EditSympathizerForm;