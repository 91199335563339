import React, { Component } from 'react'
import memberController from '../../controller/memberController';

export class DeleteMemberForm extends Component {



    state = {
        members: '',
        success: false,
        notification: false,
        message: ''
    }

    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    cancelAction = (e) => {

        this.props.hideDialog()
    }

    deleteMember = (e) => {

        if (this.state.members.trim().length > 0) {
            const members = this.state.members.split(',')


            members.forEach(member => {

                member = member.trim()
                let totalCandidates = 0
                memberController.findMember(member).then(res => {
                    const candidates = res.data.exact_matches.members
                    totalCandidates += candidates.length
                    this.showNotification(true, `${candidates.length} found for ${member}...`)
                    candidates.forEach(candidate => {

                        const listId = candidate.list_id
                        const memberId = candidate.id

                        this.props.deleteMemberAction(listId, memberId)


                    })
                })

                this.showNotification(true, `${totalCandidates} send for unsubscription...`)

                setTimeout(() => {
                    this.setState({ notification: false })
                    this.props.hideDialog()
                }, 5000)
            })
        } else {
            this.showNotification(false, 'Please enter valid string...')
            setTimeout(() => {
                this.setState({ notification: false })
            }, 3000)
        }


    }

    showNotification = (success, message) => {

        this.setState({ notification: true, success, message })
    }
    render() {
        return (
            <div className="flex-column">

                {this.state.notification ?
                    <div className="uk-margin inline-content-center">

                        {
                            this.state.success ? <input class="uk-input uk-form-success uk-form-width-expand" type="text" placeholder="form-success" value={this.state.message}></input>
                                :
                                <input class="uk-input uk-form-danger uk-form-width-expand" type="text" placeholder="form-danger" value={this.state.message}></input>
                        }

                    </div>

                    : null
                }

                <div className="inline-content-center-no-space">
                    <div class="uk-margin">
                        <textarea onChange={this.handleInputChange} name="members" placeholder="enter email separated with ',' " rows="5" class="uk-input uk-margin-remove" type="text" value={this.state.members}></textarea>
                    </div>
                </div>


                <div className="modal-footer inline-content-center-no-space ">
                    <button onClick={this.cancelAction} type="submit" className="uk-button uk-button-large">CANCEL</button>
                    <div className="spacer-1"></div>
                    <button onClick={this.deleteMember} type="submit" className="uk-button uk-button-large primary-theme-color text-white">DELETE</button>
                </div>

            </div>
        )
    }
}

export default DeleteMemberForm
