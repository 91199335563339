import React, { Component } from 'react'

export class ListItem extends Component {

    editListAction = (e) => {
        console.log('Edit list action')
    }

    deleteListAction = (e) => {
        console.log('Delete list action')
    }

    showListDetails = (e) => {
        this.props.listFocusScreen(this.props.list)
    }

    render() {

        const list = this.props.list

        return (
            <React.Fragment >
                <tr>
                    <td><div class="flex-column">
                        <dt><a onClick={this.showListDetails}>{list.name}</a></dt>
                        <div><span uk-icon="icon: user"></span><span>{list.members}</span></div>
                    </div></td>
                    <td><div class="flex-column">
                        <div className="inline-content">
                            <dd><b className="text-primary">Opens: </b>{list.opens + "%"}</dd>
                        </div>
                        <dd><b className="text-primary">Clicks: </b> {list.clicks + "%"}</dd>
                    </div></td>
                    <td><ul className="uk-iconnav">
                        <li><a onClick={this.editListAction} className="primary-theme-color" href="#" uk-icon="icon: pencil; ratio: 1.5"></a></li>
                        <li><a onClick={this.deleteListAction} className="primary-theme-color" href="#" uk-icon="icon: close; ratio: 1.5"></a></li>
                    </ul></td>
                </tr>
            </React.Fragment>
        )
    }
}

export default ListItem
