import React, { Component } from 'react'

export class PropositionContent extends Component {


    state = {
        value: ''
    }

    componentWillMount() {

        const value = this.props.object.value

        this.setState({ value })
    }

    handleChange = e => {


        const id = this.props.object.id
        const position = this.props.object.position
        const value = e.target.value

        const proposition = {
            id,
            value,
            position
        }

        this.setState({ value })
        this.props.updateProposition(proposition)

    }

    removeProposition = e => {

        this.props.removeProposition(this.props.object)
    }
    render() {

        const value = this.state.value
        const size = value.length
        return (


            <div className="flex-row centered">

                <p>#{this.props.index+1}</p>
                <div class="uk-margin">
                <span class={`uk-label uk-label-${size<=1024 && size>0? 'success' :'danger'}`}>{size<=1024 && size>0 ? 'OK':'Error'}</span><span>({`${value.length} character${value.length>0?'s':''}`})[max: 1024]</span>
                    <textarea onChange={this.handleChange} class="uk-textarea" rows="5" cols="70" placeholder="Proposition" value={value}></textarea>
                </div>
                <div>
                    <a onClick={this.removeProposition} className="primary-theme-color" href="#" uk-icon="icon: close; ratio: 1.5"></a>
                </div>
            </div>

        )
    }
}

export default PropositionContent
