import React, { Component } from 'react'

export class MemberListItem extends Component {




    editMemberAction = (e) => {

        console.log('Edit action')
    }


    deleteMemberAction = (e) => {

        if (window.confirm("Are you sure to unsubscribe this member?")) {
            const listId = this.props.member.list_id
            const memberId = this.props.member.id

            this.props.deleteMemberAction(listId, memberId)
        }
    }


    render() {

        const member = this.props.member
        return (
            <React.Fragment>

                <tr>
                    <td> <div className="flex-column ">
                        <dt><a>{member.name}</a></dt>
                        <dd>{member.email_address}</dd>
                        <dd>{member.status}</dd>
                    </div></td>
                    <td><dt><a>{member.listName ? member.listName : null}</a></dt><div >
                        Opens : {member.opens + "%"}
                    </div>
                        <div >
                            Clicks : {member.clicks + "%"}
                        </div>
                    </td>
                    <td><ul className="uk-iconnav">
                        <li><a onClick={this.editMemberAction} className="primary-theme-color" href="#" uk-icon="icon: pencil; ratio: 1.5"></a></li>
                        <li><a onClick={this.deleteMemberAction} className="primary-theme-color" href="#" uk-icon="icon: close; ratio: 1.5"></a></li>
                    </ul></td>
                </tr>

            </React.Fragment>


        )
    }
}

export default MemberListItem
