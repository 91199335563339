import React from 'react'
import { Redirect } from 'react-router-dom'
import userController from '../../../controller/userController'
import * as routes from '../../../utils/routes'

export default class SignInFormValidation extends React.Component {


    state = {
        email: '',
        password: '',
        signed: false,
        loading: true,
        error: false,
        message: '',
        processing: false
    }

    componentDidMount() {
        userController.validateToken().then(res => {
            return this.setState({ signed: res.data, loading: false })
        }).catch(e => {
            localStorage.clear()
            this.setState({ signed: false, loading: false})
        })
    }

    handleFieldChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });

    };
    submitForm = (e) => {
        e.preventDefault();

        const userCredentials = {
            username: this.state.email,
            password: this.state.password
        }

        if(userCredentials.username.length > 0 && userCredentials.password.length > 0) {
            this.setState({error: false , processing: true}, () => {
                const res = userController.signIn(userCredentials)
                res.then(response => {
                    localStorage.setItem('jwt', (response.data.jwt))
                    localStorage.setItem('username', (response.data.username))
                    localStorage.setItem('signed', true)
                    this.setState({ signed: true, processing: false })
    
                }).catch(err => this.setState({error: true, processing: false, message: 'Identifiant ou mot de passe incorrect'}))
            })
        } else {
            this.setState({error: true, message: 'Saisis ton identifiant et ton mot de passe'})
        }
    };

    render() {

        if (this.state.signed) return <Redirect to={routes.HOME_ROUTE} />

        return !this.state.loading ?(
                <div>
                    <head>
                    <title>Connexion ASFE</title>
                    <meta charSet="UTF-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />

                    <link rel="icon" type="image/png" href="https://alliancesolidaire.org/wp-content/uploads/2019/10/cropped-flavicon2-copie-32x32.png" />
                    <link rel="stylesheet" type="text/css" href="fonts/iconic/css/material-design-iconic-font.min.css" />
                    <link rel="stylesheet" type="text/css" href="css/main.css" />
                </head>
                <body>

                    <div className="limiter">
                        <div className="container-login100">
                            <div className="wrap-login100">
                                <form className="login100-form validate-form flex-column" onSubmit={this.submitForm}>

                                    <div className="flex-column-center">
                                        <img alt='asfe-logo' src="logo-white.png" height="150" width="250" />
                                    </div>

                                    <span className="login100-form-title p-b-34 p-t-27">
                                        Se connecter
					                </span>

                                    {this.state.error && <p className="error">{this.state.message}</p>}

                                    <div className="wrap-input100 validate-input">
                                        <input name="email" onChange={this.handleFieldChange} className="input100"
                                            type="text" placeholder="email" />
                                        <span className="focus-input100" data-placeholder="&#xf207;" />
                                    </div>

                                    <div className="wrap-input100 validate-input">
                                        <input name="password" onChange={this.handleFieldChange} className="input100"
                                            type="password" placeholder="mot de passe" />
                                        <span className="focus-input100" data-placeholder="&#xf191;" />
                                    </div>

                                    <div className="container-login100-form-btn">
                                        <button type="submit" className="login100-form-btn" disabled={this.state.processing}>
                                            connexion {this.state.processing ? <div className="uk-margin-small-left" uk-spinner=""/>: null}
                                    </button>
                                    <a target="_blank" rel="noopener noreferrer" className="uk-link-text uk-margin-top" href="https://alliancesolidaire.org/mentions-legales/">Protection des données et mentions légales</a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </body>
                </div>
        ):<div></div>
    }


}
