import React, { Component } from 'react'
import MemberListItem from './MemberListItem'
import memberController from '../../controller/memberController'
import listController from '../../controller/listsController'
import MemberImportFromCsvMatchingFields from './MemberImportFromCsvMatchingFields'
import StepsContainer from './StepsContainer'
import AddMemberForm from './AddMemberForm'
import DeleteMemberForm from './DeleteMemberForm'
import Dialog from '../dialog'
import filterHelper from '../../helpers/FilterHelper'

export class MemberScreen extends Component {


    state = {
        ...filterHelper.common,
        lists: this.props.account.lists,
        loading: false,
        loadingLists: false,
        selectedListId: '',
        email: '',
        activeSearch: false,
        notification: false,
        success: false,
        message: '',
        title:''
    }

    getAllMergeFieldsValidation = async lists => {


    }


    updateDetails = (e) => {
        this.setState({
            details: true
        })
    }

    displayMemberForm = () => {


        this.getAllMergeFieldsValidation(this.state.lists)
        if (!this.state.loadingLists) {
            this.setState({
                openDialog: true,
                title:'ADD MEMBER',
                dialogChilds: <AddMemberForm hideDialog={this.hideDialog} lists={this.state.lists} />
            })
        } else {
            this.showNotification(false, 'Lists still loading...')
        }
    }

    displayDeleteForm = () => {
        this.setState({
            openDialog: true,
            title:'DELETE MEMBER',
            dialogChilds: <DeleteMemberForm hideDialog={this.hideDialog} deleteMemberAction={this.deleteMemberAction} />
        })
    }

    importCSV = (e) => {

        if (!this.state.loadingLists) {
            this.setState({
                openDialog: true,
                title:'IMPORT CSV',
                dialogChilds: <StepsContainer lists={this.state.lists} hideDialog={this.hideDialog} updateFileContent={this.updateFileContent} apiFields={this.state.membersFields} account={this.props.account} />
            })
        } else {

            this.showNotification(false, 'Lists still loading...')
        }
    }

    hideDialog = () => {
        this.setState({
            openDialog: false
        })
    }

    updateFileContent = (fileAsArray) => {
        this.setState({
            fileAsArray
        })
    }

    uploadAction = () => {
        // Update scene to select fields match
        this.setState({
            dialogChilds: <MemberImportFromCsvMatchingFields csvFields={this.state.fileAsArray[0].split(';')} apiFields={this.state.membersFields} />
        })
    }

    handlePagination = (e) => {
        e.preventDefault()

    }

    onLimitChange = (e) => {
        try {
            this.setState({ loading: true, notification: false, activeSearch: false, success: false })
            const limit = this.state.limits[e.target.value]

            const totalItems = this.state.totalItems
            const paginationInf = 0
            const paginationSup = limit < totalItems ? limit : totalItems

            listController.getMembersInList(this.state.selectedListId, limit).then(res => {


                const members = res.data.members
                const tmpMembers = members
                this.setState({
                    members, tmpMembers, loading: false,
                    limit, paginationInf, paginationSup
                })
            })

        } catch (e) {

        }
    }

    onListChange = (e) => {
        const selectedListId = this.state.lists[e.target.value].id

        this.loadingToggleStart()
        this.setState({ notification: false, activeSearch: false, success: false })
        listController.getMembersInList(selectedListId, this.state.limit).then(res => {

            const members = res.data.members
            const tmpMembers = members
            const totalItems = res.data.total_items
            const paginationInf = totalItems > 0 ? 1 : 0
            const paginationSup = totalItems > this.state.limit ? this.state.limit : totalItems

            this.setState({ members, tmpMembers, loading: false, selectedListId, totalItems, paginationInf, paginationSup })
        })
    }

    rightPaginationUpdate = () => {

        const limit = this.state.limit
        const totalItems = this.state.totalItems
        const offset = this.state.offset + limit
        let diff = totalItems - offset


        if (diff > 0) {

            this.loadingToggleStart()

            const paginationInf = offset
            const paginationSup = offset + limit < totalItems ? paginationInf + limit : offset + diff

            listController.getMembersInList(this.state.selectedListId, this.state.limit, paginationInf).then(res => {
                // this.state.tmpMembers = res.data.members
                this.state.members = res.data.members
                // this.state.members = this.gatheredFilter()
                this.setState({
                    paginationInf, paginationSup, offset,
                    loading: false
                })
            })
        }

    }

    leftPaginationUpdate = () => {



        const limit = this.state.limit
        const totalItems = this.state.totalItems
        const offset = limit < totalItems ? limit : totalItems


        const paginationSup = this.state.paginationInf
        const paginationInf = this.state.paginationInf - offset


        if (paginationInf >= 0) {
            this.setState({ loading: true })
            listController.getMembersInList(this.state.selectedListId, this.state.limit, paginationInf).then(res => {

                // this.state.tmpMembers = res.data.members
                this.state.members = res.data.members
                // this.state.members = this.gatheredFilter()

                this.setState({
                    paginationInf, paginationSup,
                    loading: false
                })
            })
        }

    }

    loadingToggleStart = () => {
        this.setState({ loading: true })
    }

    loadingToggleEnd = () => {
        this.setState({ loading: false })
    }

    handleInputChange = (e) => {

        const email = e.target.value
        this.setState({ [e.target.name]: email })

        if (email.length === 0) {

            this.setState({ members: this.state.tmpMembers, activeSearch: false })

        }
    }

    onUserFilterChange = (e) => {

        if (!this.state.loadingLists) {
            const userEmail = this.state.email
            this.setState({ activeSearch: false })
            if (userEmail.length > 0) {
                this.loadingToggleStart()
                memberController.findMember(userEmail).then(res => {
                    let members = res.data.exact_matches.members
                    const fullSearchMembers = res.data.full_search.members

                    Array.prototype.push.apply(members, fullSearchMembers)
                    members = members.map(m => {

                        const list = this.state.lists.filter(l => l.id === m.list_id)
                        console.log(list)
                        m = {
                            ...m,
                            listName: list[0].name
                        }

                        return m
                    })

                    this.setState({ members, activeSearch: true })
                    this.loadingToggleEnd()
                })
            }
        } else {
            this.showNotification(false, 'Lists still loading...')

        }

    }


    deleteMemberAction = (listId, memberId) => {
        listController.deleteMembersInList(listId, memberId).then(res => {

            if (res.status === 200) {

                if (this.state.selectedListId.length > 0) {
                    const selectedListId = this.state.selectedListId
                    this.loadingToggleStart()
                    listController.getMembersInList(selectedListId).then(res => {

                        const members = res.data.members
                        const totalItems = res.data.total_items

                        this.setState({ members, loading: false, selectedListId, totalItems })

                    })
                }
            }
        })
    }

    showNotification = (success, message) => {

        this.setState({ notification: true, success, message })
        setTimeout(() => {
            this.setState({ notification: false, success: false, message: '' })
        }, 3000)
    }


    render() {


        const members = filterHelper.mapMembers(this.state.members)



        return (
            <div>
                <div className="sizedbox-height-5"></div>
                <div className="uk-flex uk-flex-around uk-flex-wrap uk-flex-wrap-around">
                    <h1>MEMBERS</h1>
                    <button onClick={this.displayMemberForm} type="submit" className="uk-margin uk-button primary-theme-color text-white">ADD MEMBER</button>
                    <button onClick={this.displayDeleteForm} type="submit" className="uk-margin uk-button primary-theme-color text-white">DELETE MEMBER</button>
                    <button onClick={this.importCSV} type="submit" className="uk-margin uk-button primary-theme-color text-white">IMPORT CSV</button>
                </div>

                {this.state.openDialog ? (<Dialog lists={this.state.lists} key='dialog' title={this.state.title} multipleSteps={true} stepContainer={this.state.dialogChilds} childs={this.state.dialogChilds} hideDialog={this.hideDialog} mainAction={this.uploadAction} />) : (<div></div>)}

                <div className="sizedbox-height-5"></div>

                <div className="items-container uk-padding">

                    {this.state.notification ?
                        <div className="uk-margin inline-content-center">
                            {
                                this.state.success ? <input class="uk-input uk-form-success uk-form-width-medium" type="text" placeholder="form-success" value={this.state.message}></input>
                                    :
                                    <input class="uk-input uk-form-danger uk-form-width-medium" type="text" placeholder="form-danger" value={this.state.message}></input>
                            }
                        </div>
                        : null
                    }
                    <div className="uk-flex uk-flex-between uk-flex-middle uk-flex-wrap">
                        <div className="uk-flex uk-flex-around">
                            <span className="primary-theme-color-foreground" uk-icon={"icon: ban ; ratio: 2"}></span>
                            <div className="spacer-1"></div>
                            <form>
                                <div class="uk-margin">
                                    <div uk-form-custom="target: > * > span:first-child">
                                        <select onChange={this.onLimitChange}>
                                            <option value="">limit</option>
                                            {this.state.limits.map((limit, index) => <option value={index}>{limit}</option>)}

                                        </select>
                                        <button class="uk-button uk-button-default" type="button" >
                                            <span></span>
                                            <span className="primary-theme-color-foreground" uk-icon="icon: chevron-down"></span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="uk-flex uk-flex-around">
                            <span className="primary-theme-color-foreground" uk-icon={"icon: file-text ; ratio: 2"}></span>
                            <div className="spacer-1"></div>
                            <form>
                                <div class="uk-margin">
                                    <div uk-form-custom="target: > * > span:first-child">
                                        <select onChange={this.onListChange}>
                                            <option value="">Please select...</option>
                                            {this.state.lists.map((list, index) => <option value={index}>{list.name}</option>)}

                                        </select>
                                        <button class="uk-button uk-button-default" type="button" >
                                            <span></span>
                                            <span className="primary-theme-color-foreground" uk-icon="icon: chevron-down"></span>
                                        </button>
                                    </div>
                                </div>
                            </form>

                            {this.state.loadingLists ? <span class="uk-margin-small-right" uk-spinner="ratio: 1"></span> : null}
                        </div>
                        <div className="uk-flex uk-flex-around">
                            <div class="uk-margin">
                                <div class="uk-inline">
                                    <span class="uk-form-icon" uk-icon="icon: user"></span>
                                    <a onClick={this.onUserFilterChange} class="uk-form-icon uk-form-icon-flip primary-theme-color" uk-icon="icon: search"></a>
                                    <input onChange={this.handleInputChange} name="email" placeholder="email" class="uk-input uk-margin-remove" type="text" value={this.state.email}></input>
                                </div>
                            </div>
                        </div>

                        <div>
                            <button onClick={this.leftPaginationUpdate} type="submit" className="primary-theme-color-foreground" uk-icon="icon: chevron-left"></button>
                            <span>{this.state.paginationInf} - {this.state.paginationSup} of {this.state.totalItems}</span>
                            <button onClick={this.rightPaginationUpdate} type="submit" className="primary-theme-color-foreground" uk-icon="icon: chevron-right"></button>
                        </div>

                    </div>
                    <div className="sizedbox-height-5"></div>
                    {
                        this.state.activeSearch ? <h4>{this.state.members.length + ' result(s)'}</h4> : null
                    }
                    <div className="uk-overflow-auto">
                        {this.state.loading ? <div className="inline-content-center"><span class="uk-margin-small-right" uk-spinner="ratio: 3"></span></div> : <table class="uk-table uk-table-small uk-table-divider">
                            <thead>
                                <tr>
                                    <th>NAME</th>
                                    <th>STATS</th>
                                    <th>ACTIONS</th>
                                </tr>
                            </thead>
                            <tbody>
                                {members.map(member => {
                                    return <MemberListItem member={member} deleteMemberAction={this.deleteMemberAction} />
                                })}

                            </tbody>
                        </table>}
                    </div>
                </div>
                <div className="inline-content-center-no-space ">
                    <button onClick={this.leftPaginationUpdate} type="submit" className="primary-theme-color-foreground" uk-icon="icon: chevron-left"></button>
                    <span>{this.state.paginationInf} - {this.state.paginationSup} of {this.state.totalItems}</span>
                    <button onClick={this.rightPaginationUpdate} type="submit" className="primary-theme-color-foreground" uk-icon="icon: chevron-right"></button>
                </div>
                <div className="sizedbox-height-5"></div>
            </div>
        )
    }
}

export default MemberScreen
