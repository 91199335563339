
const HOST_BASE = 'https://applications.alliancesolidaire.org/asfe/api'
// const HOST_BASE = 'https://applications.alliancesolidaire.org:3000/asfe/api'
// const HOST_BASE = 'http://localhost:3000/asfe/api'

const CONSUL_HOST_BASE = 'https://applications.alliancesolidaire.org/asfe/consulaires/api/consuls'
// const CONSUL_HOST_BASE = 'http://localhost:3001/asfe/consulaires/api/consuls'

export const CONSUL_DATA_HOST_BASE = 'https://applications.alliancesolidaire.org/consuls/campaign/email/templates'
// export const CONSUL_DATA_HOST_BASE = 'http://localhost:3002/consuls/campaign/email/templates'



export const SIGN_IN_ROUTE = '/'
export const HOME_ROUTE = '/home'
export const NOT_FOUND_ROUTE = '/404'


// ASFE API HTTP ROUTES
export const ACCOUNT_INFOS = HOST_BASE + '/mailchimp/account'
export const ADMIN_SIGN_IN = HOST_BASE + '/admin/users/signin'
export const ADMIN_VALIDATE_JWT = HOST_BASE + '/admin/validate/jwt'

// LISTS
export const GET_LISTS = HOST_BASE + '/mailchimp/lists'
export const POST_LISTS_CSV_VALIDATE = HOST_BASE + '/mailchimp/lists/upload/validate'
export const POST_LISTS_CSV_DOWNLOAD = HOST_BASE + '/mailchimp/lists/download'
export const CREATE_MEMBERS_FROM_CSV = HOST_BASE + '/members/csv/register'
export const GET_CREATE_MEMBER_API_FIELD = HOST_BASE + '/members/dto/create'
export const GET_MEMBERS = HOST_BASE + '/members'


// REPORTS

export const GET_CAMPAIGNS_REPORTS = HOST_BASE + '/mailchimp/reports'
export const GET_CAMPAIGNS_REPORTS_EMAILS_ACTIVITY = 'email-activity'
export const GET_CAMPAIGNS_REPORTS_CLICKS_DETAILS = 'click-details'
export const GET_CAMPAIGNS_REPORTS_OPENS_DETAILS = 'open-details'

// SEARCH

export const SEARCH_MEMBERS = HOST_BASE + '/mailchimp/search-members'

// CONSULS
export const CONSULS_BASE = '/consuls'
export const CREATE_CONSUL = CONSUL_HOST_BASE
export const DELETE_CONSUL = CONSUL_HOST_BASE
export const UPDATE_CONSUL = CONSUL_HOST_BASE

// CONSUL SECTION

export const ASSISTANT_BASE = '/assistants'
export const GET_CONSULS = CONSUL_HOST_BASE
export const CREATE_ASSISTANT = CONSUL_HOST_BASE + ASSISTANT_BASE
export const UPDATE_ASSISTANT = CONSUL_HOST_BASE + ASSISTANT_BASE
export const DELETE_ASSISTANT = CONSUL_HOST_BASE + ASSISTANT_BASE
export const GET_ASSISTANTS = CONSUL_HOST_BASE + ASSISTANT_BASE

// Sympathizer

export const SYMPATHIZER_BASE = '/sympathizers'
export const GET_SYMPATHIZER = CONSUL_HOST_BASE + SYMPATHIZER_BASE
export const CREATE_SYMPATHIZER = CONSUL_HOST_BASE + SYMPATHIZER_BASE
export const UPDATE_SYMPATHIZER = CONSUL_HOST_BASE + SYMPATHIZER_BASE
export const DELETE_SYMPATHIZER = CONSUL_HOST_BASE + SYMPATHIZER_BASE

// DISTRICT

export const DISTRICT_BASE = CONSUL_HOST_BASE + '/districts'
export const GET_META_DISTRICT = CONSUL_HOST_BASE + '/meta'
export const DISTRICTS = DISTRICT_BASE
export const DELETE_DISTRICT = DISTRICT_BASE
export const UPDATE_DISTRICT = DISTRICT_BASE

// SOCIAL NETWORKS

export const SOCIAL_NETWORK_BASE = '/social-networks'
export const SOCIAL_NETWORK_TAGS_BASE = SOCIAL_NETWORK_BASE + '/tags'


export const GET_SOCIAL_NETWORKS_TAGS = CONSUL_HOST_BASE + SOCIAL_NETWORK_TAGS_BASE
export const DELETE_SOCIAL_NETWORK_TAG = CONSUL_HOST_BASE + SOCIAL_NETWORK_TAGS_BASE
export const CREATE_SOCIAL_NETWORK_TAG = CONSUL_HOST_BASE + SOCIAL_NETWORK_TAGS_BASE
export const UPDATE_SOCIAL_NETWORK_TAG = CONSUL_HOST_BASE + SOCIAL_NETWORK_TAGS_BASE

// FILES

export const FILES_BASE = '/mailchimp/files'
export const GET_FILES = HOST_BASE + FILES_BASE
export const FOLDER_BASE = '/mailchimp/folders'
export const GET_FOLDERS = HOST_BASE + FOLDER_BASE
