import React, { Component } from 'react'
import { Line } from 'react-chartjs-2'

export class chart extends Component {

    state = {
        data: {
            labels: ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"],
            datasets: [
                {
                    label: "Subscriptions",
                    backgroundColor: "#0d0b5a",
                    data: [8, 11, 7, 20, 10, 19, 20, 10, 12, 11, 45, 30]
                }
            ],
        }
    }
    render() {
        return (
            <Line
                options={{
                    responsive: true
                }}
                data={this.state.data}
                height={'70vh'}
                width={'100vw'}
            />
        )
    }
}

export default chart
