import React, { Component } from 'react'
import ListItem from './ListItem'
import ListScreenDetails from './ListScreenDetails'
import listsController from '../../controller/listsController'

export class ListsScreen extends Component {


    state = {
        lists: [],
        tmpLists: this.props.account.lists,
        listsTotal: 0,
        openDialog: false,
        dialogChilds: [],
        limit: 20,
        offset: 0,
        limits: [20, 30, 50, 100],
        paginationInf: 0,
        paginationSup: 20,
        loading: true,
        activeSearch: false,
        listName: ''
    }

    componentDidMount() {
        listsController.getLists(this.state.limit).then(res => {
            return this.setState({ lists: res.data.lists, listsTotal: res.data.total_items })
        }).then(r => this.loadingToggleEnd())

    }

    handleInputChange = (e) => {

        const listName = e.target.value
        this.setState({ [e.target.name]: listName })

        if (listName.length === 0) {

            this.setState({ lists: this.state.tmpLists, activeSearch: false })

        } else {

            const candidates = this.state.tmpLists.filter(list => list.name.toLowerCase().includes(listName.toLowerCase()))
            this.setState({ lists: candidates })
        }
    }


    hideDialog = () => {
        this.setState({
            openDialog: false,

        })
    }

    createListAction = () => {
        console.log('create action...')
    }

    showListDetails = (list) => {
        this.props.updateScreen(<ListScreenDetails updateListToCreate={this.props.updateListToCreate} list={list} />)
    }

    onListChange = (e) => {

        try {
            this.setState({ loading: true })
            const limit = this.state.limits[e.target.value]
            const paginationInf = 0
            const paginationSup = limit < this.state.listsTotal ? limit : this.state.listsTotal

            const limitRequest = (limit < this.state.listsTotal ? limit : this.state.listsTotal)
            listsController.getLists(limitRequest, 0).then(res => {

                this.setState({ lists: res.data.lists, listsTotal: res.data.total_items, limit: (limit < this.state.listsTotal ? limit : this.state.listsTotal), paginationSup, paginationInf, loading: false })

            })

        } catch (e) {

        }
    }

    rightPaginationUpdate = () => {


        const limit = this.state.limit
        const offset = this.state.offset + limit
        const diff = this.state.listsTotal - offset

        if (diff > 0) {

            this.setState({ loading: true })

            const paginationInf = offset
            const paginationSup = paginationInf + diff


            listsController.getLists(this.state.limit, paginationInf).then(res => {

                this.setState({ lists: res.data.lists, listsTotal: res.data.total_items, offset, paginationInf, paginationSup, loading: false })
            })

        }
    }

    leftPaginationUpdate = () => {


        const limit = this.state.limit
        const offset = this.state.offset - limit

        const paginationSup = this.state.paginationInf
        const paginationInf = this.state.paginationInf - limit

        if (paginationInf >= 0) {

            this.loadingToggleStart()

            listsController.getLists(limit, paginationInf).then(res => {

                this.setState({ lists: res.data.lists, listsTotal: res.data.total_items, offset, paginationInf, paginationSup })
                this.loadingToggleEnd()
            })



        }



    }

    loadingToggleStart = () => {
        this.setState({ loading: true })
    }

    loadingToggleEnd = () => {
        this.setState({ loading: false })
    }

    render() {

        const lists = this.state.lists.map(list => {

            list = {
                ...list,
                uuid: list.id,
                opens: Number(list['stats']['open_rate']).toFixed(2),
                clicks: Number(list['stats']['click_rate']).toFixed(2),
                members: list['stats']['member_count'],
            }

            return list;
        })



        return (
            <div>
                {!this.state.loading ? (<div>
                    <div className="sizedbox-height-2"></div>
                    <div className="header-container ">
                        <h1>LISTS</h1>
                    </div>
                    <div className="sizedbox-height-2"></div>
                    <div className="uk-flex uk-flex-between uk-flex-wrap uk-flex-wrap-middle uk-padding">
                        <div className="uk-flex uk-flex-around">
                            <span className="primary-theme-color-foreground" uk-icon={"icon: ban ; ratio: 2"}></span>
                            <div className="spacer-1"></div>
                            <form>
                                <div class="uk-margin">
                                    <div uk-form-custom="target: > * > span:first-child">
                                        <select onChange={this.onListChange}>
                                            <option value="">limit</option>
                                            {this.state.limits.map((limit, index) => <option value={index}>{limit}</option>)}

                                        </select>
                                        <button class="uk-button uk-button-default" type="button" >
                                            <span></span>
                                            <span className="primary-theme-color-foreground" uk-icon="icon: chevron-down"></span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="uk-flex uk-flex-around">
                            <div class="uk-margin">
                                <div class="uk-inline">
                                    <span class="uk-form-icon" uk-icon="icon: file-text"></span>
                                    <input onChange={this.handleInputChange} name="listName" placeholder="list name" class="uk-input uk-margin-remove" type="text" value={this.state.listName}></input>
                                </div>
                            </div>
                        </div>

                        <div >
                            <button onClick={this.leftPaginationUpdate} type="submit" className="primary-theme-color-foreground" uk-icon="icon: chevron-left"></button>
                            <span>{this.state.paginationInf} - {this.state.paginationSup} of {this.state.listsTotal}</span>
                            <button onClick={this.rightPaginationUpdate} type="submit" className="primary-theme-color-foreground" uk-icon="icon: chevron-right"></button>
                        </div>

                    </div>
                    <div className="items-container uk-overflow-auto">
                        <div className="uk-overflow-auto uk-padding-small">
                            <table class="uk-table uk-table-small uk-table-divider">
                                <thead>
                                    <tr>
                                        <th>NAME</th>
                                        <th>STATS</th>
                                        <th>ACTIONS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {lists.map(list => {
                                        return <ListItem key={list.uuid} listFocusScreen={this.showListDetails} list={list} />
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="inline-content-center-no-space">
                        <button onClick={this.leftPaginationUpdate} type="submit" className="primary-theme-color-foreground" uk-icon="icon: chevron-left"></button>
                        <span>{this.state.paginationInf} - {this.state.paginationSup} of {this.state.listsTotal}</span>
                        <button onClick={this.rightPaginationUpdate} type="submit" className="primary-theme-color-foreground" uk-icon="icon: chevron-right"></button>
                    </div>
                    <div className="sizedbox-height-5"></div>
                </div>) : (<div className="full-screen"><span class="uk-margin-small-right" uk-spinner="ratio: 3"></span></div>)}
            </div>
        )
    }
}

export default ListsScreen
