import React, { Component } from 'react'
import PropositionContent from './propositions/PropositionContent';

export class ConsularAdvisorPropositions extends Component {


    state = {
        currentIndex:this.props.currentPropositions.length,
        currentCapacity:0,
        propositionsCapacity:5,
        propositions: [],
        propositionsObjects: []
    }

    componentWillMount() {

        const propositions = this.props.currentPropositions

        this.setState({ propositions })
    }

    addProposition = e => {

        if(this.state.currentCapacity<this.state.propositionsCapacity){

            let currentCapacity=this.state.currentCapacity
            let currentIndex = this.state.currentIndex
            const key = '_' + Math.random().toString(36).substr(2, 9)
            let propositionObject = {
                id: key,
                position:currentIndex,
                value: ''
            }
            currentCapacity+=1
            const propositions = this.state.propositions

            propositions.push(propositionObject)

            currentIndex+=1
            this.setState({ propositions,currentCapacity,currentIndex })

        }

    }

    updateProposition = (propositionObject) => {



        const propositionsObjectsTmp = this.state.propositions
        let propositionsObjects = this.state.propositions

        propositionsObjectsTmp.forEach((proposition, index) => {

            if (proposition.id === propositionObject.id) {
                propositionsObjects[index] = propositionObject
            }
        })

        this.setState({ propositions: propositionsObjects })
        this.props.updatePropositions(propositionsObjects)

    }

    removeProposition = object => {

        const propositions = this.state.propositions.filter(proposition => proposition.id.toString() !== object.id.toString()).sort((a,b)=>a.position<b.position?-1:1)
        const currentCapacity = this.state.currentCapacity-1
        this.setState({ propositions,currentCapacity})
        this.props.updatePropositions(propositions)
    }

    render() {

        const propositions = this.state.propositions.map((propositionObject,index) => <PropositionContent key={propositionObject.id} index={index} object={propositionObject} updateProposition={this.updateProposition} removeProposition={this.removeProposition} />)

        return (


            <div className='flex-column'>

                <div className="flex-right">
                    <button onClick={this.addProposition} type="submit" className="uk-button primary-theme-color text-white">AJOUTER</button>
                </div>

                <div className="sizedbox-height-5"></div>

                {propositions.map((proposition) => proposition)}

            </div>
        )
    }
}

export default ConsularAdvisorPropositions
