import React, {Component} from 'react'
import consularController from './../../../controller/consularController'
import ConsularAdvisorPersonalInfos from './ConsularAdvisorPersonalInfos'
import ConsularAdvisorIntentions from './ConsularAdvisorIntentions'
import ConsularAdvisorPropositions from './ConsularAdvisorPropositions'
import SocialNetworksComponentScreen from './SocialNetworksComponentScreen'
import AddSympathizerForm from "./sympathizers/AddSympathizerForm"
import ConsularAdvisorEvents from "./ConsularAdvisorEvents"
import notification from '../../../services/notification'

export class AddConsularAdvisorForm extends Component {

    state = {

        mainContainer: null,
        sympathizerContainer: null,
        homeCandidate: false,
        firstName: '',
        lastName: '',
        files: this.props.files,
        folders: this.props.folders,
        currentFile: null,
        currentFolder: null,
        image: '',
        biography: '',
        districtUuid: '',
        intentions: [],
        propositions: [],
        events: [],
        city: null,
        cities: [],
        socialNetworks: [],
        assistants: this.props.assistants,
        districts: this.props.districts,
        assistantsUuids: [],
        currentAssistants: [],
        socialNetworksTags: this.props.socialNetworksTags,
        assistantsTags: [],
        personalInfos: null,
        intentionsScreen: null,
        propositionsScreen: null,
        socialNetworksScreen: null,
        eventsScreen: null,
        selectedDistrictIndex: -1,
        selectedCityIndex: -1,
        currentPropositions: [],
        notification: false,
        message: '',
        success: false,
        link: '',
        processing: false
    }


    componentDidMount() {


        const sympathizerContainer = <AddSympathizerForm folders={this.state.folders}
                                                         files={this.state.files}
                                                         image={this.state.currentFile}
                                                         folder={this.state.currentFolder}
                                                         onFileChange={this.onFileChange}
                                                         onFolderChange={this.onFolderChange}
                                                         districts={this.state.districts}
                                                         handleFieldChange={this.handleFieldChange}
                                                         onCityChange={this.onCityChange}
                                                         onDistrictChange={this.onDistrictChange}/>
        const personalInfos = <ConsularAdvisorPersonalInfos

            firstName={this.state.firstName}
            lastName={this.state.lastName}
            folders={this.state.folders}
            folder={this.state.currentFolder}
            onFolderChange={this.onFolderChange}
            files={this.state.files}
            image={this.state.currentFile}
            onFileChange={this.onFileChange}
            biography={this.state.biography}

            assistants={this.state.assistants}
            onAssistantChange={this.onAssistantChange}

            currentAssistants={this.state.currentAssistants}
            removeAssistantAction={this.removeAssistantAction}

            districts={this.state.districts}
            onDistrictChange={this.onDistrictChange}

            cities={this.state.cities}

            city={this.state.city}
            onCityChange={this.onCityChange}

            handleFieldChange={this.handleFieldChange}

            selectedDistrictIndex={this.state.selectedDistrictIndex}
            selectedCityIndex={this.state.selectedCityIndex}


        />

        const intentionsScreen = <ConsularAdvisorIntentions intentions={this.state.intentions}
                                                            addIntention={this.addIntention}
                                                            updateIntentions={this.updateIntentions}/>

        const propositionsScreen = <ConsularAdvisorPropositions currentPropositions={this.state.currentPropositions}
                                                                updatePropositions={this.updatePropositions}
                                                                addProposition={this.addProposition}/>

        const socialNetworksScreen = <SocialNetworksComponentScreen socialNetworks={this.state.socialNetworks}
                                                                    socialNetworksTags={this.state.socialNetworksTags}
                                                                    addSocialNetwork={this.addSocialNetwork}
                                                                    updateSocialNetworks={this.updateSocialNetworks}/>
        const eventsScreen = <ConsularAdvisorEvents events={this.state.events} addEvent={this.addEvent}
                                                    updateEvents={this.updateEvents}/>
        const mainContainer = personalInfos


        this.setState({
            sympathizerContainer,
            personalInfos,
            mainContainer,
            propositionsScreen,
            eventsScreen,
            socialNetworksScreen,
            intentionsScreen
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        this.state.sympathizerContainer = <AddSympathizerForm
            folders={this.props.folders}
            folder={this.state.currentFolder}
            onFolderChange={this.onFolderChange}
            files={this.state.files}
            image={this.state.currentFile}
            onFileChange={this.onFileChange}
            handleFieldChange={this.handleFieldChange}
            districts={this.state.districts}
            onCityChange={this.onCityChange}
            onDistrictChange={this.onDistrictChange}/>

        this.state.personalInfos = <ConsularAdvisorPersonalInfos


            firstName={this.state.firstName}
            lastName={this.state.lastName}
            folders={this.state.folders}
            folder={this.state.currentFolder}
            onFolderChange={this.onFolderChange}
            files={this.state.files}
            image={this.state.currentFile}
            onFileChange={this.onFileChange}
            biography={this.state.biography}

            assistants={this.state.assistants}
            onAssistantChange={this.onAssistantChange}

            currentAssistants={this.state.currentAssistants}
            removeAssistantAction={this.removeAssistantAction}

            districts={this.state.districts}
            onDistrictChange={this.onDistrictChange}

            cities={this.state.cities}

            city={this.state.city}
            onCityChange={this.onCityChange}

            handleFieldChange={this.handleFieldChange}

            selectedDistrictIndex={this.state.selectedDistrictIndex}
            selectedCityIndex={this.state.selectedCityIndex}

        />

        this.state.propositionsScreen =
            <ConsularAdvisorPropositions currentPropositions={this.state.currentPropositions}
                                         updatePropositions={this.updatePropositions}
                                         addProposition={this.addProposition}/>


        this.state.intentionsScreen =
            <ConsularAdvisorIntentions intentions={this.state.intentions} addIntention={this.addIntention}
                                       updateIntentions={this.updateIntentions}/>

        this.state.eventsScreen = <ConsularAdvisorEvents events={this.state.events} addEvent={this.addEvent}
                                                         updateEvents={this.updateEvents}/>
    }

    onFileChange = file => {
        this.setState({currentFile: file})
    }

    onFolderChange = (folder, images) => {
        this.setState({currentFolder: folder, files: images })
    }

    onHomeCandidateChange = e => {

        this.setState({homeCandidate: e.target.checked})
    }

    handleFieldChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    // Personal Info
    onDistrictChange = e => {

        const district = this.state.districts[e.target.value]
        const cities = district.cities
        const city = {}
        const selectedDistrictIndex = e.target.value
        const selectedCityIndex = -1

        this.setState({cities, city, selectedDistrictIndex, selectedCityIndex})
    }

    onCityChange = e => {

        const city = this.state.cities[e.target.value]
        const selectedCityIndex = e.target.value

        this.setState({city, selectedCityIndex})

    }

    onAssistantChange = candidate => {

        const currentAssistants = this.state.currentAssistants

        currentAssistants.push(candidate)

        this.setState({currentAssistants})

    }

    removeAssistantAction = candidate => {

        const currentAssistants = this.state.currentAssistants.filter(assistant => candidate.uuid !== assistant.uuid)

        this.setState({currentAssistants})
    }
    validateAssistantsOrder = _ =>{

        const sorted = this.state.currentAssistants.sort((a,b)=>a.position-b.position)

        let valid = true
        if(sorted[0].position !== 1) return false
        for(let i=0;i<sorted.length-1;i++){

            if(sorted[i].position+1 !== sorted[i+1].position){
                valid = false
                break
            }
        }
        return valid
    }

    // Intentions

    addIntention = intention => {
        const intentions = this.state.intentions
        intentions.push(intention)
        this.setState({intentions})
    }

    updateIntentions = intentions => {
        this.setState({intentions})
    }

    // Propositions

    addProposition = proposition => {

        const propositions = this.state.propositions
        propositions.push(proposition)
        this.setState({propositions, currentPropositions: propositions})
    }

    updatePropositions = propositions => {
        this.setState({currentPropositions: propositions})
    }

    // Events
    addEvent = event => {
        const events = this.state.events
        events.push(event)
        this.setState({events})
    }

    updateEvents = events => {
        this.setState({events})
    }

    // Social Networks

    addSocialNetwork = socialNetwork => {

        const socialNetworks = this.state.socialNetworks

        socialNetworks.push(socialNetwork)
        this.setState({socialNetwork})
    }

    updateSocialNetworks = socialNetworks => {
        this.setState({socialNetworks})
    }

    handleTab = e => {

        const id = e.target.id
        let mainContainer = this.state.mainContainer

        switch (id.toLowerCase()) {

            case 'infos':

                mainContainer = this.state.personalInfos

                break
            case 'intentions':

                mainContainer = this.state.intentionsScreen
                break
            case 'propositions':

                mainContainer = this.state.propositionsScreen
                break
            case 'events':

                mainContainer = this.state.eventsScreen
                break
            case 'socials-networks':

                mainContainer = this.state.socialNetworksScreen
                break
            default:
                break;
        }

        this.setState({mainContainer})
    }

    cancelAction = e => {
        this.props.hideDialog()
    }

    createConsulAction = e => {

        if (!this.state.homeCandidate) {
            this.createSympathizer(e)
        } else {
            this.createConsularAdvisor(e)
        }
    }

    createConsularAdvisor = e => {

        const selectedCityIndex = this.state.selectedCityIndex
        const selectedDistrictIndex = this.state.selectedDistrictIndex

        const firstName = this.state.firstName
        const lastName = this.state.lastName
        const currentFile = this.state.currentFile
        const image = currentFile !== null ? {id: currentFile.id, url: currentFile.full_size_url} : null
        const biography = this.state.biography

        const districtUuid = selectedDistrictIndex !== -1 ? this.state.districts[selectedDistrictIndex].uuid : ''
        const cityUuid = selectedCityIndex !== -1 ? this.state.cities[selectedCityIndex].uuid : ''
        const intentions = this.state.intentions.map(intention => intention.value)
        const propositions = this.state.currentPropositions.map((proposition,index) => {

            return {
                ...proposition,
                position: index
            }

        })
        let events = this.state.events
        const assistantsUuids = this.state.currentAssistants.map(assistant => assistant.uuid)
        const socialNetworks = this.state.socialNetworks.every(socialNetwork => socialNetwork.socialTagIndex !== -1) ? this.state.socialNetworks.map(socialNetwork => {

            const socialNetworkObj = {
                socialNetworkTagUuid: this.state.socialNetworksTags[socialNetwork.socialTagIndex].uuid,
                link: socialNetwork.link
            }

            return socialNetworkObj
        }) : []

        let error = false

        if (firstName.length <= 0) {
            error = true
            return this.showNotification(false, 'Fill the First name please...')
        }
        if (lastName.length <= 0) {
            error = true
            return this.showNotification(false, 'Fill the Last name please...')
        }

        if (currentFile === null) {
            error = true
            return this.showNotification(false, 'Select an image please...')
        }

        if (biography.trim().length <= 0) {
            error = true
            return this.showNotification(false, 'Fill the Biography please...')
        }

        if (biography.length > 2048) {
            error = true
            return this.showNotification(false, 'Biography cannot have more than 2048 characters...')
        }

        if (districtUuid.length <= 0) {
            error = true
            return this.showNotification(false, 'Select a district...')
        }

        if (assistantsUuids.length <= 0) {
            error = true
            return this.showNotification(false, 'Please select assistants...')
        }

        if(!this.validateAssistantsOrder()){
            error = true
            return this.showNotification(false, 'Verify assistants positions...')
        }

        if (intentions.length <= 0 || intentions.every(intention => intention.trim().length <= 0)) {
            error = true
            return this.showNotification(false, 'Please fill intentions...')
        }

        if (propositions.length <= 0 || propositions.every(proposition => proposition.value.trim().length <= 0)) {
            error = true
            return this.showNotification(false, 'Please fill propositions...')
        }

        if (propositions.some(proposition => proposition.value.trim().length > 1024)) {
            error = true
            return this.showNotification(false, 'Proposition cannot have more than 1024 characters...')
        }

        if (events.length > 0 && events.some(event => event.place === null)) {
            error = true
            return this.showNotification(false, 'Each event should have a place.')
        }


        if (socialNetworks.length <= 0) {
            error = true
            return this.showNotification(false, 'Please fill social networks...')
        }


        if (!error) {

            this.showNotification(true, 'All required fields present...')

            events = events.map(event => {
                return {
                    title: event.title,
                    date: event.date.toString(),
                    moreInfoLink: event.moreInfoLink,
                    address: event.place.description!=null?event.place.description:event.place,
                    latitude: event.place.location.lat,
                    time: event.time,
                    longitude: event.place.location.lng
                }
            })

            const data = {
                firstName,
                lastName,
                image,
                biography,
                districtUuid,
                cityUuid,
                events,
                intentions,
                propositions,
                assistantsUuids,
                socialNetworks
            }


            this.setState({processing: true}, () => {
                consularController.createConsul(data).then(res => {

                    if (res.status === 200) {
                        this.props.updateConsuls().then(() => {
                            this.showNotification(true, `consular ${res.data.firstName + ' ' + res.data.lastName} created...`)
                            this.cancelAction()
                        })
                    } else {
    
                        this.showNotification(false, `something wrong happened...Check if the assistant(s) are not related to other consul or if the district is already taken`)
                    }
                }).catch(e => {
                    this.showNotification(false, 'something wrong happened...')
                    this.setState({processing: false})
                })
            })


        }

    }

    createSympathizer = sympathizer => {

        const firstName = this.state.firstName
        const lastName = this.state.lastName
        const link = this.state.link

        const image = this.state.currentFile

        const selectedCityIndex = this.state.selectedCityIndex
        const selectedDistrictIndex = this.state.selectedDistrictIndex


        const district = selectedDistrictIndex !== -1 ? this.state.districts[selectedDistrictIndex] : null
        const city = selectedCityIndex !== -1 ? district.cities[selectedCityIndex] : null

        const districtUuid = district !== null ? district.uuid : ''
        const cityUuid = city !== null ? city.uuid : ''
        let error = false

        if (firstName.length <= 0) {
            error = true
            return this.showNotification(false, 'Fill the First name please...')
        }
        if (lastName.length <= 0) {
            error = true
            return this.showNotification(false, 'Fill the Last name please...')
        }

        if (image === null) {
            error = true
            return this.showNotification(false, 'Select an image please...')
        }

        if (link.length <= 0) {
            error = true
            return this.showNotification(false, 'Fill the Website link please...')
        }

        if (districtUuid.length <= 0) {
            error = true
            return this.showNotification(false, 'Select a district...')
        }

        if (cityUuid.length <= 0) {
            error = true
            return this.showNotification(false, 'Select a city...')
        }

        if (!error) {

            this.showNotification(true, 'All required fields present...')

            const data = {
                firstName,
                lastName,
                image,
                link,
                districtUuid,
                cityUuid
            }


            this.setState({processing: true}, () => {
                consularController.createSympathizer(data).then(res => {

                    if (res.status === 200) {
    
                        this.showNotification(true, `consular ${res.data.firstName + ' ' + res.data.lastName} created...`)
                        this.props.updateConsuls().then(() => this.cancelAction())
                    } else {
                        this.showNotification(false, `something wrong happened...`)
                    }
                }).catch(e => {
                    this.showNotification(false, 'something wrong happened...')
                    this.setState({processing: false})
                })
            })
        }

    }

    showNotification = (success, message) => {

        notification.display(message, success? 'success':'danger')
        this.setState({processing: false})
    }


    render() {
        return (
            <div className="flex-column">

                {this.state.notification ?
                    <div className="uk-margin inline-content-center">

                        {
                            this.state.success ?
                                <input class="uk-input uk-form-success uk-form-width-expand" type="text"
                                       placeholder="form-success" value={this.state.message}></input>
                                :
                                <input class="uk-input uk-form-danger uk-form-width-expand" type="text"
                                       placeholder="form-danger" value={this.state.message}></input>
                        }

                    </div>

                    : null
                }

                <div className="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                    <label><input onChange={this.onHomeCandidateChange} className="uk-checkbox" type="checkbox"/> ASFE
                        Candidate</label>
                </div>

                {this.state.homeCandidate ? <div className="tab-menu">


                    <ul className="uk-child-width-expand" uk-tab="media: @s">
                        <li className="uk-active"><a onClick={this.handleTab} id="infos" href="#infos">Infos Personnelles</a></li>
                        <li><a onClick={this.handleTab} href="#intentions" id="intentions">Intentions</a></li>
                        <li><a onClick={this.handleTab} href="#propositions" id="propositions">Propositions</a></li>
                        <li><a onClick={this.handleTab} href="#events" id="events">Évènements</a></li>
                        <li><a onClick={this.handleTab} href="#socials-networks" id="socials-networks">Réseaux sociaux</a></li>

                    </ul>

                    {
                        this.state.mainContainer
                    }

                </div> : this.state.sympathizerContainer}

                <div className="modal-footer inline-content-center-no-space ">
                    <button onClick={this.cancelAction} type="button"
                            className="uk-button uk-button-large uk-modal-close" disabled={this.state.processing}>ANNULER
                    </button>
                    <div className="spacer-1"></div>
                    <button onClick={this.createConsulAction} type="submit"
                            className="uk-button primary-theme-color text-white uk-button-large">CRÉER{ this.state.processing ? <div className="uk-margin-small-left" uk-spinner=""/>: null}
                    </button>
                </div>

            </div>
        )
    }
}

export default AddConsularAdvisorForm
