import React, { Component } from 'react'

export class ConsularAdvisorIntentions extends Component {

    state = {
        intentions: [],

    }

    componentWillMount() {

        const intentions = this.props.intentions

        this.setState({ intentions })
    }

    addIntention = e => {

        e.preventDefault()

        const key = '_' + Math.random().toString(36).substr(2, 9)
        let intentionObject = {
            id: key,
            value: ''
        }


        const intentions = this.state.intentions

        intentions.push(intentionObject)

        this.setState({ intentions })

    }

    updateIntention = intentionObject => {

        const intentionsObjectsTmp = this.state.intentions
        let intentionsObjects = this.state.intentions

        intentionsObjectsTmp.forEach((intention, index) => {

            if (intentionObject.id === intention.id) {
                intentionsObjects[index] = intentionObject
            }
        })

        this.setState({ intentions: intentionsObjects })
        this.props.updateIntentions(intentionsObjects)

    }

    removeIntention = intentionObj => {

        const intentions = this.state.intentions.filter(intention => intention.id.toString() !== intentionObj.id.toString())
        this.setState({ intentions })
        this.props.updateIntentions(intentions)
    }
    render() {
        const intentions = this.state.intentions.map(intentionObject => <IntentionContent key={intentionObject.id} object={intentionObject} updateIntention={this.updateIntention} removeIntention={this.removeIntention} />)

        return (


            <div className='flex-column'>

                <div className="flex-right">
                    <button onClick={this.addIntention} type="submit" className="uk-button primary-theme-color text-white">AJOUTER</button>
                </div>

                <div className="sizedbox-height-5"></div>

                {intentions.map((intention) => intention)}

            </div>
        )
    }
}

export default ConsularAdvisorIntentions

class IntentionContent extends Component {


    state = {
        value: this.props.object.value
    }



    handleChange = e => {

        const value = e.target.value


        const object = {
            ...this.props.object,
            value
        }

        this.setState({ value })
        this.props.updateIntention(object)
    }

    removeIntention = e => {

        this.props.removeIntention(this.props.object)
    }




    render() {

        const value = this.state.value
        const size = value.length
        return (


            <div className="flex-row centered">
                <div class="uk-margin">
                    <span class={`uk-label uk-label-${size<=2048 && size>0? 'success' :'danger'}`}>{size<=2048 && size>0 ? 'OK':'Error'}</span><span>({`${value.length} character${value.length>0?'s':''}`})[max: 2048]</span>
                    <textarea onChange={this.handleChange} className="uk-textarea" rows="5" cols="70" placeholder="intention" value={value}></textarea>
                </div>
                <div>
                    <a onClick={this.removeIntention} className="primary-theme-color" href="#" uk-icon="icon: close; ratio: 1.5"></a>
                </div>
            </div>

        )
    }

}
