
class FilterHelper {


    type = ['opens', 'clicks']


    common = {
        tmpLists: [],
        dialogChilds: [],
        ageFilter: ['< 35', '35 < x < 55', '> 55'],
        percentageFilter: ['< 15', '< 30', '< 50', '> 50'],
        countryFilter: ['France', 'Other'],
        members: [],
        tmpMembers: [],
        limits: [20, 30, 50, 100],
        limit: 20,
        totalItems: 0,
        currentCountryFilter: '',
        currentCountrySpecificFilter: '',
        currentAgeFilter: '',
        currentPercentageFilter: '',
        currentUserFilter: '',
        activeCountryFilter: false,
        activeSpecificCountryFilter: false,
        activeAgeFilter: false,
        activePercentageFilter: false,
        activeCityFilter: false,
        activeUserFilter: false,
        offset: 0,
        paginationInf: 0,
        paginationSup: 0,
        loading: true,
        country: '',
        city: '',
        user: '',
        selectAll: false
    }

    filterByCountry(country, list) {

        const mappedList = this.mapMembers(list)
        const filteredList = []


        mappedList.forEach(member => {

            if (member.country.toLowerCase().includes(country.toLowerCase())) {
                filteredList.push(member)
            }
        })

        return filteredList
    }

    filterByCountrySpecific(country, list) {

        const mappedList = this.mapMembers(list)
        const filteredList = []


        switch (country.toLowerCase()) {

            case 'france':
                mappedList.forEach(member => {

                    if (member.country.toLowerCase() === 'france') {
                        filteredList.push(member)
                    }
                })
                break
            default:
                mappedList.forEach(member => {
                    if (member.country.toLowerCase() !== 'france') {
                        filteredList.push(member)
                    }
                })
                break

        }




        return filteredList
    }

    filterByCity(city, list) {

        const mappedList = this.mapMembers(list)
        const filteredList = []


        mappedList.forEach(member => {

            if (member.city.toLowerCase().includes(city.toLowerCase())) {
                filteredList.push(member)
            }
        })

        return filteredList
    }

    filteredListByAge(ageCondition, list) {
        const filteredList = []
        const mappedList = this.mapMembers(list)
        switch (ageCondition) {

            case '< 35':
                mappedList.forEach(member => {

                    if (member.birthDate < 35) {
                        filteredList.push(member)
                    }

                })

                break
            case '35 < x < 55':

                mappedList.forEach(member => {

                    if (member.birthDate >= 35 && member.birthDate < 55) {
                        filteredList.push(member)
                    }

                })
                break
            case '> 55':
                mappedList.forEach(member => {

                    if (member.birthDate >= 55) {
                        filteredList.push(member)
                    }

                })
                break
            default:
                break;
        }


        return filteredList
    }

    filterByClicksOrOpensPercentage(condition, list) {

        const filteredList = []
        const mappedList = this.mapMembers(list)
        switch (condition) {

            case '< 15':
                mappedList.forEach(member => {

                    // || member.clicks < 50
                    if (member.opens < 15) {
                        filteredList.push(member)
                    }

                })

                break
            case '< 30':
                mappedList.forEach(member => {

                    // || member.clicks < 30
                    if (member.opens < 30) {
                        filteredList.push(member)
                    }

                })

                break
            case '< 50':
                mappedList.forEach(member => {

                    // || member.clicks < 50
                    if (member.opens < 50) {
                        filteredList.push(member)
                    }

                })

                break

            case '> 50':
                mappedList.forEach(member => {

                    //|| member.clicks >= 50
                    if (member.opens >= 50) {
                        filteredList.push(member)
                    }

                })
                break
            default:
                break;
        }


        return filteredList

    }

    mapMembers(members) {
        return members.map(member => {



            let birthDate = ''
            let name = ''
            let country = ''
            let city = ''
            const currentYear = new Date().getFullYear()

            try {

                const tmpBirth = member['merge_fields']['BIRTHDATE']

                if (tmpBirth.includes('+')) {

                    const birthArray = member['merge_fields']['BIRTHDATE'].toString().split('+')
                    birthDate = birthArray[birthArray.length - 1]
                    birthDate = currentYear - Number(birthArray[birthArray.length - 1])
                } else {
                    birthDate = currentYear - Number(tmpBirth.substring(0, 4))
                }

            } catch (e) {
                console.log(e)
            }

            try {

                member = {
                    ...member,
                    opens: Number(member['stats']['avg_open_rate'] * 100).toFixed(2),
                    clicks: Number(member['stats']['avg_click_rate'] * 100).toFixed(2),
                }

            } catch (e) {
                console.log("error parsing stats")
            }

            try {

                name = member['merge_fields']['FIRSTNAME'] + ' ' + member['merge_fields']['LASTNAME']
                if (!name.includes('undefined')) {
                    if (name.includes('+')) name = name.split('+').join(' ')
                    member = {
                        ...member,
                        name
                    }
                }


            } catch (e) {
                console.log(e)
            }


            try {

                country = member['merge_fields']['COUNTRY']
                if (country) {

                    if (country.includes('+')) {
                        country = country.split('+').join(' ')
                    }
                    member = {
                        ...member,
                        country
                    }
                }


            } catch (e) {

            }

            try {

                city = member['merge_fields']['CITY']
                if (city) {

                    if (city.includes('+')) {
                        city = city.split('+').join(' ')
                    }
                    member = {
                        ...member,
                        city
                    }
                }


            } catch (e) {

            }

            member = {
                ...member,
                birthDate,

                uuid: member['id'],
            }

            return member
        })
    }

    filterByUser(user, list) {

        const mappedList = this.mapMembers(list)
        const filteredList = []


        mappedList.forEach(member => {

            if (member.name.toLowerCase().includes(user.toLowerCase()) || member.email_address.toLowerCase().includes(user.toLowerCase())) {
                filteredList.push(member)
            }
        })

        return filteredList

    }

    selectAll(source) {

        let checkboxes = document.querySelectorAll('input[type="checkbox"]');
        for (let i = 0; i < checkboxes.length; i++) {
            if (checkboxes[i] !== source)
                checkboxes[i].checked = source.checked;
        }

    }

    gatheredFilters(activeAgeFilter, activeSpecificCountryFilter,
        activeCountryFilter, activeCityFilter,
        activeUserFilter, user,
        country, countrySpecific,
        city, ageCondition, list,
        activePercentageFilter = false,
        percentageCondition = '') {


        let tmpList = this.mapMembers(list)

        if (activeAgeFilter) {
            tmpList = this.filteredListByAge(ageCondition, tmpList)
        }

        if (activeUserFilter) {
            tmpList = this.filterByUser(user, tmpList)
        }

        if (activeSpecificCountryFilter) {
            tmpList = this.filterByCountrySpecific(countrySpecific, tmpList)
        }

        if (activeCountryFilter) {
            tmpList = this.filterByCountry(country, tmpList)
        }

        if (activeCityFilter) {
            tmpList = this.filterByCity(city, tmpList)
        }

        if (activePercentageFilter) {
            tmpList = this.filterByClicksOrOpensPercentage(percentageCondition, tmpList)
        }


        return tmpList

    }

}


export default new FilterHelper()