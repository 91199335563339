import React, { Component } from 'react'
import listsController from '../../controller/listsController'
import consularController from "../../controller/consularController"
import AddTemplateForm from "./AddTemplateForm"
import Dialog from '../dialog';

export class SettingsScreen extends Component {

    state = {
        loading: false,
        canDownload: false,
        title:'',
        html:'',
        templates:[],
        lists: this.props.account.lists,
        openDialog: false,
        listsId: [],
        selectedList: null,
        dialogChilds: [],
        action:null,
        alert: false,
        success: false,
        message: ''
    }


    loadingToggleStart = () => {
        this.setState({ loading: true })
    }

    loadingToggleEnd = () => {
        this.setState({ loading: false })
    }

    onListChange = (e) => {

        const selectedList = this.state.lists[e.target.value]

        this.setState({ selectedList })
    }

    hideDialog = () => {

        this.setState({ openDialog: false })
    }

    showDialogValidation = () => {


        if (this.state.selectedList) {
            const dialogChilds = [
                <div className="inline-content-center">Create fields for <div className="spacer-1"></div><b> {this.state.selectedList.name} </b> ?</div>
            ]
            this.setState({
                title:'CREATE MERGE FIELDS',
                openDialog: true,
                dialogChilds,
                action:this.createListMergeFields
            })
        }
    }

    showDialogTemplate = ()=>{

            const dialogChilds = [
                <AddTemplateForm hideDialog={this.hideDialog} updateHtmlState={this.updateHtmlState} canDownload={this.state.canDownload} downloadAction={this.downloadAction}/>
            ]
            this.setState({
                openDialog: true,
                dialogChilds,
                title:'CREATE MAIL TEMPLATES',
            })
    }

    updateHtmlState=html =>{
        this.setState({html})
    }


    createListMergeFields = (e) => {

        if (this.state.selectedList) {

            this.loadingToggleStart()

            listsController.createOneListMergeFields(this.state.selectedList.id).then(res => {

                console.log(res)
                if (res.status === 200) {


                    const message = 'Fields successfully created...'
                    this.loadingToggleEnd()
                    this.setState({ openDialog: false, success: true, alert: true, message })
                    setTimeout(() => {
                        this.setState({ alert: false, success: false })
                    }, 5000)

                } else {
                    const message = 'Something wrong happened...Make sure all the presents fields in the list was deleted...'
                    this.setState({ openDialog: false, success: false, alert: true, message })
                    setTimeout(() => {
                        this.setState({ alert: false, success: false })
                    }, 5000)
                }
            }).catch(e => {
                this.loadingToggleEnd()
                const message = 'Something wrong happened...Make sure all the presents fields in the list was deleted...'
                this.setState({ openDialog: false, success: false, alert: true, message })
                setTimeout(() => {
                    this.setState({ alert: false, success: false })
                }, 5000)
            })

        }

    }

    render() {
        return (
            <div>
                {
                    !this.state.loading ? (<div>

                        <div className="flex-column">
                            <div className="sized-box-height-2"></div>
                            <h1 class="uk-heading-line uk-text-center"><span>SETTINGS</span></h1>

                        </div>

                        <div className="items-container">

                            {this.state.alert ? <div className={this.state.success ? 'uk-alert-success' : 'uk-alert-danger'}>{this.state.message}</div> : null}

                            <div className="inline-content-center">



                                <div className='inline-content-center'>
                                    <form >
                                        <div class="uk-margin">
                                            <div uk-form-custom="target: > * > span:first-child">

                                                <span><b>LIST :</b> </span>
                                                <select onChange={this.onListChange}>
                                                    <option value="">Please select...</option>
                                                    {this.state.lists.map((list, index) => <option value={index}>{list.name}</option>)}

                                                </select>
                                                <button class="uk-button uk-button-default" type="button" >
                                                    <span></span>
                                                    <span className="primary-theme-color-foreground" uk-icon="icon: chevron-down"></span>
                                                </button>
                                            </div>
                                        </div>

                                    </form>

                                    <div className="spacer-2"></div>

                                    <div>
                                        <button onClick={this.showDialogValidation} type="submit" className="uk-button primary-theme-color text-white">CREATE FIELDS</button>

                                    </div>

                                </div>
                                <div className='inline-content-center'>

                                    <div className="spacer-2"></div>

                                    <div>
                                        <button onClick={this.showDialogTemplate} type="submit" className="uk-button primary-theme-color text-white">CREATE TEMPLATE</button>
                                    </div>

                                </div>

                                <div className="sizedbox-width-5"></div>

                                {
                                    this.state.openDialog ? <Dialog key='dialog' title={this.state.title} multipleSteps={true} stepContainer={this.state.dialogChilds} childs={this.state.dialogChilds} hideDialog={this.hideDialog} mainAction={this.state.action} /> : null
                                }


                            </div>
                        </div>



                    </div>) : (<div className="full-screen"><span class="uk-margin-small-right" uk-spinner="ratio: 3"></span></div>)
                }
            </div>
        )
    }
}

export default SettingsScreen
