import React, { Component } from 'react'

export class ListTagComponent extends Component {


    removeAction = (e) => {

        this.props.removeAction(this.props.list)

    }
    render() {

        const name = this.props.list.name
        return (
            <div className='inline-content-center'>

                <div className="spacer-1"></div>
                <span className='uk-label text-white primary-theme-color'>{name}<a onClick={this.removeAction} className="primary-theme-color" href="#" uk-icon="icon: close;"></a></span>
                <div className="spacer-1"></div>

            </div>
        )
    }
}

export default ListTagComponent
