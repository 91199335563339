
import axios from '../../utils/routes/axios'
import * as routes from '../../utils/routes'

class UserController {


    async validateToken() {
        return await axios.get(routes.ADMIN_VALIDATE_JWT)
    }

    async getAccountInfo() {
        return await axios.get(routes.ACCOUNT_INFOS)
    }

    async signIn(user) {
        return await axios.post(routes.ADMIN_SIGN_IN,
            user);

    };

    async getLists() {

        return await axios.get(routes.GET_LISTS)
    }

}


export default new UserController()

