import React, { Component } from 'react'
import FileHelper from '../../helpers/FileHelper'
import listsController from '../../controller/listsController'
export class ImportFileComponent extends Component {

    state = {
        validCsv: false,
        success: false,
        notification: false,
        file: {},
        lists: [],
        selectedListId: '',
        loading: false,
        uploading: false,
        message: ''
    }

    getFileContent = (e) => {
        let file = document.getElementById("fileForUpload").files[0]
        this.hideNotification()


        this.state.loading = true
        this.showNotification('File ' + file.name + ' send to mailchimp for validation...', true)
        listsController.uploadListFile(file).then(res => {

            this.showNotification('File validation finished...', true)
            return this.setState({ file, validCsv: res.data, loading: false })
        })
    }

    hideNotification = () => {
        this.setState({ notification: false, success: false })
    }

    showNotification = (message, value) => {
        this.setState({ notification: true, success: value, message })
    }


    validateDownload = () => {


        if (!this.state.loading) {

            if (this.state.validCsv && this.state.selectedListId.length > 0) {
                this.setState({ uploading: true })

                this.showNotification('File ' + this.state.file.name + ' send to mailchimp for upload...', true)
                listsController.downloadListFile(this.state.file, this.state.selectedListId).then(res => {

                    // if (res.data.length < 7000) {

                    //     this.download('parsed-' + this.state.file.name, res.data)
                    //     this.props.hideDialog()
                    // } else {
                    //     FileHelper.downloadFile('parsed-' + this.state.file.name, res.data)
                    //     this.props.hideDialog()
                    // }


                    // this.props.hideDialog()
                    // this.setState({ uploading: false })

                })
            } else this.showNotification('Error, enter a valid file or select list...', false)
        } else {
            this.showNotification('File still loading from the server...', false)
        }




    }

    onListChange = (e) => {
        const selectedListId = this.props.lists[e.target.value].id

        this.hideNotification()

        this.setState({ selectedListId })
    }

    download(filename, text) {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    render() {
        return (
            <div>

                {
                    this.state.notification ? (<div className={!this.state.success ? 'uk-alert-danger inline-content-center-no-space' : 'uk-alert-success inline-content-center-no-space'} uk-alert>

                        <p>{this.state.message} <a class="uk-alert-close" uk-icon="icon: close; ratio: 1.5"></a></p>

                    </div>) : (<div></div>)
                }

                <div className="sizedbox-height-5"></div>

                <div className="inline-content-center-no-space">
                    <span className="primary-theme-color-foreground" uk-icon={"icon: file-text ; ratio: 2"}></span>
                    <div className="spacer-1"></div>
                    <form>
                        <div class="uk-margin">
                            <div uk-form-custom="target: > * > span:first-child">
                                <select onChange={this.onListChange}>
                                    <option value="">Please select...</option>
                                    {this.props.lists.map((list, index) => <option value={index}>{list.name}</option>)}

                                </select>
                                <button class="uk-button uk-button-default" type="button" >
                                    <span></span>
                                    <span className="primary-theme-color-foreground" uk-icon="icon: chevron-down"></span>
                                </button>
                            </div>
                        </div>
                    </form>

                    {this.state.loadingLists ? <span class="uk-margin-small-right" uk-spinner="ratio: 1"></span> : null}
                </div>

                <div className='error-file-type'></div>
                <div class="js-upload uk-placeholder uk-text-center">
                    <span uk-icon="icon: cloud-upload"></span>
                    <span class="uk-text-middle">Attach binaries by dropping them here or</span>
                    <div uk-form-custom>
                        <input onChange={this.getFileContent} id="fileForUpload" type="file" multiple />
                    </div>
                </div>

                <div className="sizedbox-height-5"></div>

                <div className="modal-footer inline-content-center-no-space ">
                    <button onClick={this.props.hideDialog} type="submit" className="uk-button rounded-btn uk-button-small">CANCEL</button>
                    <div className="spacer-1"></div>
                    <button onClick={this.validateDownload} type="submit" className="uk-button rounded-btn uk-button-small">UPLOAD</button>
                </div>
            </div>
        )
    }
}

export default ImportFileComponent
