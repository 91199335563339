import React, { Component } from 'react'
import consularController from '../../../controller/consularController'
import notification from '../../../services/notification'

export class AddAssistantForm extends Component {


    state = {
        files:this.props.files,
        folders: this.props.folders,
        firstName: '',
        lastName: '',
        biography: '',
        externalLink: '',
        image: null,
        notification: false,
        message: '',
        success: false,
        position:-1,
        positions:[],
        processing: false,
        processFiles: false,
    }

    componentWillMount() {
        const positions = []

        for(let i=1;i<28;i++) positions.push(i)

        this.setState({positions})
    }

    onFolderChange = e =>{
        const folderIndex = e.target.value;

        if(folderIndex.toString()!=='-1'){
            const folder = this.state.folders[folderIndex]
            this.setState({processFiles: true}, async () => {
                const images = (await consularController.getFilesByFolderId(folder.id, folder.created_at.toString().split('T')[0])).data.files
                this.setState({files: images, processFiles: false, folder})
            })
        }
    }

    onFileChange = e =>{

        const fileIndex = e.target.value
        if(fileIndex.toString()!=='-1'){
            const file = {
                ...this.state.files[fileIndex],
                url:this.state.files[fileIndex].full_size_url
            }
            this.setState({image: file})
        }
    }

    onPositionChange= e =>{

        const index = e.target.value

        if(index.toString() !== '-1'){
            this.setState({position:this.state.positions[index]})
        }

    }


    handleFieldChange = e => {

        this.setState({ [e.target.name]: e.target.value })
    }

    createAssistantAction = e => {

        e.preventDefault()

        const position = this.state.position
        const firstName = this.state.firstName
        const lastName = this.state.lastName
        const biography = this.state.biography
        const externalLink = this.state.externalLink
        let image = this.state.image
        const regExp = RegExp('https://www.facebook.[fr|com]/*')

        if(position<=0 || position >= 28){
            notification.display('Saisis une position valide 🤥', 'danger')
            return
        }

        if(firstName.length <= 0){
            notification.display('Saisis le prénom 🤥', 'danger')
            return
        }

        if(lastName.length <= 0){
            notification.display('Saisis le nom 🤥', 'danger')
            return
        }

        if(externalLink !== null &&externalLink.length>0 && !regExp.test(externalLink.toLowerCase())){
            notification.display('Entre un lien facebook valide ! 🤥', 'danger')
            return
        }

        if(biography.trim().length <= 0){
            notification.display('Saisis une biographie 🤥', 'danger')
            return
        }

        if(biography.length > 1024){
            notification.display('La biographie ne peut pas avoir plus de 1024 caractères 🤥', 'danger')
            return
        }

        if(!image){
            notification.display('Sélectionne une image 🌌', 'danger')
            return
        }
        
        this.setState({processing: true}, () => {
            image = {
                id:image.id,
                url:image.full_size_url
            }
            const data = {
                position,
                firstName,
                lastName,
                biography,
                externalLink,
                image
            }
            consularController.createAssistant(data).then(res => res.data).then((data)=>this.props.getAssistants()).then(()=> {
                notification.display('Co-listier créé 😁')
                this.cancelAction()
            }).catch(err=>notification.display('Une problème est survenu', 'danger'))
        })

    }

    showNotification = (success, message) => {

        this.setState({notification: true, success, message})
        return setTimeout(() => {
            this.setState({notification: false, success: false, message: ''})
        }, 3000)
    }

    cancelAction = e => {
        this.props.hideDialog()
    }
    render() {

        const imagePresent = this.state.image !==null
        const biography = this.state.biography
        const bioSize = biography.length
        return (
            <div className="flex-column">

                <form >
                    <div className="centered ">

                        {this.state.notification ?
                            <div className="uk-margin inline-content-center">

                                {
                                    this.state.success ?
                                        <input class="uk-input uk-form-success uk-form-width-expand" type="text"
                                               placeholder="form-success" value={this.state.message}></input>
                                        :
                                        <input class="uk-input uk-form-danger uk-form-width-expand" type="text"
                                               placeholder="form-danger" value={this.state.message}></input>
                                }

                            </div>

                            : null
                        }

                        <div className="flex-row-center">

                            <div>
                                <img className="circular" src={imagePresent?this.state.image.url:""} width="100" height="100"
                                     alt="assistant-image"/>
                            </div>

                            <div className="sizedbox-width-5"/>

                            <div className="flex-column">

                                <div className="inline-content-center-no-space">
                                    <span className="primary-theme-color-foreground" uk-icon={"icon: hashtag ; ratio: 1.5"}></span>
                                    <div className="spacer-1"></div>
                                    <form>
                                        <div className="uk-margin">
                                            <div uk-form-custom="target: > * > span:first-child">
                                                <select onChange={this.onPositionChange}>
                                                    <option value={-1}>position...</option>
                                                    {this.state.positions.map((position, index) => <option value={index} selected={position===this.state.position}>{position}</option>)}

                                                </select>
                                                <button className="uk-button uk-button-default" type="button">
                                                    <span></span>
                                                    <span className="primary-theme-color-foreground"
                                                          uk-icon="icon: chevron-down"></span>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="uk-inline content-20w">
                                    <span className="uk-form-icon" uk-icon="icon: user" />
                                    <input name="firstName" placeholder="first name" onChange={this.handleFieldChange} className="uk-align-center uk-input bottom-border no-border" type="text" value={this.state.firstName} />
                                </div>



                                <div className="uk-inline content-20w">
                                    <span className="uk-form-icon" uk-icon="icon: user" />
                                    <input name="lastName" placeholder="last name" onChange={this.handleFieldChange} className="uk-align-center uk-input bottom-border no-border" type="text" value={this.state.lastName} />
                                </div>

                                <div className="uk-inline content-20w">
                                    <span className="uk-form-icon" uk-icon="icon: facebook" />
                                    <input name="externalLink" placeholder="facebook" onChange={this.handleFieldChange} className="uk-align-center uk-input bottom-border no-border" type="text" value={this.state.externalLink} />
                                </div>
                            </div>

                        </div>

                        <div className="inline-content-center-no-space">
                            <span className="primary-theme-color-foreground" uk-icon={"icon: folder ; ratio: 2"}></span>
                            <div className="spacer-1"></div>
                            <form>
                                <div className="uk-margin">
                                    <div uk-form-custom="target: > * > span:first-child">
                                        <select onChange={this.onFolderChange}>
                                            <option value={-1}>dossiers...</option>
                                            {this.state.folders.map((folder, index) => <option value={index}>{folder.name}</option>)}
                                        </select>
                                        <button className="uk-button uk-button-default" type="button">
                                            <span></span>
                                            <span className="primary-theme-color-foreground"
                                                uk-icon="icon: chevron-down"></span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="inline-content-center-no-space">
                            <span className="primary-theme-color-foreground" uk-icon={"icon: image ; ratio: 1.5"}></span>
                            <div className="spacer-1"></div>
                            <form>
                                <div className="uk-margin">
                                    <div uk-form-custom="target: > * > span:first-child">
                                        <select onChange={this.onFileChange} disabled={this.state.processFiles}>
                                            <option value={-1}>images...</option>
                                            {this.state.files.map((file, index) => <option value={index}>{file.name}</option>)}

                                        </select>
                                        <button className="uk-button uk-button-default" type="button">
                                            <span></span>
                                            <span className="primary-theme-color-foreground"
                                                  uk-icon="icon: chevron-down"></span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                            { this.state.processFiles ? <div className="uk-margin-small-left" uk-spinner=""/>: null }
                        </div>
                        <div className="uk-inline content-20w">
                            <span class={`uk-label uk-label-${bioSize<=1024 && bioSize>0? 'success' :'danger'}`}>{bioSize<=1024 && bioSize>0 ? 'OK':'Error'}</span><span>({`${bioSize} character${bioSize>0?'s':''}`})[max: 1024]</span>
                            <textarea className="uk-textarea" rows="5" cols="70" name="biography" placeholder="biography" onChange={this.handleFieldChange}  type="text" value={biography} ></textarea>
                        </div>
                        <div className="sizedbox-height-5"></div>

                        <div className="modal-footer inline-content-center-no-space ">
                            <button onClick={this.cancelAction} type="submit" className="uk-button uk-button-large" disabled={this.state.processing}>Annuler</button>
                            <div className="spacer-1"></div>
                            <button onClick={this.createAssistantAction} type="submit" className="uk-button primary-theme-color text-white uk-button-large">Créer { this.state.processing ? <div className="uk-margin-small-left" uk-spinner=""/>: null}</button>
                        </div>

                    </div>

                </form>
            </div>
        )
    }
}

export default AddAssistantForm
