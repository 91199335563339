

import axios from '../../utils/routes/axios';
import * as routes from '../../utils/routes';

class CampaignsController {


    async getReports(count = 10, offset = 0) {

        return await axios.get(`${routes.GET_CAMPAIGNS_REPORTS}?count=${count}&offset=${offset}`)
    }

    async getReportsEmailsActivity(campaignId, count = 10, offset = 0) {

        return await axios.get(`${routes.GET_CAMPAIGNS_REPORTS}/${campaignId}/${routes.GET_CAMPAIGNS_REPORTS_EMAILS_ACTIVITY}?count=${count}&offset=${offset}`)
    }

    async getReportsClicksDetails(campaignId, count = 10, offset = 0) {

        return await axios.get(`${routes.GET_CAMPAIGNS_REPORTS}/${campaignId}/${routes.GET_CAMPAIGNS_REPORTS_CLICKS_DETAILS}?count=${count}&offset=${offset}`)
    }

    async getOneReportClicksDetails(campaignId, linkId, count = 10, offset = 0) {

        return await axios.get(`${routes.GET_CAMPAIGNS_REPORTS}/${campaignId}/${routes.GET_CAMPAIGNS_REPORTS_CLICKS_DETAILS}/${linkId}/members?count=${count}&offset=${offset}`)
    }

    async getReportsOpensDetails(campaignId, count = 10, offset = 0) {

        return await axios.get(`${routes.GET_CAMPAIGNS_REPORTS}/${campaignId}/${routes.GET_CAMPAIGNS_REPORTS_OPENS_DETAILS}?count=${count}&offset=${offset}`)
    }



}


export default new CampaignsController()

