import React, { Component } from 'react'

export class ListMembersCheckBoxContainer extends Component {

    componentDidUpdate() {
        let checkBox = document.getElementById(this.props.member.uuid)
        let checked = this.props.selectAll

        checkBox.checked = checked
    }

    handleCheck = (event) => {

        let checkBox = document.getElementById(this.props.member.uuid)

        // if (checkBox.checked) {
        //     this.props.addMemberToTmpList(this.props.member)
        // } else {
        //     this.props.removeMemberToTmpList(this.props.member)
        // }


    }

    render() {

        const member = this.props.member

        return (



            <React.Fragment >


                <tr>
                    <td>
                        <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                            <input id={member.uuid} onChange={this.handleCheck} className="uk-checkbox primary-theme-color-foreground" type="checkbox" />
                        </div>
                    </td>
                    <td><div class="flex-column">
                        <dt><a>{member.name}</a></dt>
                        <dd>{member.email_address}</dd>
                        {member.country !== '' ? <dd><span uk-icon="icon: world;"></span>{member.country}</dd> : null}
                        {member.city !== '' ? <dd><span uk-icon="icon: location;"></span>{member.city}</dd> : null}
                    </div>
                    </td>
                    <td>
                        <dd>
                            Age: <b>{member.birthDate}</b>
                        </dd>

                        <dd>
                            Opens: {member.opens + "%"}
                        </dd>

                        <dd>Clicks: {member.clicks + "%"}</dd>
                    </td>
                </tr>


            </React.Fragment>

        )
    }
}

export default ListMembersCheckBoxContainer
