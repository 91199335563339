
import axios from '../../utils/routes/axios';
import * as routes from '../../utils/routes';

class ListsController {


    async getLists(count = 10, offset = 0) {

        return await axios.get(routes.GET_LISTS + `?count=${count}&offset=${offset}`)
    }

    async getMembersInList(listId, count = 10, offset = 0) {

        return await axios.get(routes.GET_LISTS + `/${listId}/members?count=${count}&offset=${offset}`)
    }

    async deleteMembersInList(listId, memberId) {

        return await axios.delete(routes.GET_LISTS + `/${listId}/members/${memberId}`)
    }

    async getListMergeFields(listId) {

        return await axios.get(routes.GET_LISTS + `/${listId}/merge-fields`)
    }

    async addMembersInList(listId, members) {

        const url = routes.GET_LISTS + `/${listId}/members`

        return await axios.post(url, members, { headers: { "content-type": "application/json" } })
    }


    async createOneListMergeFields(listId) {

        const url = routes.GET_LISTS + `/${listId}/merge-fields/skeleton`

        return await axios.post(url, { headers: { "content-type": "application/json" } })
    }

    async uploadListFile(file) {
        let data = new FormData()

        data.append('file', file)

        return await axios.post(routes.POST_LISTS_CSV_VALIDATE, data)
    }

    async downloadListFile(file, listId) {
        let data = new FormData()

        data.append('file', file)
        data.append('listId', listId)

        return await axios.post(routes.POST_LISTS_CSV_DOWNLOAD, data)
    }

}


export default new ListsController()

