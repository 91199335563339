import React, { Component } from 'react'

export class SocialNetworksComponentScreen extends Component {

    state = {
        socialNetworks: [],
        socialNetworksTags: []
    }

    componentWillMount() {

        const socialNetworks = this.props.socialNetworks
        this.setState({ socialNetworks })
    }


    addSocialNetwork = e => {

        const key = '_' + Math.random().toString(36).substr(2, 9)
        let socialNetwork = {
            id: key,
            link: '',
            socialTagIndex: -1
        }

        const socialNetworks = this.state.socialNetworks

        socialNetworks.push(socialNetwork)

        this.setState({ socialNetworks })

    }

    removeSocialNetwork = socialNetwork => {

        const socialNetworks = this.state.socialNetworks.filter(socialNetworkObj => socialNetworkObj.id.toString() !== socialNetwork.id.toString())
        this.setState({ socialNetworks })
        this.props.updateSocialNetworks(socialNetworks)
    }

    updateSocialNetwork = socialNetwork => {


        let socialNetworkObjects = this.state.socialNetworks

        socialNetworkObjects.forEach((socialNetworkObj, index) => {

            if (socialNetwork.id === socialNetworkObj.id) {
                socialNetworkObjects[index] = socialNetwork
            }
        })

        this.setState({ socialNetworks: socialNetworkObjects })
        this.props.updateSocialNetworks(socialNetworkObjects)

    }
    render() {
        const socialNetworks = this.state.socialNetworks.map(socialNetwork => <SocialNetworkContent socialNetworksTags={this.props.socialNetworksTags} object={socialNetwork} removeSocialNetwork={this.removeSocialNetwork} updateSocialNetwork={this.updateSocialNetwork} />)
        return (
            <div className='flex-column'>

                <div className="flex-right">
                    <button onClick={this.addSocialNetwork} type="submit" className="uk-button primary-theme-color text-white">AJOUTER</button>
                </div>

                <div className="sizedbox-height-5"></div>

                {socialNetworks.map((socialNetwork) => socialNetwork)}

            </div>
        )
    }
}

export default SocialNetworksComponentScreen

class SocialNetworkContent extends Component {

    state = {
        socialNetworksTags: [],
        link: '',
        icon: 'rss',
        socialNetworkTagName: 'Social Network...',
        socialNetworkTag: null,
        finalObj: null

    }

    componentDidMount() {

        const object = this.props.object
        const socialNetworksTags = this.props.socialNetworksTags
        const link = object.link
        let icon = this.state.icon
        let socialNetworkTagName = this.state.socialNetworkTagName
        let socialNetworkTag = this.state.socialNetworkTag
        if (object.socialTagIndex !== -1) {

            socialNetworkTag = socialNetworksTags[object.socialTagIndex]

            icon = socialNetworkTag.name.toLowerCase()
            socialNetworkTagName = icon
        }

        this.setState({ socialNetworksTags, icon, link, socialNetworkTagName, socialNetworkTag })
    }



    handleFieldChange = e => {

        const link = e.target.value


        const finalObj = {
            ...this.props.object,
            link
        }

        this.setState({ link, finalObj })
        this.props.updateSocialNetwork(finalObj)

    }

    onSocialNetworkTagChange = e => {

        const socialNetworkTag = this.state.socialNetworksTags[e.target.value]
        const icon = socialNetworkTag.name.toLowerCase()
        const link = this.state.link
        const finalObj = {
            ...this.props.object,
            link,
            socialTagIndex: e.target.value
        }

        this.setState({ icon, socialNetworkTag, finalObj })
        this.props.updateSocialNetwork(finalObj)

    }



    removeSocialNetwork = e => {

        this.props.removeSocialNetwork(this.props.object)
    }




    render() {

        const socialNetworksTags = this.state.socialNetworksTags

        return (


            <div className="flex-row">
                <div>
                    <div className="uk-inline content-20w">
                        <span className="uk-form-icon" uk-icon="icon: link" />
                        <input name="link" placeholder="link" onChange={this.handleFieldChange} className="uk-align-center uk-input bottom-border no-border" type="text" value={this.state.link} />
                    </div>
                </div>
                <div className="inline-content-center-no-space">
                    <span className="primary-theme-color-foreground" uk-icon={`icon: ${this.state.icon} ; ratio: 1.5`}></span>
                    <div className="spacer-1"></div>
                    <form>
                        <div class="uk-margin">
                            <div uk-form-custom="target: > * > span:first-child">
                                <select onChange={this.onSocialNetworkTagChange} >
                                    {this.state.socialNetworkTag !== null ? <option value={this.props.object.socialTagIndex} selected>{this.state.socialNetworkTag.name}</option> : <option value="">Social Networks...</option>}

                                    {socialNetworksTags.map((socialNetworkTag, index) => {
                                        if (index.toString() !== this.props.object.socialTagIndex.toString()) {
                                            return <option value={index}>{socialNetworkTag.name}</option>
                                        }


                                    })}

                                </select>
                                <button class="uk-button uk-button-default" type="button" >
                                    <span></span>
                                    <span className="primary-theme-color-foreground" uk-icon="icon: chevron-down"></span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>

                <div>
                    <a onClick={this.removeSocialNetwork} className="primary-theme-color" href="#" uk-icon="icon: close; ratio: 1.5"></a>
                </div>
            </div>

        )
    }
}
