import React, {Component} from 'react';
import consularController from "../../controller/consularController";
import FileHelper from "../../helpers/FileHelper";

class AddTemplateForm extends Component {

    state = {
        template:'',
        templates:[],
        canDownload:false,
        loading:false
    }

    handleChange = e=>{
        this.setState({template:e.target.value})
    }

    cancelAction = e=>{
        e.preventDefault()
        this.props.hideDialog()
    }

    createTemplates = _ =>{


        if(this.state.template.length>0){
            this.setState({canDownload:false,loading:true})
            consularController
                .getTemplates({template:this.state.template.replace(/[\n\r]/g, '')})
                .then(res=> this.setState({canDownload:true, templates:res.data.templates,loading:false}))
                .catch(e=>console.log(e))
        }
    }

    downloadAction = e =>{
        FileHelper.downloadAsZip(this.state.templates,"templates.zip")

    }

    render() {

        return (
            <div className="uk-flex uk-flex-column">
                <div className="uk-flex uk-flex-inline uk-flex-right">
                    {
                        this.state.canDownload?<div>
                            <a onClick={this.downloadAction} className="uk-icon-button primary-theme-color" uk-icon="cloud-download"/>
                        </div>: (this.state.loading?<div uk-spinner=""/>:null)
                    }
                </div>
                <div className="uk-margin uk-width-expand">
                    <textarea onChange={this.handleChange} className="uk-textarea" rows="5" placeholder="Textarea" value={this.state.template}></textarea>
                </div>

                <div className="modal-footer inline-content-center-no-space ">
                    <button onClick={this.cancelAction} type="submit" className="uk-button rounded-btn uk-button-small">CANCEL</button>
                    <div className="spacer-1"></div>
                    <button onClick={this.createTemplates} type="submit" className="uk-button rounded-btn uk-button-small">CREATE</button>
                </div>

            </div>
        );
    }
}

export default AddTemplateForm;