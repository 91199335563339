import React, { Component } from 'react'
import campaignController from '../../controller/campaignController'
import memberController from '../../controller/memberController'
import MemberReportItem from './MemberReportItem';
import { ListMemberDialogDisplayItem } from '../lists/ListMemberDialogDisplayItem';
import { Dialog } from '../dialog/Dialog'
import filterHelper from '../../helpers/FilterHelper'

export class CampaignScreenDetails extends Component {


    state = {
        type: filterHelper.type,
        campaigns: [],
        emails: [],
        links: [],
        currentLink: {},
        linksTotal: 0,
        campaignsTotal: 0,
        openDialog: false,
        filterByLink: false,
        ...filterHelper.common
    }

    componentDidMount() {


        campaignController.getReportsEmailsActivity(this.props.campaign.id).then(res => {

            const emails = res.data.emails
            return this.setState({ emails })


        }).then(r => {
            return campaignController.getReportsClicksDetails(this.props.campaign.id).then(response => {


                return response.data.total_items


            }).then(total => {

                return campaignController.getReportsClicksDetails(this.props.campaign.id, total).then(response => {

                    const links = response.data.urls_clicked
                    const linksTotal = total

                    return this.setState({ links, linksTotal })
                })
            }).then(r => {

                return campaignController.getReportsOpensDetails(this.props.campaign.id, this.state.limit).then(response => {

                    const members = response.data.members
                    const tmpMembers = members

                    this.setState({ members, tmpMembers, totalItems: response.data.total_items })
                    this.loadingToggleEnd()
                })

            })
        }).catch(e => console.log(e))

    }

    handleInputChange = (e) => {

        const name = e.target.name
        const value = e.target.value



        if (value === '') {
            switch (name) {
                case 'city':
                    this.state.activeCityFilter = false

                    break
                case 'country':
                    this.state.activeCountryFilter = false

                    break
                case 'user':
                    this.state.activeUserFilter = false
                    break
                default:
                    break;
            }
            this.setState({ members: this.gatheredFilter() })
        }
        this.setState({ [name]: value })


    }

    onTypeChange = (e) => {

        try {

            const action = this.state.type[e.target.value]
            const paginationInf = 0
            const paginationSup = 0
            const offset = 0
            this.setState({ paginationInf, paginationSup, offset })
            console.log(action)
            if (action) {
                switch (action) {
                    case 'opens':
                        this.loadingToggleStart()
                        this.getOpensReportDetails()

                        break
                    case 'clicks':

                        this.setState({ filterByLink: true, members: [], tmpMembers: [], totalItems: 0, paginationInf, paginationSup })
                        break
                    default:
                        break
                }
            } else {
                this.getOpensReportDetails()
            }

        } catch (e) {

        }

    }

    onLinkChange = (e) => {

        try {

            const link = this.state.links[e.target.value]

            if (link) {
                this.loadingToggleStart()
                campaignController.getOneReportClicksDetails(this.props.campaign.id, link.id, this.state.limit).then(res => {

                    this.findMappedMembersBySearch(res.data.members).then(results => {


                        this.state.tmpMembers = results
                        const members = this.gatheredFilter()
                        const limit = this.state.limit
                        const totalItems = res.data.total_items
                        const paginationSup = limit < totalItems ? limit : totalItems

                        this.setState({ members, totalItems, paginationSup, currentLink: link, offset: 0 })
                        this.loadingToggleEnd()

                    })

                })
            } else {
                this.setState({ members: [] })
            }



        } catch (e) {



        }

    }

    addMemberToTmpList = (member) => {

        if (!this.state.tmpLists.has(member)) {
            this.state.tmpLists.add(member)
        }

    }

    removeMemberToTmpList = (member) => {
        this.state.tmpLists.forEach((mem) => {
            if (member.uuid === mem.uuid) {
                this.state.tmpLists.delete(mem)
            }
        })
    }

    showDialogValidation = () => {

        let membersItems = []

        var checkboxes = document.querySelectorAll('input[type="checkbox"]')
        let selectAll = document.getElementById("select-all")

        for (var i = 0; i < checkboxes.length; i++) {
            if (checkboxes[i].checked && checkboxes[i] !== selectAll) {

                membersItems.push(checkboxes[i])

            }

        }

        membersItems = membersItems.map(el => {


            const member = this.state.members.filter(m => m.email_id === el.id)[0]

            return member


        })

        this.state.tmpLists = filterHelper.mapMembers(membersItems)
        membersItems = this.state.tmpLists.map(member => <ListMemberDialogDisplayItem key={member.email_id} member={member} />)


        this.setState({
            addToListOpen: true,
            dialogChilds: membersItems
        })
    }

    hideDialog = () => {
        this.setState({
            addToListOpen: false
        })
    }



    loadingToggleStart = () => {
        this.setState({ loading: true })
    }

    loadingToggleEnd = () => {
        this.setState({ loading: false })
    }

    validateTmpList = () => {

        let tmpMappedByUuid = new Set()

        this.state.tmpLists.forEach(m => {
            memberController.findMember(m.email_address).then(response => {

                let member = response.data.exact_matches.members[0]
                member = {
                    ...member,
                    uuid: member.id
                }
                tmpMappedByUuid.add(member)
                this.props.updateListToCreate(tmpMappedByUuid)

            })
        })

        this.state.tmpLists = []
        this.hideDialog()

    }

    onAgeFilterChange = (e) => {

        try {

            const ageCondition = this.state.ageFilter[e.target.value]
            if (ageCondition) {

                this.state.activeAgeFilter = true
                this.state.currentAgeFilter = ageCondition
                const members = this.gatheredFilter()
                this.setState({ members })
            } else {
                this.state.activeAgeFilter = false
                this.setState({ members: this.gatheredFilter() })
            }

        } catch (e) {

            console.log('exception')
        }
    }

    onPercentageFilter = (e) => {


        try {

            const currentPercentageFilter = this.state.percentageFilter[e.target.value]

            if (currentPercentageFilter) {
                this.state.activePercentageFilter = true
                this.state.currentPercentageFilter = currentPercentageFilter
                const members = this.gatheredFilter()

                this.setState({ members })
            } else {
                this.state.activePercentageFilter = false
                this.state.currentPercentageFilter = ''
                const members = this.gatheredFilter()
                this.setState({ members })
            }


        } catch (e) {

            this.state.activePercentageFilter = false
            this.state.currentPercentageFilter = ''
            const members = this.gatheredFilter()
            this.setState({ members })

        }
    }
    onSpecificCountryFilterChange = (e) => {
        try {

            this.state.currentCountrySpecificFilter = this.state.countryFilter[e.target.value]
            this.state.activeSpecificCountryFilter = true
            const members = this.gatheredFilter()
            this.setState({ members })

        } catch (e) {
            const activeSpecificCountryFilter = false
            this.setState({ activeSpecificCountryFilter })
        }
    }

    onUserFilterChange = (e) => {

        try {


            const user = this.state.user


            if (user !== '') {
                this.state.activeUserFilter = true
                const members = this.gatheredFilter()
                this.setState({ members, currentUserFilter: user })

            }


        } catch (e) {

        }
    }

    onCountryFilterChange = (e) => {
        try {


            const country = this.state.country


            if (country !== '') {
                this.state.activeCountryFilter = true
                const members = this.gatheredFilter()
                this.setState({ members, currentCountryFilter: country })

            }


        } catch (e) {

        }
    }


    onCityFilterChange = (e) => {
        e.preventDefault()

        const city = this.state.city

        if (city !== '') {

            this.state.activeCityFilter = true
            const members = this.gatheredFilter()
            this.setState({ members })
        }
    }

    rightPaginationUpdate = () => {

        const limit = this.state.limit
        const totalItems = this.state.totalItems
        const offset = this.state.offset + limit
        let diff = totalItems - offset

        if (diff > 0) {

            const paginationInf = offset
            const paginationSup = offset + limit < totalItems ? paginationInf + limit : offset + diff


            this.loadingToggleStart()
            if (this.state.filterByLink) {

                campaignController.getOneReportClicksDetails(this.props.campaign.id, this.state.currentLink.id, limit, paginationInf).then(res => {

                    const members = res.data.members
                    const totalItems = res.data.total_items
                    this.setState({ members, totalItems, paginationInf, paginationSup, offset })
                    this.loadingToggleEnd()
                })
            } else {

                campaignController.getReportsOpensDetails(this.props.campaign.id, limit, paginationInf).then(response => {

                    const members = response.data.members
                    const totalItems = response.data.total_items
                    this.setState({ members, totalItems, paginationInf, paginationSup, offset })
                    this.loadingToggleEnd()
                })




            }

        }


    }

    getOpensReportDetails = () => {
        campaignController.getReportsOpensDetails(this.props.campaign.id).then(response => {

            this.state.tmpMembers = response.data.members
            const members = this.gatheredFilter()

            const limit = this.state.limit
            const totalItems = response.data.total_items
            const paginationInf = 0
            const paginationSup = limit < totalItems ? limit : totalItems

            this.setState({ members, filterByLink: false, paginationInf, totalItems, paginationSup })
            this.loadingToggleEnd()
        })
    }

    leftPaginationUpdate = () => {



        const limit = this.state.limit
        const totalItems = this.state.totalItems
        const offset = limit < totalItems ? limit : totalItems


        const paginationSup = this.state.paginationInf
        const paginationInf = this.state.paginationInf - offset


        if (paginationInf >= 0) {
            this.loadingToggleStart()


            if (this.state.filterByLink) {

                campaignController.getOneReportClicksDetails(this.props.campaign.id, this.state.currentLink.id, this.state.limit, paginationInf).then(res => {

                    const members = res.data.members
                    const limit = this.state.limit
                    const totalItems = res.data.total_items
                    const paginationSup = limit < totalItems ? limit : totalItems

                    this.setState({ members, totalItems, paginationInf, paginationSup })
                    this.loadingToggleEnd()
                })
            } else {

                campaignController.getReportsOpensDetails(this.props.campaign.id, limit, paginationInf).then(response => {

                    const members = response.data.members

                    this.setState({ members, totalItems: response.data.total_items, paginationInf, paginationSup })
                    this.loadingToggleEnd()
                })




            }

        }

    }

    onLimitChange = (e) => {
        try {

            const limit = this.state.limits[e.target.value]
            const paginationInf = 0
            const offset = 0

            this.loadingToggleStart()

            if (this.state.filterByLink) {

                campaignController.getOneReportClicksDetails(this.props.campaign.id, this.state.currentLink.id, limit).then(res => {

                    this.state.members = res.data.members
                    const members = this.gatheredFilter()
                    const limit = this.state.limit
                    const totalItems = res.data.total_items
                    const paginationSup = limit < totalItems ? limit : totalItems

                    this.setState({ members, totalItems, limit, paginationSup, offset })
                    this.loadingToggleEnd()
                })
            } else {

                campaignController.getReportsOpensDetails(this.props.campaign.id, limit).then(response => {

                    const members = response.data.members
                    const totalItems = response.data.total_items
                    const paginationSup = limit < totalItems ? limit : totalItems

                    this.setState({ members, totalItems, paginationInf, paginationSup, limit, offset })
                    this.loadingToggleEnd()
                })




            }

        } catch (e) {

        }
    }

    gatheredFilter() {

        return filterHelper.gatheredFilters(this.state.activeAgeFilter,
            this.state.activeSpecificCountryFilter,
            this.state.activeCountryFilter,
            this.state.activeCityFilter,
            this.state.activeUserFilter,
            this.state.user,
            this.state.country,
            this.state.currentCountrySpecificFilter,
            this.state.city,
            this.state.currentAgeFilter,
            this.state.tmpMembers,
            this.state.activePercentageFilter,
            this.state.currentPercentageFilter
        )
    }

    handleCheckAll = (source) => {

        let selectAll = document.getElementById("select-all")
        this.setState({ selectAll: selectAll.checked })
    }

    async findMappedMembersBySearch(tmpListMembers) {

        const tmpMappedByUuid = []

        for (let i = 0; i < tmpListMembers.length; i++) {

            const m = tmpListMembers[i]
            const candidate = await memberController.findMember(m.email_address).then(response => {

                let member = response.data.exact_matches.members[0]
                member = {
                    ...member,
                    uuid: member.id
                }

                return member

            })

            tmpMappedByUuid.push(candidate)
        }


        return tmpMappedByUuid
    }


    render() {


        const campaign = this.props.campaign
        const emails = campaign.emails_sent

        const members = filterHelper.mapMembers(this.state.members)
        return (
            <div className="uk-padding-small">
                <div className="flex-column">
                    <div className="sized-box-height-2"></div>
                    <h1 class="uk-heading-line uk-text-center"><span>{campaign.name}</span></h1>

                    <div className="centered-no-back">

                        <span>{campaign.list_name}</span>
                        <span>{campaign.send}</span>
                    </div>

                </div>
                <div className="uk-flex uk-flex-around uk-flex-middle uk-flex-wrap uk-flex-wrap-around">
                        <div className="text-white circular list-details-stats primary-theme-color uk-margin-bottom">
                            <div className="list-details-stats-content">
                                <h5 className="text-white">EMAILS</h5>
                                <span className="font-20 text-white">{emails}</span>
                            </div>

                        </div>
                        <div className="circular list-details-stats primary-theme-color uk-margin-bottom">

                            <div className=" list-details-stats-content ">
                                <h5 className="text-white">OPENS</h5>
                                <span className="font-20 text-white">{campaign.opens + "%"}</span>
                            </div>

                        </div>
                        <div className="circular list-details-stats primary-theme-color uk-margin-bottom">

                            <div className=" list-details-stats-content ">
                                <h5 className="text-white">CLICKS</h5>
                                <span className="font-20 text-white">{campaign.clicks + "%"}</span>
                            </div>

                        </div>
                        <div className="circular list-details-stats primary-theme-color uk-margin-bottom">

                            <div className=" list-details-stats-content ">
                                <h5 className="text-white">BOUNCES</h5>
                                <span className="font-20 text-white">{campaign.bounces.hard_bounces + campaign.bounces.soft_bounces + campaign.bounces.syntax_errors}</span>
                            </div>

                        </div>

                    </div>
                <div className="items-container">

                    <div className="sizedbox-height-5"></div>
                    <div className="uk-flex uk-flex-between uk-flex-middle uk-flex-wrap">
                        <div className="uk-flex uk-flex-center">
                            <span className="primary-theme-color-foreground" uk-icon={"icon: ban ; ratio: 2"}></span>
                            <div className="spacer-1"></div>
                            <form>
                                <div class="uk-margin">
                                    <div uk-form-custom="target: > * > span:first-child">
                                        <select onChange={this.onLimitChange}>
                                            <option value="">limit</option>
                                            {this.state.limits.map((limit, index) => <option value={index}>{limit}</option>)}

                                        </select>
                                        <button class="uk-button uk-button-default" type="button" >
                                            <span></span>
                                            <span className="primary-theme-color-foreground" uk-icon="icon: chevron-down"></span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="uk-flex uk-flex-center">

                            <span className="primary-theme-color-foreground" uk-icon={"icon: list ; ratio: 2"}></span>
                            <div className="spacer-1"></div>
                            <form>
                                <div class="uk-margin">
                                    <div uk-form-custom="target: > * > span:first-child">
                                        <select onChange={this.onTypeChange}>
                                            <option value="">type</option>
                                            {this.state.type.map((type, index) => <option value={index}>{type}</option>)}

                                        </select>
                                        <button class="uk-button uk-button-default" type="button" >
                                            <span></span>
                                            <span className="primary-theme-color-foreground" uk-icon="icon: chevron-down"></span>
                                        </button>
                                    </div>
                                </div>
                            </form>


                        </div>

                        <div className="uk-flex uk-flex-center">

                            <span className="primary-theme-color-foreground" uk-icon={"icon: world ; ratio: 2"}></span>
                            <div className="spacer-1"></div>
                            <form>
                                <div class="uk-margin">
                                    <div uk-form-custom="target: > * > span:first-child">
                                        <select onChange={this.onSpecificCountryFilterChange}>
                                            <option value="">NATION</option>
                                            {this.state.countryFilter.map((country, index) => <option value={index}>{country}</option>)}

                                        </select>
                                        <button class="uk-button uk-button-default" type="button" >
                                            <span></span>
                                            <span className="primary-theme-color-foreground" uk-icon="icon: chevron-down"></span>
                                        </button>
                                    </div>
                                </div>
                            </form>


                        </div>

                        <div className="uk-flex uk-flex-center">

                            <span className="primary-theme-color-foreground" uk-icon={"icon: calendar ; ratio: 2"}></span>
                            <div className="spacer-1"></div>
                            <form>
                                <div class="uk-margin">
                                    <div uk-form-custom="target: > * > span:first-child">
                                        <select onChange={this.onAgeFilterChange}>
                                            <option value="">AGE</option>
                                            {this.state.ageFilter.map((condition, index) => <option value={index}>{condition}</option>)}

                                        </select>
                                        <button class="uk-button uk-button-default" type="button" >
                                            <span></span>
                                            <span className="primary-theme-color-foreground" uk-icon="icon: chevron-down"></span>
                                        </button>
                                    </div>
                                </div>
                            </form>


                        </div>

                        <div className="uk-flex uk-flex-center">

                            <span className="primary-theme-color-foreground" uk-icon={"icon: unlock ; ratio: 2"}></span>
                            <div className="spacer-1"></div>
                            <form>
                                <div class="uk-margin">
                                    <div uk-form-custom="target: > * > span:first-child">
                                        <select onChange={this.onPercentageFilter}>
                                            <option value="">PERCENTAGE</option>
                                            {this.state.percentageFilter.map((condition, index) => <option value={index}>{condition}</option>)}

                                        </select>
                                        <button class="uk-button uk-button-default" type="button" >
                                            <span></span>
                                            <span className="primary-theme-color-foreground" uk-icon="icon: chevron-down"></span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="uk-flex uk-flex-between uk-flex-middle uk-flex-wrap">
                        <div className="uk-flex uk-flex-center uk-flex-middle">
                            <div class="uk-margin">
                                <div class="uk-inline">
                                    <span class="uk-form-icon" uk-icon="icon: user"></span>
                                    <a onClick={this.onUserFilterChange} class="uk-form-icon uk-form-icon-flip primary-theme-color" uk-icon="icon: search"></a>
                                    <input onChange={this.handleInputChange} name="user" placeholder="name or email" class="uk-input uk-margin-remove" type="text" value={this.state.user}></input>
                                </div>
                            </div>
                        </div>
                        <div className="uk-flex uk-flex-center uk-flex-middle">


                            <div class="uk-margin">
                                <div class="uk-inline">
                                    <span class="uk-form-icon" uk-icon="icon: world"></span>
                                    <a onClick={this.onCountryFilterChange} class="uk-form-icon uk-form-icon-flip primary-theme-color" uk-icon="icon: search"></a>
                                    <input onChange={this.handleInputChange} name="country" placeholder="native country" class="uk-input uk-margin-remove" type="text" value={this.state.country}></input>
                                </div>
                            </div>

                        </div>

                        <div className="uk-flex uk-flex-center uk-flex-middle">
                            <div class="uk-margin">
                                <div class="uk-inline">
                                    <span class="uk-form-icon" uk-icon="icon: location"></span>
                                    <a onClick={this.onCityFilterChange} class="uk-form-icon uk-form-icon-flip primary-theme-color" uk-icon="icon: search"></a>
                                    <input onChange={this.handleInputChange} name="city" placeholder="city of residence" class="uk-input uk-margin-remove" type="text" value={this.state.city}></input>
                                </div>
                            </div>
                        </div>



                        <div >
                            <button onClick={this.leftPaginationUpdate} type="submit" className="primary-theme-color-foreground" uk-icon="icon: chevron-left"></button>
                            <span>{this.state.paginationInf} - {this.state.paginationSup} of {this.state.totalItems}</span>
                            <button onClick={this.rightPaginationUpdate} type="submit" className="primary-theme-color-foreground" uk-icon="icon: chevron-right"></button>
                        </div>

                    </div>





                    {
                        this.state.filterByLink ? <div className="uk-flex uk-flex-center uk-flex-middle">

                            <span className="primary-theme-color-foreground" uk-icon={"icon: link ; ratio: 2"}></span>
                            <div className="spacer-1"></div>
                            <form>
                                <div class="uk-margin">
                                    <div uk-form-custom="target: > * > span:first-child">
                                        <select onChange={this.onLinkChange}>
                                            <option value="">links</option>
                                            {this.state.links.map((link, index) => <option value={index}>{link.url}</option>)}

                                        </select>
                                        <button class="uk-button uk-button-default" type="button" >
                                            <span></span>
                                            <span className="primary-theme-color-foreground" uk-icon="icon: chevron-down"></span>
                                        </button>
                                    </div>
                                </div>
                            </form>


                        </div> : null
                    }




                    <div className="default-divider"></div>

                    {this.state.addToListOpen ? (<Dialog key='dialog' childs={this.state.dialogChilds} hideDialog={this.hideDialog} mainAction={this.validateTmpList} />) : (<div></div>)}

                    <div className="flex-right">
                        <button onClick={this.showDialogValidation} type="submit" className="uk-button rounded-btn uk-button-small">ADD TO LIST</button>
                    </div>

                    <div className="sizedbox-height-5"></div>

                    {
                        !this.state.loading ? (<div className="table-container">

                            <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                                <label><input id="select-all" onChange={this.handleCheckAll} class="uk-checkbox primary-theme-color-foreground" type="checkbox" /> SELECT ALL</label>
                            </div>
                            <table class="uk-table uk-table-responsive uk-table-divider">
                                <thead>
                                    <tr>
                                        <th>ACTION</th>
                                        <th>EMAIL</th>
                                        <th>INFOS</th>
                                    </tr>
                                </thead>
                                <tbody className="padding-5">

                                    {members.map(member => <MemberReportItem selectAll={this.state.selectAll} key={member.uuid} addMemberToTmpList={this.addMemberToTmpList} removeMemberToTmpList={this.removeMemberToTmpList} member={member} />)}


                                </tbody>
                            </table>
                        </div>) : (<div className="inline-content-center"><span class="uk-margin-small-right" uk-spinner="ratio: 3"></span></div>)
                    }






                </div>

                <div className="uk-flex uk-flex-center uk-flex-middle">
                    <button onClick={this.leftPaginationUpdate} type="submit" className="primary-theme-color-foreground" uk-icon="icon: chevron-left"></button>
                    <span>{this.state.paginationInf} - {this.state.paginationSup} of {this.state.totalItems}</span>
                    <button onClick={this.rightPaginationUpdate} type="submit" className="primary-theme-color-foreground" uk-icon="icon: chevron-right"></button>
                </div>
                <div className="sizedbox-height-5"></div>


            </div>
        )
    }
}

export default CampaignScreenDetails
