import React, { Component } from 'react'
import listController from '../../controller/listsController'
import ListTagComponent from '../lists/ListTagComponent'

export class AddMemberForm extends Component {

    state = {
        email: '',
        firstName: '',
        lastName: '',
        country: '',
        city: '',
        birthDate: '',
        listId: '',
        lists: this.props.lists,
        selectedListId: '',
        listsTagComponents: [],
        listTotal: 0,
        notification: false,
        success: false,
        message: '',
        uploadingCounter: 0
    }

    handleFieldChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    cancelAction = () => {
        this.props.hideDialog()
    }

    createMember = (e) => {
        e.preventDefault()

        if (this.state.listsTagComponents.length > 0) {


            const data = [{
                email_address: this.state.email,
                status: 'subscribed',
                merge_fields: {
                    FIRSTNAME: this.state.firstName,
                    LASTNAME: this.state.lastName,
                    CITY: this.state.city,
                    COUNTRY: this.state.country,
                    BIRTHDATE: this.state.birthDate,
                }
            }]


            this.state.listsTagComponents.forEach(tagComponent => {


                const list = tagComponent.props.list
                const listId = tagComponent.props.list.id


                listController
                    .addMembersInList(listId, data)
                    .then(response => {

                        if (response.status === 200) {

                            this.state.uploadingCounter += 1
                            this.showNotification(true, `Member added to ${list.name} !`)
                            if (this.state.uploadingCounter === this.state.listsTagComponents.length) {
                                this.showNotification(true, `Uploading complete for ${this.state.uploadingCounter} list(s) !`, true)

                            }

                        }

                    }).catch(e => console.log(e))
            })

        } else {
            this.showNotification(false, `Please make sure you've selected a list or you have not remove the current list tag !`, false, 10000)
        }


    }

    onListChange = (e) => {

        const list = this.state.lists[e.target.value]
        const presents = this.state.listsTagComponents.filter(listEl => listEl.props.list.id === list.id)

        if (presents.length === 0) {
            const listTag = <ListTagComponent list={list} removeAction={this.removeListInTags} />
            const listsTagComponents = this.state.listsTagComponents
            listsTagComponents.push(listTag)
            this.setState({ listId: list.id, listsTagComponents })
        }


    }

    removeListInTags = (list) => {
        const listsTagComponents = this.state.listsTagComponents.filter(listTagComponent => listTagComponent.props.list.id !== list.id)
        this.setState({ listsTagComponents })
    }

    showNotification = (success, message, close = false, duration = 3000) => {

        this.setState({ notification: true, success, message })
        setTimeout(() => {
            this.setState({ notification: false, success: false, message: '' })
            close ? this.props.hideDialog() : console.log('')
        }, duration)
    }
    render() {
        return (
            <div className="row">
                <div className=" flex-column-space-around content-30w">

                    {this.state.listsTagComponents}

                </div>
                <div className="flex-column content-70w">
                    {this.state.notification ?
                        <div className="uk-margin">

                            {
                                this.state.success ? <input class="uk-input uk-form-success uk-form-width-expand" type="text" placeholder="form-success" value={this.state.message}></input>
                                    :
                                    <input class="uk-input uk-form-danger uk-form-width-expand" type="text" placeholder="form-danger" value={this.state.message}></input>
                            }

                        </div>

                        : null
                    }

                    <div className="inline-content-center-no-space">
                        <span className="primary-theme-color-foreground" uk-icon={"icon: mail ; ratio: 2"}></span>
                        <div className="spacer-1"></div>
                        <form>
                            <div class="uk-margin">
                                <div uk-form-custom="target: > * > span:first-child">
                                    <select onChange={this.onListChange}>
                                        <option value="">Please select...</option>
                                        {this.state.lists.map((list, index) => <option value={index}>{list.name}</option>)}

                                    </select>
                                    <button class="uk-button uk-button-default" type="button" >
                                        <span></span>
                                        <span className="primary-theme-color-foreground" uk-icon="icon: chevron-down"></span>
                                    </button>
                                </div>
                            </div>
                        </form>


                    </div>



                    <form >
                        <div className="centered ">

                            <div>
                                <div className="uk-inline">
                                    <span className="uk-form-icon" uk-icon="icon: user" />
                                    <input name="firstName" placeholder="first name" onChange={this.handleFieldChange} className="uk-align-center uk-input bottom-border no-border" type="text" value={this.state.firstName} />
                                </div>
                            </div>

                            <div>
                                <div className="uk-inline">
                                    <span className="uk-form-icon" uk-icon="icon: user" />
                                    <input name="lastName" placeholder="last name" onChange={this.handleFieldChange} className="uk-align-center uk-input bottom-border no-border" type="text" value={this.state.lastName} />
                                </div>
                            </div>

                            <div>
                                <div className="uk-inline">
                                    <span className="uk-form-icon" uk-icon="icon: location" />
                                    <input name="city" placeholder="city of residence" onChange={this.handleFieldChange} className="uk-align-center uk-input bottom-border no-border" type="text" value={this.state.city} />
                                </div>
                            </div>

                            <div>
                                <div className="uk-inline">
                                    <span className="uk-form-icon" uk-icon="icon: world" />
                                    <input name="country" placeholder="birth country" onChange={this.handleFieldChange} className="uk-align-center uk-input bottom-border no-border" type="text" value={this.state.country} />
                                </div>
                            </div>


                            <div>
                                <div className="uk-inline">
                                    <span className="uk-form-icon" uk-icon="icon: mail" />
                                    <input name="email" placeholder="email" onChange={this.handleFieldChange} className="uk-align-center uk-input bottom-border no-border" type="text" value={this.state.email} />
                                </div>
                            </div>

                            <div>
                                <div className="uk-inline">
                                    <span className="uk-form-icon" uk-icon="icon: calendar" />
                                    <input name="birthDate" placeholder="birth : YYYYMMDD" onChange={this.handleFieldChange} className="uk-align-center uk-input bottom-border no-border" type="text" value={this.state.birthDate} />
                                </div>
                            </div>

                            <div className="sizedbox-height-5"></div>


                            <div className="modal-footer inline-content-center-no-space ">
                                <button onClick={this.cancelAction} type="submit" className="uk-button uk-button-large">CANCEL</button>
                                <div className="spacer-1"></div>
                                <button onClick={this.createMember} type="submit" className="uk-button uk-button-large primary-theme-color text-white">CREATE</button>
                            </div>

                        </div>

                    </form>

                </div>
            </div>
        )
    }
}

export default AddMemberForm
