
class NotificationService {

    display(message, status = 'success', pos = 'top-center', timeout= 5000) {
        // eslint-disable-next-line no-undef
        UIkit.notification({
            message,
            status,
            pos,
            timeout
        })
    }
}

export default new NotificationService()