import React, { Component } from 'react'
import Map from '../map'
import Chart from '../chart'
import filterHelper from '../../helpers/FilterHelper'
import campaignController from '../../controller/campaignController'
import CampaignScreen from '../campaigns'
import MemberScreen from '../members'
export class DashboardScreen extends Component {

    state = {
        campaigns: [],
        subscribers: this.props.account.general.total_subscribers,
        lastOpensSubscribers: []
    }


    componentDidMount() {

        const lastCampaign = this.props.account.reports[0]
        campaignController.getReportsOpensDetails(lastCampaign.id).then(res => {

            const lastOpensSubscribers = res.data.members
            this.setState({ campaigns: this.props.account.reports, lastOpensSubscribers })

        })

    }

    displayAll = (e) => {

        e.preventDefault()

        const type = e.target.name
        let newScreen = null

        switch (type) {
            case 'campaigns':

                newScreen = <CampaignScreen updateListToCreate={this.props.updateListToCreate} updateScreen={this.props.updateScreen} />
                break
            case 'opens':
                newScreen = <MemberScreen account={this.props.account} />
                break
            default:
                break
        }
        this.props.updateScreen(newScreen)
    }



    render() {

        const lastCampaigns = this.state.campaigns.map(campaign => {

            campaign = {
                ...campaign,
                name: campaign.subject_line,
                opens: Number(campaign.opens.open_rate * 100).toFixed(2),
                clicks: Number(campaign.clicks.click_rate * 100).toFixed(2),
            }

            return campaign
        })

        const lastOpensSubscribers = filterHelper.mapMembers(this.state.lastOpensSubscribers)
        return (
                <div>
                    <div className="sizedbox-height-5"></div>
                    <div className="uk-flex uk-flex-center uk-flex-middle" uk-grid="">
                        <div>
                            <div id="dashboard-card" className="uk-card uk-card-default uk-card-hover uk-card-body uk-padding-small">
                                <h3 className="uk-card-title uk-flex uk-flex-center">OPENS</h3>
                                <h2 className="uk-flex uk-flex-center primary-theme-color-foreground">{Number(this.props.account.general.industry_stats.open_rate * 100).toFixed(2) + "%"}</h2>
                            </div>
                        </div>

                        <div>
                            <div id="dashboard-card" className="uk-card uk-card-default uk-card-hover uk-card-body uk-padding-small">
                                <h3 className="uk-card-title uk-flex uk-flex-center">SUBSCRIBERS</h3>
                                <h2 className="uk-flex uk-flex-center primary-theme-color-foreground">{this.state.subscribers}</h2>
                            </div>
                        </div>

                        <div>
                            <div id="dashboard-card" className="uk-card uk-card-default uk-card-hover uk-card-body uk-padding-small">
                                <h3 className="uk-card-title uk-flex uk-flex-center">BOUNCES</h3>
                                <h2 className="uk-flex uk-flex-center primary-theme-color-foreground">{Number(this.props.account.general.industry_stats.bounce_rate * 100).toFixed(2) + "%"}</h2>
                            </div>
                        </div>
                    </div>

                    <div className="sizedbox-height-5"></div>

                    <div className="uk-flex uk-padding uk-margin-bottom uk-flex-wrap">
                        <div className="uk-flex uk-flex-column uk-padding-small uk-margin-bottom uk-width-1-2@l">
                            <h3>MEMBERS LOCATIONS</h3>
                            <Map />
                        </div>
                        <div className="uk-flex uk-flex-column uk-padding-small uk-width-1-2@l">
                            <div className="uk-flex uk-flex-between uk-margin-small-bottom uk-flex-wrap">
                                <h3>LAST CAMPAIGNS</h3>
                                <button name='campaigns' onClick={this.displayAll} type="submit" className="uk-button uk-button-small primary-theme-color text-white">VIEW ALL</button>
                            </div>
                            <div className="uk-overflow-auto last-campaigns">
                                <table className="uk-table uk-table-divider">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Opens</th>
                                            <th>Clicks</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {lastCampaigns.map(lastCampaign => <tr>
                                            <td>{lastCampaign.name}</td>
                                            <td>{lastCampaign.opens + '%'}</td>
                                            <td>{lastCampaign.clicks + '%'}</td>
                                        </tr>)}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="uk-padding uk-flex uk-flex-between uk-margin-large-top uk-flex-wrap">
                        <div className="uk-flex uk-flex-column uk-padding-small uk-width-1-2@l uk-margin-small-bottom">
                            <div className="uk-flex uk-flex-between uk-margin-small-bottom uk-flex-wrap">
                                <h3>LAST OPENS</h3>
                                <button name='opens' onClick={this.displayAll} type="submit" className="uk-button uk-button-small primary-theme-color text-white">VIEW ALL</button>
                            </div>
                            <div className="uk-overflow-auto">
                                <table className="uk-table uk-table-divider">
                                    <thead>
                                        <tr>
                                            <th>name</th>
                                            <th>email</th>
                                            <th>Opening date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {lastOpensSubscribers.map(lastSubscriber => <tr>
                                            <td>{lastSubscriber.name}</td>
                                            <td>{lastSubscriber.email_address}</td>
                                            <td>{new Date(lastSubscriber.opens[0].timestamp).toLocaleDateString('fr-Fr')}</td>
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="uk-flex uk-flex-column uk-padding-small uk-width-1-2@l">
                            <h3>TRACKING</h3>
                            <div className="uk-flex">
                                <Chart />
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}

export default DashboardScreen
