import React, {Component} from 'react';
import consularController from '../../../../controller/consularController';

class AddSympathizerForm extends Component {

    state = {
        files:this.props.files,
        folders: this.props.folders,
        folder: this.props.folder,
        image:this.props.image,
        firstName:'',
        lastName:'',
        districts: this.props.districts,
        cities: [],
        selectedDistrictIndex: -1,
        selectedCityIndex: -1,
        link:'',
        processing: false
    }

    onFolderChange = e =>{
        const folderIndex = e.target.value;

        if(folderIndex.toString()!=='-1'){
            const folder = this.state.folders[folderIndex]
            this.setState({processing: true}, async () => {
                const images = (await consularController.getFilesByFolderId(folder.id, folder.created_at.toString().split('T')[0])).data.files
                this.setState({files: images, processing: false, folder}, () => {
                    this.props.onFolderChange(folder, images)
                })
            })
        }
    }

    onFileChange = e=>{
        const fileIndex = e.target.value;

        if(fileIndex.toString()!=='-1'){
            const file = {
                ...this.state.files[fileIndex],
                url:this.state.files[fileIndex].full_size_url
            }
            this.props.onFileChange(file)
            this.setState({image: file})
        }

    }

    handleFieldChange = e =>{

        this.props.handleFieldChange(e)
        this.setState({[e.target.name]:e.target.value})
    }


    // Personal Info
    onDistrictChange = e => {

        this.props.onDistrictChange(e)
        const district = this.state.districts[e.target.value]
        const cities = district.cities
        const city = {}
        const selectedDistrictIndex = e.target.value
        const selectedCityIndex = -1

        this.setState({ cities, city, selectedDistrictIndex, selectedCityIndex })
    }

    onCityChange = e => {

        this.props.onCityChange(e)
        const selectedCityIndex = e.target.value
        this.setState({ selectedCityIndex })
    }

    render() {

        const cities = this.state.cities
        const imageSelected = this.state.image!==null
        const folderSelected = this.state.folder!==null
        return (
            <div className="centered">

                <div className="flex-row-center">

                    <div className="uk-width-auto">
                        <img className="uk-comment-avatar circular" src={imageSelected?this.state.image.full_size_url:''} width="100" height="100"
                             alt="consul-image"/>
                    </div>

                    <div className="sizedbox-width-5"/>
                    <div className="flex-column">
                        <div className="uk-inline content-20w">
                            <span className="uk-form-icon" uk-icon="icon: user" />
                            <input name="firstName" placeholder="first name" onChange={this.handleFieldChange} className="uk-align-center uk-input bottom-border no-border" type="text" value={this.state.firstName} />
                        </div>



                        <div className="uk-inline content-20w">
                            <span className="uk-form-icon" uk-icon="icon: user" />
                            <input name="lastName" placeholder="last name" onChange={this.handleFieldChange} className="uk-align-center uk-input bottom-border no-border" type="text" value={this.state.lastName} />
                        </div>
                    </div>
                </div>

                <div className="inline-content-center-no-space">
                        <span className="primary-theme-color-foreground" uk-icon={"icon: folder ; ratio: 2"}></span>
                        <div className="spacer-1"></div>
                        <form>
                            <div className="uk-margin">
                                <div uk-form-custom="target: > * > span:first-child">
                                    <select onChange={this.onFolderChange}>
                                        <option value={-1}>dossiers...</option>
                                        {this.state.folders.map((folder, index) => <option value={index} selected={folderSelected?folder.id.toString()===this.state.folder.id.toString():false}>{folder.name}</option>)}
                                    </select>
                                    <button className="uk-button uk-button-default" type="button">
                                        <span></span>
                                        <span className="primary-theme-color-foreground"
                                              uk-icon="icon: chevron-down"></span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>

                <div className="inline-content-center-no-space">
                    <span className="primary-theme-color-foreground" uk-icon={"icon: image ; ratio: 2"}></span>
                    <div className="spacer-1"></div>
                    <form>
                        <div className="uk-margin">
                            <div uk-form-custom="target: > * > span:first-child">
                                <select onChange={this.onFileChange} disabled={this.state.processing}>
                                    <option value={-1}>images...</option>
                                    {this.state.files.map((file, index) => <option value={index} selected={imageSelected?file.id.toString()===this.state.image.id.toString():false}>{file.name}</option>)}

                                </select>
                                <button className="uk-button uk-button-default" type="button">
                                    <span></span>
                                    <span className="primary-theme-color-foreground"
                                          uk-icon="icon: chevron-down"></span>
                                </button>
                            </div>
                        </div>
                    </form>
                    { this.state.processing ? <div className="uk-margin-small-left" uk-spinner=""/>: null }
                </div>

                <div className="uk-inline content-20w">
                    <span className="uk-form-icon" uk-icon="icon: link" />
                    <input name="link" placeholder="website link" onChange={this.handleFieldChange} className="uk-align-center uk-input bottom-border no-border" type="text" value={this.state.link} />
                </div>

                <div className="spacer-1"/>

                <div className="inline-content-center-no-space">
                    <span className="primary-theme-color-foreground" uk-icon={"icon: location ; ratio: 1.5"}></span>
                    <div className="spacer-1"/>
                    <form>
                        <div className="uk-margin">
                            <div uk-form-custom="target: > * > span:first-child">
                                <select onChange={this.onDistrictChange}>

                                    <option value="">District...</option>
                                    {this.props.districts.map((district, index) => <option value={index}>{district.name}</option>)}

                                </select>
                                <button className="uk-button uk-button-default" type="button">
                                    <span/>
                                    <span className="primary-theme-color-foreground"
    uk-icon="icon: chevron-down"/>
                                </button>
                            </div>
                        </div>
                    </form>

                    <div className="spacer-1"/>

                    <form>
                        <div className="uk-margin">
                            <div uk-form-custom="target: > * > span:first-child">
                                <select onChange={this.onCityChange}>

                                    <option value="">City...</option>
                                    {cities.map((city, index) => <option value={index}>{city.name}</option>)}

                                </select>
                                <button className="uk-button uk-button-default" type="button">
                                    <span></span>
                                    <span className="primary-theme-color-foreground"
                                          uk-icon="icon: chevron-down"></span>
                                </button>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        );
    }
}

export default AddSympathizerForm;