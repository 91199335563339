import React, {Component} from 'react'
import ConsulListItem from '../ConsulListItem'
import AddConsularAdvisorForm from './AddConsularAdvisorForm'
import Dialog from '../../dialog/index'
import consularController from '../../../controller/consularController'
import EditConsularAdvisorForm from './EditConsularAdvisorForm'
import EditSympathizerForm from "./sympathizers/EditSympathizerForm"
import notification from '../../../services/notification'
import FileHelper from '../../../helpers/FileHelper'
import { ENTITIES } from '../../../helpers/EntityKeys'

export class ConsularAdvisorsScreen extends Component {


    state = {
        files: this.props.files,
        folders: this.props.folders,
        consuls: this.props.consuls,
        tmpConsuls: this.props.consuls,
        loading: false,
        openDialog: false,
        dialogChilds: [],
        assistants: this.props.assistants,
        socialNetworksTags: this.props.socialNetworksTags,
        districts: this.props.districts,
        dialogTitle: '',
        consularName:''
    }


    handleFieldChange = e => {

        const value = e.target.value.toLowerCase()

        let consuls = this.state.consuls
        if(value.length>0) consuls = consuls.filter(consul=>consul.name.toLowerCase().includes(value))

        this.setState({ [e.target.name]: value, tmpConsuls: consuls })

    }

    getConsuls = _ => {
        return consularController.getSympathizers().then(res=>{
            return res.data.sympathizers
        }).then(sympathizers=>{
            return consularController.getConsuls().then(res => {

                const consuls = res.data.consuls.concat(sympathizers).map(consul => {

                    consul = {...consul, name: consul.firstName + ' ' + consul.lastName}
                    return consul
                })
                FileHelper.updateLocalStorage(ENTITIES.consuls, consuls)
                this.props.updateRootEntity(ENTITIES.consuls, consuls)
                return this.setState({ consuls,tmpConsuls: consuls })
            })
        })

    }


    displayConsulForm = e => {
        if (!this.state.loading) {
            this.setState({
                openDialog: true,
                dialogTitle: 'Créer une tête de liste',
                dialogChilds: <AddConsularAdvisorForm folders={this.state.folders} files={this.state.files} lists={this.props.lists} hideDialog={this.hideDialog} assistants={this.state.assistants} districts={this.state.districts} socialNetworksTags={this.state.socialNetworksTags} updateConsuls={this.getConsuls}/>
            })
        }
    }

    updateConsul = consul => {


        if(consul.asfeConsular){
            this.setState({
                openDialog: true,
                dialogTitle: 'Mettre à jour une tête de liste',
                dialogChilds: <EditConsularAdvisorForm folders={this.state.folders} files={this.state.files} lists={this.props.lists} consul={consul} hideDialog={this.hideDialog} assistants={this.state.assistants} districts={this.state.districts} socialNetworksTags={this.state.socialNetworksTags} updateConsuls={this.getConsuls} />
            })
        }else{

            this.setState({
                openDialog: true,
                dialogTitle: 'Mettre à jour un sympathisant',
                dialogChilds: <EditSympathizerForm folders={this.state.folders} files={this.state.files} consul={consul} hideDialog={this.hideDialog} districts={this.state.districts} updateConsuls={this.getConsuls}/>
            })
        }

    }

    deleteConsul = consul => {
        if(consul.asfeConsular){
            consularController.deleteConsul(consul.uuid).then(res => res).then((res) => this.getConsuls()).then(()=> notification.display('Consular successfully deleted...')).catch(err => notification.display('Something wrong happened...'))
        } else {
            consularController.deleteSympathizer(consul.uuid).then(res => res).then((res) => this.getConsuls()).then(()=> notification.display('Sympathizer successfully deleted...')).catch(err => notification.display('Something wrong happened...'))
        }
    }

    hideDialog = e => {
        this.setState({ openDialog: false })
    }

    render() {

        const consuls = this.state.tmpConsuls.sort((a,b)=>a.name.toLowerCase()<b.name.toLowerCase()?-1:1)
        return !this.state.loading?(
            <div className="uk-flex uk-flex-column">
                <div className="uk-margin uk-padding uk-flex uk-flex-between uk-flex-wrap-reverse ">
                    <div className="uk-flex uk-flex-center uk-flex-middle">
                        <div className="uk-inline">
                            <span className="uk-form-icon" uk-icon="icon: search"></span>
                            <input onChange={this.handleFieldChange} name="consularName" placeholder="nom tête de liste"
                                   className="uk-form-width-large uk-input uk-margin-remove" type="text"
                                   value={this.state.consularName}></input>
                        </div>
                    </div>
                    <div className="uk-flex uk-flex-end uk-margin-bottom">
                        <button onClick={this.displayConsulForm} type="submit" className="uk-button primary-theme-color text-white">Ajouter</button>
                    </div>
                </div>

                <div className="sizedbox-height-5"></div>
                {this.state.openDialog ? (<Dialog title={this.state.dialogTitle} key='dialog' multipleSteps={true} stepContainer={this.state.dialogChilds} childs={this.state.dialogChilds} hideDialog={this.hideDialog} mainAction={this.uploadAction} />) : (<div></div>)}
                <div>
                    {
                        consuls.map((consul) => <ConsulListItem key={consul.uuid} consul={consul} deleteConsul={this.deleteConsul} updateConsul={this.updateConsul} />)
                    }

                </div>
            </div>
        ):<div className='uk-position-center'><span uk-spinner="ratio: 4.5"></span></div>
    }
}

export default ConsularAdvisorsScreen
