import React, {Component} from 'react';
import DatePicker from 'react-day-picker'
import Geosuggest from 'react-geosuggest'
import TimePicker from 'react-time-picker'
import 'react-day-picker/lib/style.css'


class ConsularAdvisorEvents extends Component {

    state = {
        events: this.props.events
    }

    componentDidMount() {

        const events = this.props.events.map(event=>{

            let e =event;
            if(event.id === undefined && event.uuid !== undefined){
                e = {
                    ...event,
                    id: event.uuid,
                }
            }

            return e
        })

        this.setState({events})
    }


    addEvent = e => {

        e.preventDefault()


        const key = '_' + Math.random().toString(36).substr(2, 9)
        let eventObject = {
            id: key,
            title: '',
            moreInfoLink: '',
            address: '',
            date: new Date().toLocaleDateString('fr-FR').toString(),
            time:'10:00',
            place: null
        }


        const events = this.state.events

        events.push(eventObject)

        this.setState({events})
    }



    removeEvent = event => {
        const events = this.state.events.filter(eventObj => eventObj.id !== event.id)
        this.setState({events}, ()=> {
            this.props.updateEvents(events)
        })
    }

    updateEvent = event => {
        const events = this.state.events.map((eventObj) => {

            if(eventObj.id.toString() === event.id.toString()){
                eventObj = event
            }

            return eventObj
        })
        this.setState({events}, () => {
            this.props.updateEvents(events)
        })

    }

    render() {

        const events = this.state.events.map(eventObject => <EventContent key={eventObject.id} object={eventObject}
                                                                          updateEvent={this.updateEvent}
                                                                          removeEvent={this.removeEvent}/>)
        return (
            <div>
                <div className='flex-column'>

                    <div className="flex-right">
                        <button onClick={this.addEvent} type="submit"
                                className="uk-button primary-theme-color text-white">AJOUTER
                        </button>
                    </div>

                    <div className="sizedbox-height-5"></div>

                    {events}

                </div>
            </div>
        );
    }
}

export default ConsularAdvisorEvents;

class EventContent extends Component {


    state = {
        id:this.props.object.uuid!==undefined?this.props.object.uuid:this.props.object.id,
        title: this.props.object.title,
        moreInfoLink: this.props.object.moreInfoLink,
        date: this.props.object.date,
        address:this.props.object.address,
        place: this.props.object.place ?? { location: { lat: this.props.object.latitude, lng: this.props.object.longitude}},
        time: this.props.object.time,
    }


    handleChange = e => {

        const value = e.target.value
        const key = e.target.name
        this.setState({[key]:value}, () => {
            this.updateCurrentEvent(key,value)
        })
    }

    handleLocationChange = (value) => {

        if (value) {
            this.setState({place: value , address:value.label}, ()=> {
                this.updateCurrentEvent("place", {location: value.location})
                this.updateCurrentEvent("address",value.label)
            })
        }
    }

    onDaySelected = day => {

        const time = new Date(day)
        this.setState({date:time})
        this.updateCurrentEvent("date",time.toLocaleDateString('fr-FR').toString())
    }

    onTimeChange = time => {
        this.updateCurrentEvent("time",time)
        this.setState({time})
    }

    removeEvent = e => {

        this.props.removeEvent(this.props.object)
    }

    updateCurrentEvent = (key,candidate) =>{
        const event = {
            ...this.state,
            [key]:candidate
        }

        this.props.updateEvent(event)
    }

    isValidURL= str=> {
        const a  = document.createElement('a')
        a.href = str
        return (a.host && a.host !== window.location.host)
    }


    render() {

        const title = this.state.title
        const moreInfoLink = this.state.moreInfoLink
        const date = this.state.date
        const address = this.state.address
        return (

            <div className="flex-column">
                <h3 className="uk-margin-remove">EVENT</h3>
                <div className="flex-row centered">

                    <div className="flex-column">

                        <input className="uk-input" name='title' value={title} onChange={this.handleChange}
                               placeholder="title"/>
                        <input className="uk-input" name='moreInfoLink' value={moreInfoLink} onChange={this.handleChange}
                               placeholder="link for more information"/>

                        <div className="uk-inline">
                            <Geosuggest suggestsClassName="suggestion-input"
                                        initialValue={address}
                                        placeholder="Search place"
                                        inputClassName="uk-align-center uk-input bottom-border no-border"
                                        onSuggestSelect={this.handleLocationChange}
                            />

                        </div>


                        <div className="flex-row centered">
                            <DatePicker onDayClick={this.onDaySelected}
                            />
                            <div className="flex-column">

                                <h4>{date.toLocaleString().substr(0,date.toLocaleString().length-3)}</h4>
                                <TimePicker
                                    value={this.state.time}
                                    onChange={this.onTimeChange}
                                />
                            </div>
                        </div>


                    </div>
                    <div>
                        <a onClick={this.removeEvent} className="primary-theme-color" href="#"
                           uk-icon="icon: close; ratio: 1.5"/>
                    </div>
                </div>
                <div className="default-divider"/>
            </div>

        )
    }

}