
import axios from '../../utils/routes/axios'
import * as routes from '../../utils/routes'

class ConsularController {

    token = window.localStorage.getItem('jwt')
    headers = {
        Authorization: 'Bearer ' + this.token
    }

    async createConsul(data) {

        return await axios.post(routes.CREATE_CONSUL, data, this.headers)

    }

    async updateConsul(data) {

        return await axios.patch(routes.UPDATE_CONSUL, data, this.headers)

    }

    async getConsuls() {
        return await axios.get(routes.GET_CONSULS)
    }

    async deleteConsul(consulUuid) {
        return await axios.delete(routes.DELETE_CONSUL + '/' + consulUuid)
    }

    async createSympathizer(data) {

        return await axios.post(routes.CREATE_SYMPATHIZER, data, this.headers)

    }

    async updateSympathizer(data) {

        return await axios.patch(routes.UPDATE_SYMPATHIZER, data, this.headers)

    }

    async deleteSympathizer(uuid) {

        return await axios.delete(routes.DELETE_SYMPATHIZER+'/'+uuid, this.headers)

    }

    async getSympathizers() {

        return await axios.get(routes.GET_SYMPATHIZER)

    }



    async getAssistants() {
        return await axios.get(routes.GET_ASSISTANTS, this.headers)
    }

    async createAssistant(data) {

        return await axios.post(routes.CREATE_ASSISTANT, data, this.headers)
    }

    async updateAssistantAction(data) {
        return await axios.patch(routes.UPDATE_ASSISTANT, data, this.headers)
    }

    async deleteAssistant(assistantUuid) {
        return await axios.delete(`${routes.DELETE_ASSISTANT}/${assistantUuid}`,this.headers)
    }

    async getSocialNetworksTags() {
        return await axios.get(routes.GET_SOCIAL_NETWORKS_TAGS, this.headers)
    }

    async createSocialNetworkTag(data) {

        return await axios.post(routes.CREATE_SOCIAL_NETWORK_TAG, data, this.headers)
    }

    async updateSocialNetworkTag(data) {

        return await axios.patch(routes.UPDATE_SOCIAL_NETWORK_TAG, data, this.headers)
    }

    async deleteSocialNetworkTag(socialNetworkTagUuid) {
        return await axios.delete(routes.DELETE_SOCIAL_NETWORK_TAG + `/${socialNetworkTagUuid}`, this.headers)
    }

    // District

    async findAllDistricts() {

        return await axios.get(routes.DISTRICTS, this.headers)
    }

    async createDistrict(data) {

        return await axios.post(routes.DISTRICTS, data, this.headers)
    }

    async deleteDistrict(districtUuid) {

        return await axios.delete(`${routes.DELETE_DISTRICT + '/' + districtUuid}`)
    }

    async updateDistrict(data) {

        return await axios.patch(routes.UPDATE_DISTRICT, data, this.headers)
    }

    async getMetaFromUrl(url){
        const data = {
            url
        }
        return await axios.post(routes.GET_META_DISTRICT, data ,this.headers)
    }

    async getFolders(){
        return await axios.get(routes.GET_FOLDERS, this.headers)
    }

    async getFiles(){
        return await axios.get(routes.GET_FILES, this.headers)
    }

    async getFilesByFolderId(folderId, date){
        return await axios.get(`${routes.GET_FOLDERS}/${folderId}/files?date=${date}`, this.headers)
    }

    async getTemplates(data){
        return await axios.post(routes.CONSUL_DATA_HOST_BASE, data ,this.headers)
    }



}


export default new ConsularController()