import JSZip from 'jszip'
import fileSaver from 'file-saver'
class FileHelper {


    static validateCsv(file) {
        try {

            let asArray = file.split('\n')
            const firstLineLength = asArray[0].split(';').length
            asArray = asArray.map(el => el.split(';'))
            return asArray.every(candidate => candidate.length === firstLineLength)

        } catch (e) {
            console.log(e)

            return false;
        }

    }

    static processArrayCsvToMappedObjects(arrayCandidate, apiFieldsObject, mapping) {

        const result = []

        const mappingKeys = Object.keys(mapping)

        for (let i = 0; i < arrayCandidate.length; i++) {

            const csvLineContent = arrayCandidate[i]
            let toJsonValue = {}

            for (let j = 0; j < mappingKeys.length; j++) {
                toJsonValue[apiFieldsObject[mapping[mappingKeys[j]]].tag] = csvLineContent[mappingKeys[j]]
            }
            result.push(toJsonValue)
        }

        return result
    }

    static downloadFile(filename, text,extension='txt') {
        const element = document.createElement('a');
        element.style.display = 'none';
        document.body.appendChild(element);

        const lines = text.split('\n')
        const head = lines[0]

        const fileLines = lines.length


        const MAX_LINES = 6000
        const numberOfFiles = Math.ceil(fileLines / MAX_LINES)

        let currentIndex = 1
        const partFiles = []

        for (let i = 0; i < numberOfFiles; i++) {

            const limit = (currentIndex + MAX_LINES < fileLines) ? currentIndex + MAX_LINES : currentIndex + (fileLines - currentIndex)


            let partFile = ''.concat(head)
            for (let j = currentIndex; j < limit; j++) {

                partFile = partFile.concat('\n', lines[j])

            }

            partFiles.push(partFile)

            currentIndex = currentIndex + MAX_LINES


        }


        const files = partFiles.map((content, index) => {

           return {
                name: (index + 1) + '-' + filename,
                value: content
            }
        })




        for (let i = 0; i < files.length; i++) {

            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(files[i].value));
            element.setAttribute('download', files[i].name);
            element.click();
        }

        document.body.removeChild(element);
    }

    static downloadGenericFile(filename,content,extension='html'){


        const element = document.createElement('a');
        element.style.display = 'none';
        document.body.appendChild(element);


        const file = {
            name: filename,
            value: content
        }


        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(file.value));
        element.setAttribute('download', file.name+'.'+extension);
        element.click();


        document.body.removeChild(element);
    }

    static downloadAsZip(candidates,output){

        const zip = new JSZip()

        const size = candidates.length

        for(let i=0;i<size;i++){
            const candidate = candidates[i]
            zip.file(candidate.name+'.html', candidate.content)
        }
        zip.generateAsync({type:"blob"})
            .then((content)=> {
                fileSaver.saveAs(content, output);
            });
    }

    static getLocalStorage(key) {
        try {
            return JSON.parse(localStorage.getItem(key))
        } catch(e) {
            return undefined
        }
    }

    static updateLocalStorage(key, value) {
        try {
            localStorage.setItem(key, JSON.stringify(value))
        } catch(e) {
            console.log('unable to set item')
        }
    }

    static deleteInLocalStorage(key) {
        localStorage.removeItem(key)
    }

}

export default FileHelper