import React, { Component } from 'react'
import logo from '../../assets/images/logo-white.png'

export class AppBarHeader extends Component {

    state = {
        username: this.props.username,
        notificationsBadge: 0,
    }

    logout = (e) => {
        this.props.logout()
    }
    render() {
        return (
            <div className="app-bar primary-theme-color">
                <div className="home-logo">
                    <img alt="logo" className="app-bar-logo" src={logo} />
                </div>
                <div className="menu-actions">
                    <ul className="uk-iconnav">
                        <li><div className="boundary ">

                            <button uk-icon="icon: triangle-down; ratio: 1.5" className="uk-button uk-button-default uk-float-left text-white no-border" type="button">{this.state.username}</button>
                            <div uk-dropdown="boundary: .boundary">
                                <ul className="uk-nav uk-dropdown-nav">

                                    <li className="uk-nav-header">Account</li>
                                    <li><a >Profile</a></li>
                                    <li><a >Help</a></li>
                                    <li><a >Settings</a></li>
                                    <li className="uk-nav-divider"></li>
                                    <li><div className="inline-content-center-no-space "><a className="primary-theme-color" onClick={this.logout} uk-icon="icon: close; ratio: 1.5"></a></div></li>
                                </ul>
                            </div></div>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default AppBarHeader
