import React, { Component } from 'react'

export class AssistantTag extends Component {


    removeAction = e => {

        this.props.removeAction(this.props.assistant)
    }
    render() {


        const name = this.props.assistant.name
        const position = this.props.assistant.position
        return (
                <div className='uk-width-1-3 inline-content-center'>

                    <div className="spacer-1"></div>
                    <span className='uk-label text-white primary-theme-color'>{'#'+position+ ' ' +name}<a onClick={this.removeAction} className="primary-theme-color" href="#" uk-icon="icon: close;"></a></span>
                    <div className="spacer-1"></div>

                </div>
        )
    }
}

export default AssistantTag
