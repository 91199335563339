import React, { Component } from 'react'
import ListMembersCheckBoxContainer from './ListMembersCheckBoxContainer'
import Dialog from '../dialog'
import ListMemberDialogDisplayItem from './ListMemberDialogDisplayItem'
import listController from '../../controller/listsController'
import filterHelper from '../../helpers/FilterHelper';

export class ListScreenDetails extends Component {

    state = {

        addToListOpen: false,
        ...filterHelper.common

    }

    componentDidMount() {
        this.getList()
    }

    getList = () => {
        listController.getMembersInList(this.props.list.uuid, this.state.limit).then(res => {

            const totalItems = res.data.total_items
            const limit = this.state.limit
            const paginationSup = limit < totalItems ? limit : totalItems
            this.setState({ members: res.data.members, tmpMembers: res.data.members, totalItems, paginationSup, loading: false })
        })
    }

    handleInputChange = (e) => {

        const name = e.target.name
        const value = e.target.value



        if (value === '') {
            switch (name) {
                case 'city':
                    this.state.activeCityFilter = false

                    break
                case 'country':
                    this.state.activeCountryFilter = false

                    break
                case 'user':
                    this.state.activeUserFilter = false
                    break
                default:
                    break;
            }
            this.setState({ members: this.gatheredFilter() })
        }
        this.setState({ [name]: value })


    }
    addMemberToTmpList = (member) => {

        const tmpLists = this.state.tmpLists.filter(memberEl => memberEl.uuid === member.uuid)

        if (tmpLists.length === 0) {
            this.state.tmpLists.push(member)
        }

    }

    removeMemberToTmpList = (member) => {

        const tmpLists = this.state.tmpLists.filter(memberEl => member.uuid !== memberEl.uuid)
        this.state.tmpLists = tmpLists
    }


    onLimitChange = (e) => {
        try {
            this.setState({ loading: true })
            const limit = this.state.limits[e.target.value]

            const totalItems = this.state.totalItems
            const paginationInf = 0
            const paginationSup = limit < totalItems ? limit : totalItems

            listController.getMembersInList(this.props.list.uuid, limit).then(res => {


                this.state.tmpMembers = res.data.members
                this.setState({
                    members: this.gatheredFilter(), loading: false,
                    limit, paginationInf, paginationSup
                })
            })

        } catch (e) {

        }
    }

    showDialogValidation = () => {

        let membersItems = []

        var checkboxes = document.querySelectorAll('input[type="checkbox"]')
        let selectAll = document.getElementById("select-all")

        for (var i = 0; i < checkboxes.length; i++) {
            if (checkboxes[i].checked && checkboxes[i] !== selectAll) {

                membersItems.push(checkboxes[i])

            }

        }

        membersItems = membersItems.map(el => {


            const member = this.state.members.filter(m => m.id === el.id)[0]

            return member


        })

        this.state.tmpLists = filterHelper.mapMembers(membersItems)
        membersItems = this.state.tmpLists.map(member => <ListMemberDialogDisplayItem key={member.id} member={member} />)


        this.setState({
            addToListOpen: true,
            dialogChilds: membersItems
        })
    }

    hideDialog = () => {
        this.setState({
            addToListOpen: false,
            tmpLists: []
        })
    }

    validateTmpList = () => {

        this.props.updateListToCreate(this.state.tmpLists)

        setTimeout(() => {
            this.setState({ tmpLists: [] })
            this.hideDialog()
        }, 200)
    }

    onAgeFilterChange = (e) => {

        try {

            const ageCondition = this.state.ageFilter[e.target.value]
            if (ageCondition) {

                this.state.activeAgeFilter = true
                this.state.currentAgeFilter = ageCondition
                const members = this.gatheredFilter()
                this.setState({ members })
            } else {
                this.state.activeAgeFilter = false
                this.setState({ members: this.gatheredFilter() })
            }

        } catch (e) {

            console.log('exception')
        }
    }

    onPercentageFilter = (e) => {


        try {

            const currentPercentageFilter = this.state.percentageFilter[e.target.value]

            if (currentPercentageFilter) {
                this.state.activePercentageFilter = true
                this.state.currentPercentageFilter = currentPercentageFilter
                const members = this.gatheredFilter()

                this.setState({ members })
            } else {
                this.state.activePercentageFilter = false
                this.state.currentPercentageFilter = ''
                const members = this.gatheredFilter()
                this.setState({ members })
            }


        } catch (e) {

            this.state.activePercentageFilter = false
            this.state.currentPercentageFilter = ''
            const members = this.gatheredFilter()
            this.setState({ members })

        }
    }
    onSpecificCountryFilterChange = (e) => {
        try {

            this.state.currentCountrySpecificFilter = this.state.countryFilter[e.target.value]

            this.state.activeSpecificCountryFilter = true
            const members = this.gatheredFilter()

            console.log(this.state.currentCountrySpecificFilter)
            console.log(this.state.activeSpecificCountryFilter)
            this.setState({ members })

        } catch (e) {
            this.state.activeSpecificCountryFilter = false
            const members = this.gatheredFilter()
            this.setState({ members })
        }
    }

    onUserFilterChange = (e) => {

        try {


            const user = this.state.user


            if (user !== '') {
                this.state.activeUserFilter = true
                const members = this.gatheredFilter()
                this.setState({ members, currentUserFilter: user })

            }


        } catch (e) {

        }
    }

    onCountryFilterChange = (e) => {
        try {


            const country = this.state.country


            if (country !== '') {
                this.state.activeCountryFilter = true
                const members = this.gatheredFilter()
                this.setState({ members, currentCountryFilter: country })

            }


        } catch (e) {

        }
    }


    onCityFilterChange = (e) => {
        e.preventDefault()

        const city = this.state.city

        if (city !== '') {

            this.state.activeCityFilter = true
            const members = this.gatheredFilter()
            this.setState({ members })
        }
    }

    rightPaginationUpdate = () => {

        const limit = this.state.limit
        const totalItems = this.state.totalItems
        const offset = this.state.offset + limit
        let diff = totalItems - offset

        console.log('diff : ' + diff)
        if (diff > 0) {

            this.loadingToggleStart()

            const paginationInf = offset
            const paginationSup = offset + limit < totalItems ? paginationInf + limit : offset + diff

            listController.getMembersInList(this.props.list.uuid, this.state.limit, paginationInf).then(res => {
                this.state.tmpMembers = res.data.members
                this.state.members = this.gatheredFilter()
                this.setState({
                    paginationInf, paginationSup, offset,
                    loading: false
                })
            })
        }

    }

    leftPaginationUpdate = () => {



        const limit = this.state.limit
        const totalItems = this.state.totalItems
        const offset = limit < totalItems ? limit : totalItems


        const paginationSup = this.state.paginationInf
        const paginationInf = this.state.paginationInf - offset


        if (paginationInf >= 0) {
            this.setState({ loading: true })
            listController.getMembersInList(this.props.list.uuid, this.state.limit, paginationInf).then(res => {

                this.state.tmpMembers = res.data.members
                this.state.members = this.gatheredFilter()

                this.setState({
                    paginationInf, paginationSup,
                    loading: false
                })
            })
        }

    }

    loadingToggleStart = () => {
        this.setState({ loading: true })
    }

    loadingToggleEnd = () => {
        this.setState({ loading: false })
    }

    handleCheckAll = (source) => {

        let selectAll = document.getElementById("select-all")
        this.setState({ selectAll: selectAll.checked })
    }

    gatheredFilter() {

        return filterHelper.gatheredFilters(this.state.activeAgeFilter,
            this.state.activeSpecificCountryFilter,
            this.state.activeCountryFilter,
            this.state.activeCityFilter,
            this.state.activeUserFilter,
            this.state.user,
            this.state.country,
            this.state.currentCountrySpecificFilter,
            this.state.city,
            this.state.currentAgeFilter,
            this.state.tmpMembers,
            this.state.activePercentageFilter,
            this.state.currentPercentageFilter
        )
    }


    render() {


        const list = this.props.list


        const members = filterHelper.mapMembers(this.state.members)
        return (

            <div className="uk-padding-small">
                <div className="flex-column">
                    <div className="sized-box-height-2"></div>
                    <h1 class="uk-heading-line uk-text-center"><span>{list.name}</span></h1>

                </div>

                <div className="uk-flex uk-flex-around uk-flex-middle uk-flex-wrap uk-flex-wrap-around">
                        <div className="text-white circular list-details-stats primary-theme-color uk-margin-bottom">
                            <div className="list-details-stats-content">
                                <h5 className="text-white">MEMBERS</h5>
                                <span className="font-20 text-white">{list.members}</span>
                            </div>
                        </div>

                        <div className="circular list-details-stats primary-theme-color uk-margin-bottom">
                            <div className=" list-details-stats-content ">
                                <h5 className="text-white">OPENS</h5>
                                <span className="font-20 text-white">{list.opens + "%"}</span>
                            </div>
                        </div>

                        <div className="circular list-details-stats primary-theme-color uk-margin-bottom">
                            <div className=" list-details-stats-content ">
                                <h5 className="text-white">CLICKS</h5>
                                <span className="font-20 text-white">{list.clicks + "%"}</span>
                            </div>
                        </div>

                        <div className="circular list-details-stats primary-theme-color uk-margin-bottom">

                            <div className=" list-details-stats-content ">
                                <h5 className="text-white">CAMPAIGNS</h5>
                                <span className="font-20 text-white">{list.stats.campaign_count}</span>
                            </div>

                        </div>

                    </div>

                <div className="items-container">

                    <div className="sizedbox-height-5"></div>

                    <div className="uk-flex uk-flex-between uk-flex-wrap uk-flex-wrap-bottom">

                        <div className="uk-flex uk-flex-center">
                            <span className="primary-theme-color-foreground" uk-icon={"icon: ban ; ratio: 2"}></span>
                            <div className="spacer-1"></div>
                            <form>
                                <div class="uk-margin">
                                    <div uk-form-custom="target: > * > span:first-child">
                                        <select onChange={this.onLimitChange}>
                                            <option value="">limit</option>
                                            {this.state.limits.map((limit, index) => <option value={index}>{limit}</option>)}

                                        </select>
                                        <button class="uk-button uk-button-default" type="button" >
                                            <span></span>
                                            <span className="primary-theme-color-foreground" uk-icon="icon: chevron-down"></span>
                                        </button>
                                    </div>
                                </div>
                            </form>


                        </div>

                        <div className="inline-content-center-no-space">

                            <span className="primary-theme-color-foreground" uk-icon={"icon: world ; ratio: 2"}></span>
                            <div className="spacer-1"></div>
                            <form>
                                <div class="uk-margin">
                                    <div uk-form-custom="target: > * > span:first-child">
                                        <select onChange={this.onSpecificCountryFilterChange}>
                                            <option value="">NATION</option>
                                            {this.state.countryFilter.map((country, index) => <option value={index}>{country}</option>)}

                                        </select>
                                        <button class="uk-button uk-button-default" type="button" >
                                            <span></span>
                                            <span className="primary-theme-color-foreground" uk-icon="icon: chevron-down"></span>
                                        </button>
                                    </div>
                                </div>
                            </form>


                        </div>

                        <div className="inline-content-center-no-space">

                            <span className="primary-theme-color-foreground" uk-icon={"icon: calendar ; ratio: 2"}></span>
                            <div className="spacer-1"></div>
                            <form>
                                <div class="uk-margin">
                                    <div uk-form-custom="target: > * > span:first-child">
                                        <select onChange={this.onAgeFilterChange}>
                                            <option value="">AGE</option>
                                            {this.state.ageFilter.map((condition, index) => <option value={index}>{condition}</option>)}

                                        </select>
                                        <button class="uk-button uk-button-default" type="button" >
                                            <span></span>
                                            <span className="primary-theme-color-foreground" uk-icon="icon: chevron-down"></span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="inline-content-center-no-space">

                            <span className="primary-theme-color-foreground" uk-icon={"icon: unlock ; ratio: 2"}></span>
                            <div className="spacer-1"></div>
                            <form>
                                <div class="uk-margin">
                                    <div uk-form-custom="target: > * > span:first-child">
                                        <select onChange={this.onPercentageFilter}>
                                            <option value="">PERCENTAGE</option>
                                            {this.state.percentageFilter.map((condition, index) => <option value={index}>{condition}</option>)}

                                        </select>
                                        <button class="uk-button uk-button-default" type="button" >
                                            <span></span>
                                            <span className="primary-theme-color-foreground" uk-icon="icon: chevron-down"></span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="uk-flex uk-flex-between uk-flex-wrap uk-flex-wrap-bottom">
                        <div className="inline-content-center-no-space">
                            <div class="uk-margin">
                                <div class="uk-inline">
                                    <span class="uk-form-icon" uk-icon="icon: user"></span>
                                    <a onClick={this.onUserFilterChange} class="uk-form-icon uk-form-icon-flip primary-theme-color" uk-icon="icon: search"></a>
                                    <input onChange={this.handleInputChange} name="user" placeholder="name or email" class="uk-input uk-margin-remove" type="text" value={this.state.user}></input>
                                </div>
                            </div>
                        </div>
                        <div className="inline-content-center-no-space">


                            <div class="uk-margin">
                                <div class="uk-inline">
                                    <span class="uk-form-icon" uk-icon="icon: world"></span>
                                    <a onClick={this.onCountryFilterChange} class="uk-form-icon uk-form-icon-flip primary-theme-color" uk-icon="icon: search"></a>
                                    <input onChange={this.handleInputChange} name="country" placeholder="native country" class="uk-input uk-margin-remove" type="text" value={this.state.country}></input>
                                </div>
                            </div>

                        </div>

                        <div className="inline-content-center-no-space">
                            <div class="uk-margin">
                                <div class="uk-inline">
                                    <span class="uk-form-icon" uk-icon="icon: location"></span>
                                    <a onClick={this.onCityFilterChange} class="uk-form-icon uk-form-icon-flip primary-theme-color" uk-icon="icon: search"></a>
                                    <input onChange={this.handleInputChange} name="city" placeholder="city of residence" class="uk-input uk-margin-remove" type="text" value={this.state.city}></input>
                                </div>
                            </div>
                        </div>

                        <div >
                            <button onClick={this.leftPaginationUpdate} type="submit" className="primary-theme-color-foreground" uk-icon="icon: chevron-left"></button>
                            <span>{this.state.paginationInf + 1} - {this.state.paginationSup} of {this.state.totalItems}</span>
                            <button onClick={this.rightPaginationUpdate} type="submit" className="primary-theme-color-foreground" uk-icon="icon: chevron-right"></button>
                        </div>

                    </div>

                    <div className="default-divider"></div>

                    {this.state.addToListOpen ? (<Dialog key='dialog' childs={this.state.dialogChilds} hideDialog={this.hideDialog} mainAction={this.validateTmpList} />) : (<div></div>)}

                    <div className="flex-right">
                        <button onClick={this.showDialogValidation} type="button" className="uk-button primary-theme-color text-white">ADD TO LIST</button>
                    </div>

                    <div className="sizedbox-height-5"></div>

                    {
                        !this.state.loading ? (<div className="table-container uk-overflow-auto">

                            <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
                                <label><input id="select-all" onChange={this.handleCheckAll} class="uk-checkbox primary-theme-color-foreground" type="checkbox" /> SELECT ALL</label>
                            </div>
                            <table class="uk-table uk-table-small uk-table-divider">
                                <thead>
                                    <tr>
                                        <th>ACTION</th>
                                        <th>INFOS</th>
                                        <th>STATS</th>
                                    </tr>
                                </thead>
                                <tbody className="padding-5">

                                    {members.map(member => <ListMembersCheckBoxContainer selectAll={this.state.selectAll} key={member.uuid} addMemberToTmpList={this.addMemberToTmpList} removeMemberToTmpList={this.removeMemberToTmpList} member={member} />)}


                                </tbody>
                            </table>
                        </div>) : (<div className="inline-content-center"><span class="uk-margin-small-right" uk-spinner="ratio: 3"></span></div>)
                    }


                    <div className="inline-content-center-no-space">
                        <button onClick={this.leftPaginationUpdate} type="submit" className="primary-theme-color-foreground" uk-icon="icon: chevron-left"></button>
                        <span>{this.state.paginationInf} - {this.state.paginationSup} of {this.state.totalItems}</span>
                        <button onClick={this.rightPaginationUpdate} type="submit" className="primary-theme-color-foreground" uk-icon="icon: chevron-right"></button>
                    </div>
                    <div className="sizedbox-height-5"></div>



                </div>
            </div>
        )
    }
}

export default ListScreenDetails
