import React, { Component } from 'react'
import ReactMapGL from 'react-map-gl'

export class Map extends Component {

    state = {
        viewport: {
            width: '100%',
            height: '40vh',
            latitude: 48.8534,
            longitude: 2.3488,
            zoom: 11
        }
    };

    _onViewportChange = viewport => {

        this.setState({ viewport })
    }

    render() {
        return (
            <ReactMapGL
                {...this.state.viewport}
                onViewportChange={this._onViewportChange}
                mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
            />
        )
    }
}

export default Map
