import React, { Component } from 'react'
import ImportFileComponent from '../common/ImportFileComponent';
import MemberImportFromCsvMatchingFields from './MemberImportFromCsvMatchingFields';

export class StepsContainer extends Component {

    state = {
        step: 0,
        fileAsArray: [],
        csvFields: [],
        fieldsMapping: {},
    }

    updateStepIndex = (index) => {
        this.setState({ step: index })
    }

    getFileContent = () => {
        return this.state.fileAsArray
    }

    updateFileContent = (csvApiResponse) => {


        this.setState({
            csvFields: csvApiResponse.fields,
            fileAsArray: csvApiResponse.candidates
        })
    }

    hideDialog = () => {
        this.props.hideDialog()
    }

    render() {

        switch (this.state.step) {
            case 0:
                return <ImportFileComponent lists={this.props.lists} hideDialog={this.hideDialog} updateFileContent={this.updateFileContent} updateStep={this.updateStepIndex} />
            case 1:
                return <MemberImportFromCsvMatchingFields account={this.props.account} updateStep={this.updateStepIndex} csvFields={this.state.csvFields} apiFields={this.props.apiFields} getCsvArrayContent={this.getFileContent} />
            default:
                break;
        }
        return (
            <div>

            </div>
        )
    }
}




export default StepsContainer
